import React from 'react'
import {Layout,PageHeader,Table, Tag, Space,Menu,Dropdown,Button} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const TeamScreen = () => {
    const {Content} = Layout;
    const menu = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="/">
              Active
            </a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="/">
              Inactive
            </a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="/">
              Archieved
            </a>
          </Menu.Item>
        </Menu>
    );
    
    const DropdownMenu = () => {
        return (
        <Dropdown key="more" overlay={menu}>
            <Button
            style={{
                border: 'none',
                padding: 0,
            }}
            >
            <EllipsisOutlined
                style={{
                fontSize: 20,
                verticalAlign: 'top',
                }}
            />
            </Button>
        </Dropdown>
        );
    };

    const rowSelection = {}
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          sorter: true,
          render: text => <a href="/">{text}</a>,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          sorter: true,
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
          sorter: true,
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          sorter: true,
          render: tags => (
            <>
              {tags.map(tag => {
                let color = tag.length > 5 ? 'volcano' : 'green';
                if (tag === 'Owner') {
                  color = 'geekblue';
                }
                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <a href="/"><u>Invite</u></a>
              <a href="/"><u>Delete</u></a>
              <DropdownMenu key="more" />
            </Space>
          ),
        },
      ];
      
      const data = [
        {
          key: '1',
          name: 'John Brown',
          email: 'JohnBrown@allegrow.com',
          phone: '+44 7479 007 150',
          status: ['Admin'],
        },
        {
          key: '2',
          name: 'Jim Green',
          email: 'JimGreen@allegrow.com',
          phone: '+44 7479 008 150',
          status: ['Owner'],
        },
        {
          key: '3',
          name: 'Joe Black',
          email: 'JoeBlack@allegrow.com',
          phone: '+44 7479 009 150',
          status: ['Admin'],
        },
      ];
    return (
        <div>
            <Content className="mx-6 my-6">
                <div className="ns-shadow-xl ns-rounded-lg ns-content-section">
                    <PageHeader className="p-5"  title="My Team Members"/>
                    <Table  className="mx-2 my-2" rowSelection={rowSelection} columns={columns} dataSource={data} />
                </div>
            </Content>
        </div>
    )
}

export default TeamScreen
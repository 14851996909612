export const OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST = 'OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST';
export const OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_SUCCESS = 'OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_SUCCESS';
export const OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_FAIL = 'OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_FAIL';

export const OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_REQUEST = 'OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_REQUEST';
export const OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_SUCCESS = 'OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_SUCCESS';
export const OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_FAIL = 'OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_FAIL';

export const OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_REQUEST = 'OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_REQUEST';
export const OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_SUCCESS = 'OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_SUCCESS';
export const OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_FAIL = 'OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_FAIL';
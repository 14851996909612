export const PLAN_DETAILS_REQUEST = 'PLAN_DETAILS_REQUEST';
export const PLAN_DETAILS_REQUEST_SUCCESS = 'PLAN_DETAILS_REQUEST_SUCCESS';
export const PLAN_DETAILS_REQUEST_FAIL = 'PLAN_DETAILS_REQUEST_FAIL';

export const PLAN_DETAILS_SAVE_REQUEST = 'PLAN_DETAILS_SAVE_REQUEST';
export const PLAN_DETAILS_SAVE_SUCCESS = 'PLAN_DETAILS_SAVE_SUCCESS';
export const PLAN_DETAILS_SAVE_FAIL = 'PLAN_DETAILS_SAVE_FAIL';

export const PLAN_ALL_DETAILS_REQUEST = 'PLAN_ALL_DETAILS_REQUEST';
export const PLAN_ALL_DETAILS_REQUEST_SUCCESS = 'PLAN_ALL_DETAILS_REQUEST_SUCCESS';
export const PLAN_ALL_DETAILS_REQUEST_FAIL = 'PLAN_ALL_DETAILS_REQUEST_FAIL';
import React, { useEffect } from 'react'
import {Layout, PageHeader, Spin} from 'antd';
import InsightCollapsePanel from './Shared/InsightCollapsePanel';
import { useSelector, useDispatch } from 'react-redux';
import { userInsightsAction } from '../Store/Actions/insightsActions';

const InsightsScreen = () => {
    const {Header,Content} = Layout;
    const dispatch = useDispatch();
    const userInsightsDetails = useSelector((state) => state.userInsightsDetails);
    const { userInsights, loading:InsightsLoding } = userInsightsDetails;

    console.log('userInsightsDetails', userInsightsDetails)
 

    const urlParams = new URLSearchParams(window.location.search);
    const domainName = urlParams.get('domain') || null;
    
    useEffect(() => {
        if(domainName){
            dispatch(userInsightsAction(null,domainName));
        }
        else{
            dispatch(userInsightsAction(localStorage.getItem("userId")));
        }
    }, []);
    return (
        !InsightsLoding && userInsights && userInsights.unarchived
        ?   <div>
                <Header className="site-layout-sub-header-background p-0 fixed"> 
                    <PageHeader className="site-page-header"  title="Insights"/>
                </Header>
                <Content className="mx-6 my-6 ant-layout-content-static pt-12">
                    <div className="min-h-0">
                        <InsightCollapsePanel domainName={domainName} InsightsType="Insights" Insights={userInsights.unarchived} />
                    </div>
                </Content>
               {
                userInsights.archived.length
                ? <div>   
                        <Header className="site-layout-sub-header-background p-0"> 
                            <PageHeader className="site-page-header"  title="Archived Insights"/>
                        </Header>
                        <Content className="mx-6 pt-0 ant-layout-content-static">
                            <div className="ns-content-section">
                                <InsightCollapsePanel domainName={domainName} InsightsType="InsightsArchived" Insights={userInsights.archived} />
                            </div>
                        </Content>
                  </div>
                : <div></div>
                }
            </div>
        : <Spin className="p-56" size="large" tip="Loading Insights..."  spinning={true}></Spin>
    )
}

export default InsightsScreen

import { 
  MAILBOX_ID_STATISTICS_REQUEST, MAILBOX_ID_STATISTICS_REQUEST_SUCCESS, MAILBOX_ID_STATISTICS_REQUEST_FAIL,
  MAILBOX_UPDATE_REQUEST,MAILBOX_UPDATE_SUCCESS,MAILBOX_UPDATE_FAIL,
  MAILBOX_CONNECT_REQUEST,MAILBOX_CONNECT_SUCCESS,MAILBOX_CONNECT_FAIL,
  MAILBOX_DOMAIN_LISTS_REQUEST, MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS, MAILBOX_DOMAIN_LISTS_REQUEST_FAIL,
  MAILBOX_DOMAIN_STATISTICS_REQUEST, MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS, MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL,
  MAILBOX_VALIDATE_REQUEST, MAILBOX_VALIDATE_SUCCESS, MAILBOX_VALIDATE_FAIL,
} from "../Constants/maillboxConstants";

function mailboxIdStatisticsReducer(state = {statistics: []}, action) {
  switch (action.type) {
    case MAILBOX_ID_STATISTICS_REQUEST:
      return { loading: true, statistics: [] };
    case MAILBOX_ID_STATISTICS_REQUEST_SUCCESS:
      return { loading: false, statistics: action.payload };
    case MAILBOX_ID_STATISTICS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function mailboxDomainListsReducer(state = {mailboxDomainLists: null}, action) {
  switch (action.type) {
    case MAILBOX_DOMAIN_LISTS_REQUEST:
      return { loading: true, mailboxDomainLists: null };
    case MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS:
      return { loading: false, mailboxDomainLists: action.payload };
    case MAILBOX_DOMAIN_LISTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function mailboxDomainStatisticsReducer(state = {mailboxDomainStatistics: null}, action) {
  switch (action.type) {
    case MAILBOX_DOMAIN_STATISTICS_REQUEST:
      return { loading: true, mailboxDomainStatistics: null };
    case MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS:
      return { loading: false, mailboxDomainStatistics: action.payload };
    case MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  mailboxDetailsUpdateReducer(state = { mailboxData: {} }, action) {
  switch (action.type) {
    case MAILBOX_UPDATE_REQUEST:
      return { loading: true};
    case MAILBOX_UPDATE_SUCCESS:
      return { loading: false,success: true, mailboxData: action.payload };
    case MAILBOX_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  mailboxConnectReducer(state = { mailboxConnectData: null }, action) {
  switch (action.type) {
    case MAILBOX_CONNECT_REQUEST:
      return { mailboxConnectLoading: true};
    case MAILBOX_CONNECT_SUCCESS:
      return { mailboxConnectLoading: false,success: true, mailboxConnectData: action.payload };
    case MAILBOX_CONNECT_FAIL:
      return { mailboxConnectLoading: false, mailboxConnectError: action.payload };
    default: return state;
  }
}

function  mailboxValidateReducer(state = { mailboxValidateData: null }, action) {
  switch (action.type) {
    case MAILBOX_VALIDATE_REQUEST:
      return { mailboxValidateLoading: true };
    case MAILBOX_VALIDATE_SUCCESS:
      return { mailboxValidateLoading: false,success: true, mailboxValidateData: action.payload };
    case MAILBOX_VALIDATE_FAIL:
      return { mailboxValidateLoading: false, mailboxValidateError: action.payload };
    default: return state;
  }
}

export { 
  mailboxIdStatisticsReducer, mailboxDetailsUpdateReducer, mailboxConnectReducer, mailboxDomainListsReducer,
  mailboxDomainStatisticsReducer, mailboxValidateReducer,
}
import React , { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Layout } from 'antd';
import Chart from 'chart.js';
import 'chartjs-plugin-dragdata';
import './RoundCorners'
import { useSelector, useDispatch } from 'react-redux';
import { outgoingMailboxDailyFutureLimitSaveAction } from '../../Store/Actions/outgoingMailboxActions';
import moment from 'moment';

const DragableChart = (data) => {
  
  const demoData = localStorage.getItem("demoData")
  let { mailboxId } = useParams();
  const arrEmailAccounts = JSON.parse(localStorage.getItem('arrEmailAccounts'));
  const userSearchMailbox = mailboxId => arrEmailAccounts.find(m => m.id === mailboxId);
  let selectedMailbox = userSearchMailbox(parseInt(mailboxId));
  console.log(`selectedMailbox`, selectedMailbox)
  const outgoingMailboxDailyFutureLimit = useSelector((state) => state.outgoingMailboxDailyFutureLimit);
  const { dailySettings, 
    //loading: loadingDailyLimit , error: errorDailyLimit 
  } = outgoingMailboxDailyFutureLimit;
  const planDetails = useSelector((state) => state.planDetails);
  const { planData, 
    //loading : loadinglanDetails, error: errorPlanDetails 
  } = planDetails;
  const dispatch = useDispatch();
  console.log('dailySettingsDatyya',data)
  console.log('dailySettingsSelector',dailySettings)
  const planPermission = JSON.parse(planData.planData.records[0].plan_policy);
  const hiddenDailyReplies = demoData?(!demoData):(!planPermission.receiveReplies);
  const {Content} = Layout;
  let chartInstanceCreate;
  var dailyReplies = {
    label: 'Daily Replies',
    data: data.data.reply_limit,
    backgroundColor: Array(15).fill('#60c72a'),
    borderColor: '#60c72a',
    borderWidth: 1,
    yAxisID: "y-axis-replies",
    hidden: hiddenDailyReplies
  };
  dailyReplies.backgroundColor[0]='#d9f7ca';
  var dailyEmails = {
    label: 'Daily Emails',
    data: data.data.email_limit,
    backgroundColor: Array(15).fill('#3e95cd'),
    borderColor: '#3e95cd',
    borderWidth: 1,
    yAxisID: "y-axis-emails"
  };
  dailyEmails.backgroundColor[0]='#d1e4f1';
  const timeFrom = (X) => {
    const dates = ['Today','Tomorrow'];
    for (let I = 2; I < Math.abs(X); I++) {
        dates.push(moment(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000))).format('ddd, DD.MM'));
    }
    return dates;
  }

  console.log('dailyReplies',dailyReplies)
  console.log('dailyEmails',dailyEmails)
  var daysData = {
    labels: timeFrom(-15),
    datasets: [dailyReplies,dailyEmails]
  }

  

  console.log('timeFrom', timeFrom(-15))
  // console.log('timeFrom', moment().subtract(7, 'days').toDate())
  const maxYaxis = Math.max(...dailyEmails.data)
  const maxYaxisLimit = maxYaxis < 35 ? 40 : maxYaxis
  const chartConfig = {
    type: 'bar',
    data:daysData,
    options: {
      cornerRadius: 20,
      responsive: true,
      legend:false,
      dragData: true,
      dragDataRound: 0,
      dragOptions: {
        showTooltip: true
      },
      onDragStart: function(e) {
        console.log(e)
      },
      onDrag: function(e, datasetIndex, index, value) {
        e.target.style.cursor = 'grabbing'
        if(!index){
          return false;
        }
        //replies
        if(value<1){
          return false;
        }

      //Emails daily limit should not be grather than 35
      if(value>35){
        return false;
      }
            

        //When dragging the green bars up over the blue bars limit, increase both limits like it happens when lowering the blue bars
        if( !datasetIndex  && 
          value>=dailySettings.result[index].email_limit
        ){
          dailyEmails.data[index] = value;
          chartInstanceCreate.update();
          return true;
        }
        //Emails
        // if(datasetIndex==1 && value<=2){
        //   return false;
        // }
        //replies
        if(datasetIndex===0 && value>dailyEmails.data[index]){
          return false;
        }

   
        
        //Emails
        if(datasetIndex===1 && value<dailyReplies.data[index]){
          dailyReplies.data[index] = value;
          chartInstanceCreate.update();
          // return false;
        }
        

        // console.log(datasetIndex, index, value)
      },
      onDragEnd: function(e, datasetIndex, index, value) {
        if(!index){
          return false;
        }
        console.log('drag end',{datasetIndex, index, value})
        console.log('email limit',dailySettings.result[index].email_limit)
        console.log('reply limit',dailySettings.result[index].reply_limit)
        //1 Emails
        if ( 
              ( datasetIndex && dailySettings.result[index].reply_limit > value ) || 
              (!datasetIndex  && dailySettings.result[index].email_limit <= value) 
          ) {
          dailySettings.result[index].email_limit = value;
          dailySettings.result[index].reply_limit = value;
          
        } else {
          if(datasetIndex)
            dailySettings.result[index].email_limit = value
          else
            dailySettings.result[index].reply_limit = value
        }
        
        if(!demoData) updateFutureLimit(dailySettings.result[index]);
        e.target.style.cursor = 'default' 
        // console.log(datasetIndex, index, value)
      },
      hover: {
        onHover: function(e) {
          const point = this.getElementAtEvent(e)
          if (point.length && point[0]?._index) e.target.style.cursor = 'grab'
          else e.target.style.cursor = 'default'
        }
      },
      scales: {
        xAxes: [{
            stacked: true,
            display: true,
            legend: true,
            // barPercentage: 1,
            categoryPercentage: 0.8,
            gridLines: {
              display: false
            },
        }],
        yAxes: [
          {
            stacked: true,  
            display: false,
            ticks: {
                max: maxYaxisLimit,
                min: 1,
            },
            id: "y-axis-emails"
        },
          {
            stacked: true,
            display: false,
            id: "y-axis-replies",
            ticks: {
                max: maxYaxisLimit,
                min: 1
            }
        }
      ]
      }
    }
  };

  

  useEffect(() => {
      var ctx = document.getElementById('chartJSContainer').getContext('2d');
      chartInstanceCreate = new Chart(ctx, chartConfig);
      return () => console.log('unmounting...');
  }, []);

  const updateFutureLimit = objData => {
    console.log('objData',objData);
    dispatch(outgoingMailboxDailyFutureLimitSaveAction(objData))
  }

  return (
         <div className="ns-rounded-lg"> 
            <Content className="my-6 pb-5 pt-2 mt-0">
              <h1 className='Spam-Rate pl-1  pt-5 w-full text-left'>{selectedMailbox?.email}</h1>
              <br></br>
              <div className="w-1/2 pl-1 items-start flex-row flex">
                <h1 className='Reputation flex-row flex' style={{color: '#3e95cd'}}>Daily Emails</h1>
                { !hiddenDailyReplies && <h1 className='Reputation flex-row flex pl-4' style={{color: '#60c72a'}}>Daily Replies</h1>}
              </div>
              <div className="site-layout-background ns-rounded-lg">
                <div className="w-full items-center">
                  <div className="line-chart">
                    <div className="chart-container"></div>
                      <canvas id="chartJSContainer" style={{height:'250px', width:'100%'}}>Canvas is here</canvas>
                  </div>
                </div> 
              </div>
             </Content>
          </div>
  )
}

export default DragableChart
import React  from 'react'
import {Collapse,Empty, Tag} from 'antd';
import { archivedInsightsAction,userInsightsAction,mailboxInsightsAction } from '../../Store/Actions/insightsActions';
import { useDispatch } from 'react-redux';

const { Panel } = Collapse;

const InsightCollapsePanel = (props) => {

    const demoData = localStorage.getItem("demoData")
    const {setVisibleInsights, mailboxId, domainName,Insights,InsightsType} = props;
    const dispatch = useDispatch();
    const defaultActiveKey = (InsightsType==='InsightsArchived') ? [] : [];
    const InsightsArchivedClass = (InsightsType==='InsightsArchived') ? 'collapse-panel-content-archived' : '';
    const handleClick = async(InsightId) => {
      if (demoData) {
        return demoData;  
      }
      mailboxId && setVisibleInsights(true)
      await dispatch(archivedInsightsAction(InsightId,(mailboxId-0)));
      if(mailboxId){
        await dispatch(mailboxInsightsAction(mailboxId));
        setVisibleInsights(false)
      } 
      else if(domainName) {
        dispatch(userInsightsAction(null,domainName));
      }else{
         dispatch(userInsightsAction(localStorage.getItem("userId")));
      }
    }
    const DomainTag = ({domainName}) =>{ 
      return (
      <Tag className="ml-2  ns-rounded-lg" style={{marginTop:"7px"}} color="blue">
        <span className="mt-0" style={{color:"#08979c",marginTop:"0px",fontSize:"14px"}}>{domainName}</span>
      </Tag>
    )}
    return (
        <div>
           {Insights.length
            ?
            <Collapse className="bg-transparent site-collapse-custom-collapse"  bordered={false}   defaultActiveKey={defaultActiveKey} accordion>
                {
                  Insights.map((Insight,Index) => {
                      return (
                      <Panel  className="ns-shadow-xl ns-rounded-lg mt-6 w-full" 
                        showArrow={true} extra={[ (InsightsType==='Insights') && <button key={1} onClick={() => handleClick(Insight.insight_id)}  
                        className="btn text-bold focus:outline-none ns-bg-green  py-2 px-4 rounded-lg text-white">I've Done This</button>]} 
                        header={<div className="collapse-panel-title">
                                  <span className={`mr-5 mt-1 ${InsightsArchivedClass}`}>{Insight.title}</span>
                                  <div className="">
                                    { domainName 
                                      ? <DomainTag domainName={domainName} />
                                      : !mailboxId && Insight.domainNames.length 
                                        && Insight.domainNames.map((domain,domainIndex) => <DomainTag domainName={domain} key={domainIndex} /> )
                                    }
                                    </div>
                                  </div>} key={Index}>
                          <div className="flex w-full">
                              <div className="p-3 font-semibold collapse-panel-content text-left" dangerouslySetInnerHTML={ { __html: Insight.body  }} ></div>
                          </div>
                        </Panel>
                      )
                    })
                }
            </Collapse>
            :<Empty className="p-8" description="You'll see your insights here soon, based on the performance of your mailbox(es)." />
          }
        </div>
    )
}

export default InsightCollapsePanel

import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Spin, PageHeader, Button, Space } from 'antd';
import ContentChartScreen from './Shared/ContentChartScreen';
import EmptyMailboxScreen from './EmptyMailboxScreen';
import { USER_CREATE_CONTENT_TESTS, USER_EDIT_CONTENT_TESTS } from '../Routes/Constants/RouteUrls';
import { getlocalStorageValue } from '../Helpers/globalValues'
//import contentTests from '../Json/contentTests.json'
import { contentTestsAction } from '../Store/Actions/contentTestsActions';
import ChartLoadingScreen from './Shared/ChartLoadingScreen';

const ContentTestsScreen = () => {

    const history = useHistory();
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const [ visible, setVisible ] = useState(false);
    const { Header, Content } = Layout;
    const { contentTests } = useSelector((state) => state.contentTestsInfo);
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const waitCall = urlParams.get('waitCall');
    console.log(`waitCall`, waitCall)
    
    useEffect(() => {
        if(waitCall)
            setTimeout(() => {
                dispatch(contentTestsAction())    
            }, 3000);
        else
            dispatch(contentTestsAction())
        return () => console.log('Content Unmount component');
    }, []);

    console.log(`contentTests`, contentTests)
    return (
        <div>
            <Header className="site-layout-sub-header-background p-0 fixed herder-div-width"> 
                <PageHeader 
                    className="site-page-header"  
                    title="Content Tests"
                    extra={
                            [
                                <Spin key={1} spinning={false}>
                                    <button key={1} 
                                        className="btn text-bold focus:outline-none ns-bg-green  py-2 px-4 rounded-lg text-white" 
                                        onClick={() => history.push(USER_CREATE_CONTENT_TESTS) }
                                    >
                                        Create New Test
                                    </button>
                                </Spin>
                            ]
                        }
                />
            </Header>
            <Content className="mx-6 my-6 text-center space-y-4 ant-layout-content-static pb-16">
                {
                    contentTests 
                    ?
                        contentTests.length 
                        ?   (contentTests.map((contentTest,testIndex) => {
                                return (
                                        <div key={testIndex}>
                                            <div  className="site-layout-background pt-2 ns-rounded-lg">
                                                <div className="site-statistic-demo-card">    
                                                    <ContentChartScreen 
                                                        className="flex" 
                                                        domainName={contentTest.test_name} 
                                                        contentTest={contentTest} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full flex">
                                                <div className="w-3/4"><p></p></div>
                                                <div className="w-1/2 variations-cal-head text-right  flex flex-end justify-between py-3">
                                                    <div className="variations-email-sent">
                                                        Emails Sent
                                                    </div>
                                                    <div className="variations-reputation">
                                                        <span className="mx-3">Reputation</span>
                                                    </div>
                                                    <div className="variations-spam-rate">
                                                        <span className="mx-3">Spam Rate</span>
                                                    </div>
                                                    <div className={`mr-3 variations-difference justify-center`}>
                                                        Difference
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                contentTest.variations.map((variations,variationsIndex) => {
                                                    return (
                                                        <Link key={variationsIndex} to={
                                                            (contentTest.test_status === 'draft'
                                                            ?   USER_CREATE_CONTENT_TESTS
                                                            :   USER_EDIT_CONTENT_TESTS)
                                                            + contentTest.test_id
                                                        }>
                                                            <div className={`ns-shadow-xl ns-rounded-lg ${!variationsIndex ? 'mt-2' : 'mt-6' } flex w-full`}>
                                                                <p className={`variations-title variat_${variationsIndex} px-4 py-3 w-3/4 text-left pl-5`}>
                                                                    {variations.variation_name} 
                                                                </p>
                                                                <div className="variations-cal text-right w-1/2 flex justify-between py-4">
                                                                    <div className="variations-email-sent">
                                                                        {variations.email_sent ? variations.email_sent : '-'}
                                                                    </div>
                                                                    <div className={`variations-reputation`}>
                                                                        {variations.reputation.length ? variations.reputation.slice(-1)+'%' : '-'}
                                                                    </div>
                                                                    <div className="variations-spam-rate">
                                                                        {variations.reputation.length ? variations.spam_rate.slice(-1)+'%' : '-'}
                                                                    </div>
                                                                    <div className={` ${variations.difference && 'dorian-replies-important'} mr-3 variations-difference justify-center`}>
                                                                        {variations.difference ? variations.difference+"%" : "-"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                )
                                              
                            }))
                        : <EmptyMailboxScreen emptyTitle="Add your first content test!" />
                    : <Space className="w-full" direction="vertical"><ChartLoadingScreen subDomain={true} /><ChartLoadingScreen subDomain={true} /></Space>
                }
            </Content>
        </div>
    )
}


export default ContentTestsScreen
import React, { useEffect, useState } from 'react'
import { Layout, Switch, Tag, notification, Tooltip, Button, Spin } from 'antd';
import { useHistory } from "react-router-dom";
import { CheckCircleTwoTone } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import AxiosApi from '../config/axios-api';
import { useDispatch,useSelector } from 'react-redux';
import { planAllDetailsAction, planDetailsAction } from '../Store/Actions/planDetailsActions';
import StripeSplitElementsScreen from './StripeSplitElementsScreen';
import ConfirmUpgradePlanModal from '../Modals/ConfirmUpgradePlanModal';
import { USER_SETTINGS } from '../Routes/Constants/RouteUrls';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { stripeCustomerPortalRedirect } from '../Helpers/StripeHelper';
import { loadingWrapper } from '../Helpers/WrapperHelper';
import { openNotificationError, openNotificationSuccess } from '../Helpers/globalNotification';

const PricingScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const planAllDetails = useSelector((state) => state.planAllDetails);
  const { planAllData, loading
    //, error 
  } = planAllDetails;
  const planDetails = useSelector((state) => state.planDetails);
  let { planData, loading:loadingPlanData 
    //, error:errorPlanData 
  } = planDetails;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [planType, setPlanType] = useState('month, billed annually');
  const [selectedPlanData, setSelectedPlanData] = useState('annual');
  const [selectedPlanId, setSelectedPlanId] = useState(0);
  const [loader, setloader] = useState(!!loading);
  const [stripeConfig, setStripeConfig] = useState({});
  const [invoiceUpcoming, setInvoiceUpcoming] = useState(null);
  const userId = localStorage.getItem("userId")
  const companyId = localStorage.getItem("companyId")
  const returnUrl = `${USER_SETTINGS}?tab=4`;
  const demoData = localStorage.getItem("demoData")
  const [visible, setVisible] = useState(false);

  if(userId) window.heap.identify(userId);

  if(!userId || !companyId){
    localStorage.removeItem('state');
    planData = null;
    const signupEmail = localStorage.getItem("signupUserEmail");
    const signupUserData = JSON.parse(localStorage.getItem("signupUserData"));
    if(!signupUserData?.userId || !signupUserData?.companyId || !signupEmail){
      window.location.href = "/";
    }
  }

  const stripeFuncCaller = (funName,billingData) => {
    let stripeFn = () => funName(billingData)
    return loadingWrapper(stripeFn,setloader)
  }

  const upgradeCardDetails = async () => {
    const billingData = { userId, companyId,  returnUrl:`${window.location.origin+returnUrl}` }
    stripeFuncCaller(stripeCustomerPortalRedirect,billingData);
  };

  const { Content } = Layout;
  let currentPlan;
  const setPlanTypeTo = (planAnnual) => {
      let planAnnualType = planAnnual ? 'annual':'monthly'
      setPlanType(planAnnual ? 'month, billed annually':'month')
      setSelectedPlanData(planAnnualType)
      if(planAllData?.planData){
        if(currentPlan?.upgradePlanType?.toLowerCase()===planAnnualType) {
          handleClick(currentPlan.upgradeStripePriceId,currentPlan.upgrade_plan_id,'',currentPlan.upgradePrice,currentPlan.upgradeName);
        } else {
          console.log(`(planAllData?.planData`, (planAllData?.planData))
          planAllData.planData[planAnnualType].forEach((plan) => {
            if(plan.name===`Premium ${planAnnualType.toCapitalize()}`){
              handleClick(plan.stripe_price_id,plan.id,'',plan.price,plan.name);
            }
          });
        }  
      }
  }

  String.prototype.toCapitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  }

  const handleClick = async (priceId,planId,planType,planPrice,planName) => {
    setSelectedPlanId(planId);
    setStripeConfig({priceId,planId,planPrice,planName});
    return;
  };

  if( 
      planData && 
      planData?.planData &&
      planData?.planData?.records &&
      planData?.planData?.records[0]?.hasCustomerStripe
  )
  {
    currentPlan = planData.planData.records[0];
  }

  if (!loadingPlanData && currentPlan?.upgradeStripePriceId && !selectedPlanId) {
    handleClick(currentPlan.upgradeStripePriceId,currentPlan.upgrade_plan_id,'',currentPlan.upgradePrice,currentPlan.upgradeName);
    // setPlanTypeTo(false);
    // console.log("currentPlan.upgradeName.includes('monthly')", [currentPlan.upgradeName,currentPlan.upgradeName.includes('Monthly')]);
    (currentPlan.upgradeName.includes('Monthly') && setPlanTypeTo(false));
  } else if (!loading && planAllData?.planData && !selectedPlanId) {
    console.log('Pricing planAllData', planAllData)
    planAllData.planData['annual'].filter((plan) => {
      if(plan.name==='Premium Annual')
        handleClick(plan.stripe_price_id,plan.id,'',plan.price,plan.name)
        return true;
        //handleClick('price_1Hj68mLQ6Ktiy2Qlwbx3zTs6',7,'',216,'Premium Annual')
    });
  }

  const upgradeSuggestedPlan = async () => {
    // Get Stripe.js instance
    if(demoData) return;
    setloader(true);
    const billingData = { userId, companyId, planId:stripeConfig.planId,  upgradeStripePriceId:stripeConfig.priceId }
    // const billingData = { userId, companyId, planId:2,  upgradeStripePriceId:'price_1Hj674LQ6Ktiy2QlqUxRxub1' }
    
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    let invoice = await AxiosApi.post('billing/retrieve-upcoming-invoice',JSON.stringify(billingData)).catch((error)=>{
      console.log(error);
      setloader(false);
      openNotificationError({
          description: ((typeof error.errorMessage==='string') && error.errorMessage) || error.message || 'Something went wrong'
      });
    });
    console.log('invoice', invoice);
    setloader(false);

    if(invoice?.data?.result){ 
      invoice.data.result.planName = stripeConfig.planName;
      setInvoiceUpcoming(invoice?.data?.result);
      setVisible(true);
    }
    
  };

  const confirmSubscription = async (payInvoice) => {
    setloader(true);
    const confirmSubscription = await AxiosApi.post('billing/upgrade-subscription',JSON.stringify(payInvoice.data.result));
    console.log('confirmSubscription...', confirmSubscription);
    if (confirmSubscription?.data?.successMessage==='Success') {
      successMsg();
    }
    setloader(false);
  }

  const upgradeSuggestedPlanPay = async () => {
    // Get Stripe.js instance
    setVisible(false);
    if(demoData) return;
    setloader(true);
    const stripe = await stripePromise;
    const billingData = { userId, companyId, planId:stripeConfig.planId,  upgradeStripePriceId:stripeConfig.priceId }
    // const billingData = { userId, companyId, planId:2,  upgradeStripePriceId:'price_1Hj674LQ6Ktiy2QlqUxRxub1' }
    let requireConfrim = ['requires_confirmation','requires_action']
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    let payInvoice = await AxiosApi.post('billing/upgrade-customer-plan',JSON.stringify(billingData));
    console.log('pay invoice', payInvoice);
    if( 
      requireConfrim.includes(payInvoice?.data?.result?.paymentIntentStatus) &&
      payInvoice?.data?.result?.clientSecret
    ){
      stripe.confirmCardPayment(
        payInvoice.data.result.clientSecret,
        { setup_future_usage: 'off_session' }
      ).then(function(result) {
        if (result.error) {
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          setloader(false);
          console.log('error confirm api:', result.error);
          openNotificationError({description: result.error.message});
        } else {
          // Show a success message to your customer
          //confirmSubscription(subscription.id);
          confirmSubscription(payInvoice);
        }
      }).catch(function(error) { 
            console.log(error);
      });
    }
    else if(payInvoice?.data?.successMessage==='Success'){
      setTimeout(async() => { await successMsg(); setloader(false); },5000);
    } else {
      setloader(false);
    }
  };

  const successMsg = async () => {
    openNotificationSuccess({description: 'Payment has done successfully'});
    currentPlan?.subscription_status==='cancelled' && await dispatch(planDetailsAction(userId,'update'));
    history.push(returnUrl)
  }

  useEffect(() => {
    dispatch(planAllDetailsAction());
  }, [])
  console.log(`currentPlan`, currentPlan)
  const userHasAnyPlan = (userId && companyId) && !!planData?.planData?.records[0]?.id;
  const userOnboard = (!userId && !companyId)
  const checkPassed = (userHasAnyPlan || userOnboard)
  console.log(`passed check`, {userHasAnyPlan,userOnboard,checkPassed})
  return (
    !loading && planAllData?.planData ?
    <div>
      <Content className="mx-4 mt-6 mb-0">
        <div className="site-layout-background">
          {/* <button role="link" onClick={handleClick}>
      Checkout
    </button> */}
    {invoiceUpcoming && <ConfirmUpgradePlanModal visible={visible} upgradeSuggestedPlanPay={upgradeSuggestedPlanPay} invoiceUpcoming={invoiceUpcoming} onCancel={() => setVisible(false)} />}    
        <Spin spinning={loader}>
          <section
            className="flex flex-row lg:flex-row items-center lg:justify-center w-full lg:px-0 py-0"
          >
            <div>
              <h5 className="text-4xl">
                <Tooltip title={`${checkPassed ?'Back':'Logout'}`} placement="left">
                  <ArrowLeftOutlined className="float-left pt-4 align-middle text-2xl"  onClick={()=> history.push(checkPassed ? returnUrl : '/logout')} />
                </Tooltip>
                Choose your plan
              </h5>
              <div className="flex flex-row flex-1 py-5">
                  <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-1" />
                  <h5 className="pr-4">14-day money-back guarantee</h5>
                  <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-1" />
                  <h5 className="pr-4">cancel anytime</h5>
                  <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-1" />
                  <h5 className="pr-4">works with all email providers</h5>
              </div>
              <div className="text-xl py-5 px-5">
                <Switch checkedChildren="Annual" onChange={setPlanTypeTo} checked={planType!=='month'} unCheckedChildren="Monthly" defaultChecked />
              </div>
            </div>
          </section>
          <section
            className="flex flex-col lg:flex-row  items-center pb-8 lg:justify-center w-full lg:px-0 py-0"
          >
            
              {
                (planAllData.planData[selectedPlanData].map(
                  (plan, PlanIndex) => {
                    return (
                      currentPlan?.id !== plan.id &&
                      <article key={PlanIndex}
                        onClick={() => handleClick(plan.stripe_price_id,plan.id,(plan.extra_mailbox_price?'Element':'Auto'),plan.price,plan.name)}
                        className={` ${(selectedPlanId===plan.id) && 'ns-bd-green'} cursor-pointer  relative bg-white mx-8 ns-shadow-xl border w-1/4 lg:w-custom mb-10 lg:px-4 px-6 py-3 text-center text-primary-dark ns-rounded-lg`}
                        style={{ minHeight: '525px' }}
                      >
                        <h2 className="text-2xl text-primary-normal font-bold pb-12 pt-3">{plan.name} {plan.is_popular ? <Tag className="rounded-lg ml-2" color="#52c41a">Popular</Tag>:''}</h2>
                        <h5  className="h-20 font-bold text-base">{plan.description}</h5>
                        <h2 className="pb-4 py-10 flex justify-center font-bold border-gray-300">
                        <span className="text-base mt-8 mr-1">$</span><span className="text-5xl">{selectedPlanData==='annual'?(plan.price/12):plan.price}</span><span className="text-base mt-2 ml-1">{ !plan.extra_mailbox_price && 'Per Mailbox' }<br/>/  <span className="capitalize">{planType}</span></span>
                        </h2>
                        <ul className="text-md mb-4 text-left font-normal pl-5 ns-gray v-align-2">
                          {
                            JSON.parse(plan.benefits_description).map((benefitsDescription, benefits_description_index) => {
                              return (
                                <li className="pt-3" key={benefits_description_index}><CheckCircleTwoTone twoToneColor="#52c41a"className="pl-3 pr-3" />{benefitsDescription}</li>
                              )
                            })
                          }
                        </ul>
                        {/* <button key={1}  style={{ left: '31%' }} className={` ${(selectedPlanId==plan.id) && 'hidden'} absolute bottom-0 btn text-base my-5 mt-8 focus:outline-none ns-bg-green py-2 px-10 ns-rounded-lg text-white`}>Join now</button> */}
                      </article>
                    )
                  })
                )
              }
              {/* {handleClick('price_1Hj68mLQ6Ktiy2Qlwbx3zTs6',7,'',216,'Premium Annual')} */}
              </section>
              { 
              stripePromise && stripeConfig.planId && !currentPlan 
              ?  <section>
                  <StripeSplitElementsScreen setStripeConfig={setStripeConfig} stripeConfig={stripeConfig} stripePromise={stripePromise} />
                </section>
              : <section className="flex flex-col items-center pb-8 justify-center">
                  <button onClick={()=> upgradeSuggestedPlan()} className="h-10 ns-bg-green focus:outline-none hover:bg-dark-green text-white ns-rounded-lg w-1/4">
                    Upgrade To {stripeConfig.planName}
                  </button>
                  {
                    currentPlan?.subscription_status==='cancelled' &&
                        <Button type="text" className="h-10 my-4 w-1/4 text-gray-600  hover:text-gray-700 hover:bg-gray-300 ns-rounded-lg" onClick={()=> upgradeCardDetails()}>Use another card for payment</Button>
                  } 
                </section>
              }
          </Spin>  
            </div>
            </Content>
        </div>
     : <Spin className="p-56" size="large" spinning={true}></Spin>
    )
}

export default PricingScreen
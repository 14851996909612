import React,{ useEffect, useState } from 'react'
import { Layout,Modal, Space, PageHeader,Dropdown,Menu, message, Spin } from 'antd';
import { LeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, useParams,useHistory } from "react-router-dom";
import SpamRateOfMailboxScreen from './Shared/SpamRateOfMailboxScreen';
import AxiosApi from '../config/axios-api';
import { useSelector, useDispatch } from 'react-redux';
import { mailboxIdStatisticsAction } from '../Store/Actions/mailboxActions';
import { outgoingMailboxDailyExpectedEmailsAction } from '../Store/Actions/outgoingMailboxActions';
import { mailboxInsightsAction } from '../Store/Actions/insightsActions';
import { USER_MAILBOXES } from '../Routes/Constants/RouteUrls';
import EditConnectMailboxFormModal from '../Modals/EditConnectMailboxFormModal';
import InsightCollapsePanel from './Shared/InsightCollapsePanel';
import ChartLoadingScreen from './Shared/ChartLoadingScreen';

const EditMailBoxScreen = () => {
    const {Header,Content} = Layout;
    const dispatch = useDispatch();
    const history = useHistory();
    const [visibleForm, setVisibleForm] = useState(false);
    const [visibleInsights, setVisibleInsights] = useState(false);
    const demoData = localStorage.getItem("demoData")
    localStorage.setItem("perviousPage","editMailbox");
    const arrEmailAccounts = JSON.parse(localStorage.getItem('arrEmailAccounts'));
    const mailboxIdStatistics = useSelector((state) => state.mailboxIdStatistics);
    const { mailboxDomainStatistics } = useSelector((state) => state.mailboxDomainStatisticsInfo);
    const { statistics, loading, 
            //error 
    } = mailboxIdStatistics;
    const mailboxInsightsDetails = useSelector((state) => state.mailboxInsightsDetails);
    const { mailboxInsights, loading:mailboxLoading, 
        //error:mailboxError 
    } = mailboxInsightsDetails;
    const outgoingMailboxExpectedEmails = useSelector((state) => state.outgoingMailboxExpectedEmails);
    const { dailyExpectedEmails, 
        //loading:dailyExpectedEmailsLoading, error:dailyExpectedEmailsError 
    } = outgoingMailboxExpectedEmails;
    let { mailboxId } = useParams();
    const userId = localStorage.getItem("userId")
    let selectedMailbox = userSearch(parseInt(mailboxId));
      
    function userSearch(id) {
        return arrEmailAccounts.find(u => u.id === parseInt(mailboxId));
    }

    useEffect(() => {
        dispatch(mailboxInsightsAction(mailboxId));
        dispatch(mailboxIdStatisticsAction(mailboxId));
        dispatch(outgoingMailboxDailyExpectedEmailsAction({mailboxId}));
        return () => localStorage.setItem("perviousPage","Mailbox");
    },[])

    const { confirm } = Modal;
    const showUpdateCredentials = ()  => {
        setVisibleForm(true);
    }
    const showConfirm = ()  => {
        confirm({
          title: 'Do you want to delete these mailbox from this account?',
          icon: <ExclamationCircleOutlined />,
          content: 'It will deleted all details of your this mailbox',
          async onOk() {
            try {
                  if(!demoData)  {
                    await AxiosApi.delete('mailboxes/delete-mailbox?mailboxId='+mailboxId+'&userId='+userId);
                  }
                  message.info('Mailbox deleted successfully.');
                  history.push(USER_MAILBOXES+'?isDelete=true');
            } catch (e) {
                console.log('error delete mailboxes:', e);
                message.error(e.errorMessage || e.message);
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }

    const menu = (
        <Menu>
          <Menu.Item onClick={  ()=>showConfirm()} className="text-gray-600" key="1" >
            Delete Mailbox
          </Menu.Item>
          <Menu.Item onClick={  ()=>showUpdateCredentials()} className="text-gray-600" key="2" >
            Update Credentials
          </Menu.Item>
        </Menu>
      );
    const {ObjSpamRateOfMailbox,
        /*ObjSeprate,*/
        domain_name} = selectedMailbox
    return (
        <div>
            {
                selectedMailbox 
                && <EditConnectMailboxFormModal
                        visible={visibleForm}
                        setVisibleForm={setVisibleForm}
                        mailboxData={selectedMailbox.sub_accounts}
                        onCancel={() => setVisibleForm(false)}
                />       
            } 
            <Header className="site-layout-sub-header-background" style={{ padding: 0 }}> 
                <PageHeader  className="site-page-header"
                    backIcon={<Link to={USER_MAILBOXES}><LeftOutlined className="text-gray-500 text-2xl p-1" /></Link>} 
                    extra={  <Dropdown className="rounded-lg pr-8 pt-16 text-gray-600" trigger={['click']} overlay={menu}>
                                <a className="ant-dropdown-link pr-2 underline" href="#/" onClick={e => e.preventDefault()}>
                                    Edit
                                </a>
                            </Dropdown>} 
                    onBack={() => null} title="Edit Mailbox"
                />
            </Header>
            <Content className="mx-6 my-6 ant-layout-content-static pt-2 mt-0 h-screen">
                <div className="site-layout-background ns-rounded-lg">
                    <div className="site-statistic-demo-card">
                        <Space direction="vertical w-full">
                            {
                                statistics?.records && statistics?.records[domain_name] && !loading
                                ? <SpamRateOfMailboxScreen className="flex" chartDataStat={mailboxDomainStatistics} dailyExpectedEmails={dailyExpectedEmails} setVisibleUpdateCredentialsForm={setVisibleForm} selectedMailbox={selectedMailbox}  statistics={statistics.records} domainName={domain_name} ObjSpamRateOfMailbox={ObjSpamRateOfMailbox}/>
                                : loading 
                                    ? <ChartLoadingScreen className="flex"  domainName={selectedMailbox?.email ?? domain_name}/>
                                    :  <SpamRateOfMailboxScreen className="flex" chartDataStat={mailboxDomainStatistics} dailyExpectedEmails={dailyExpectedEmails} setVisibleUpdateCredentialsForm={setVisibleForm} selectedMailbox={selectedMailbox}   domainName={domain_name} ObjSpamRateOfMailbox={ObjSpamRateOfMailbox}/>

                            }
                            {
                                (!mailboxLoading && mailboxInsights &&  (mailboxInsights.unarchived && mailboxInsights.unarchived.length!==0))
                                &&   <div>
                                        <Spin size="large" spinning={visibleInsights}>
                                            <Content className="my-6 pb-5 pt-2 mt-0">
                                                <h1 className='Spam-Rate pl-2  pt-3 w-1/2 text-left' style={{fontSize:"28px"}}>Insights</h1>
                                                <div className="pt-3">
                                                    <InsightCollapsePanel setVisibleInsights={setVisibleInsights}  mailboxId={mailboxId} InsightsType="Insights" Insights={mailboxInsights.unarchived} />
                                                </div>
                                            </Content>
                                        </Spin>
                                    </div>
                            }
                        </Space>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default EditMailBoxScreen
import React, { useState } from 'react'
import {Layout,PageHeader,Form,Input,Button,Spin,notification} from 'antd';
import { Auth } from "aws-amplify";

const SecurityScreen = () => {
    const demoData = localStorage.getItem("demoData")
    const [loader, setloader] = useState(false);    
    const openNotification = placements => {
        notification[placements.type]({
          message: `Password ${placements.msg}`,
          description: 
            placements.description,
          placement:placements.apperence,
        });
    };

    const {Content} = Layout;
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 5,
        },
    };
    
    const tailLayout = {
        wrapperCol: {
            offset: 1,
            span: 4,
        },
    };

    const onFinish = values => {
            setloader(true);
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, values.CurrentPassword, values.NewPassword);
                })
                .then(data => 
                    {
                        openNotification({
                            type: 'success',
                            msg: 'Success',
                            description: 'Password updated successfully',
                            apperence: 'topRight',
                        });
                        setloader(false);
                    }
                )
                .catch(err => {
                    // alert(e.message);
                    openNotification({
                        type: 'error',
                        msg: 'Error',
                        description: err.message,
                        apperence: 'topRight',
                    });
                    setloader(false);
                });
    };

    return (
        <div>
            <Content className="mx-6 my-6">
                <div className="ns-shadow-xl ns-rounded-lg ns-content-section">
                    <PageHeader className="p-5"  title="Password"/>
                    <Spin spinning={loader}>
                        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                            <Form.Item
                                name="CurrentPassword"
                                label="Current Password"
                                rules={[{
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Current Password" className="rounded-md" />
                            </Form.Item>
                            <Form.Item
                                name="NewPassword"
                                label="New Password"
                                rules={[{
                                        required: true,
                                        pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"),
                                        message: 'Your password is weak',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="New Password" className="rounded-md" />
                            </Form.Item>
                            <Form.Item
                                name="ConfirmPassword"
                                label="Confirm Password"
                                rules={[{
                                        required: true,
                                        pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"),
                                        message: 'Your password is weak',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password"  className="rounded-md" />
                            </Form.Item>
                            <Form.Item {...tailLayout} className="float-right  p-5">
                                {!demoData && <Button type="primary"  className="rounded-md"  htmlType="submit">
                                    Update Password
                                </Button>}
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Content>
        </div>
    )
}

export default SecurityScreen
import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify";

const FogortPasswordModal = ({ setForgotEmail, setVisible, setVisibleReset, visible, onCancel, onCreate}) => {
    
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
        span: 8,
        },
        wrapperCol: {
        span: 23,
        },
    };

    const tailLayout = {
        wrapperCol: {
        offset: 0,
        span: 23,
        },
    };
    let user;

    const onFinish = async values => {
        try {
            let email=values.email;
            user = await Auth.forgotPassword(values.email).catch( async error => {
                  console.log(error);
                  if(error.name === 'UserNotFoundException'){
                      email=values.email.toLowerCase();
                      user = await Auth.forgotPassword(email, values.password);
                      return user;
                  }
                  throw (error);
              });
            if (user) {
              message.success('Email sent to your mailbox successfully. Please enter that code here for reset password');
              console.log('forgotPassword',user);
              setForgotEmail(email);
              setVisible(false);
              setVisibleReset(true);
            }
          } catch (error) {
              console.log('error confirming sign up', error);
              message.error(error.message);
          }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
  return (
    <Modal
    className="forgot-password-modal ns-rounded-lg"
      visible={visible}
      title=""
      icon={<ExclamationCircleOutlined />}
      okText="Reset My Password"
      onCancel={onCancel}
      width={340}
      onOk={onFinish}
      footer={null}
    >
      <Form  form={form}
        {...layout}
        initialValues={{
            remember: true,
        }}
        
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
       layout="vertical">
        <Form.Item className="" {...tailLayout}>
            <h2 className="pb-6 pt-1 text-4xl text-center">Forgot Password</h2>
            <p className="please-type-in-your pb-6 pt-1">Please type your email and if we have an account with that address, we will send you the instructions for how to reset your password.</p>
        </Form.Item>
        <Form.Item
            name="email"
            rules={[
            {
                required: true,
                message: 'Please input your email!',
                type: 'email'
            },
            ]}
        >
            <Input placeholder="Email"  className="float-left rounded-md" />
        </Form.Item>
        <Form.Item className="" {...tailLayout}>
            <Button type="primary" className="rounded-md mt-3" size="large" htmlType="submit" block>
                Reset My Password
            </Button>
        </Form.Item>
      </Form>
      
    </Modal>
  );
};

export default FogortPasswordModal;
import AxiosApi from "../config/axios-api";
AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';

// When the customer clicks on the button, redirect them to Checkout.
const stripeCustomerPortalRedirect = async (billingData) => {
  let session = await AxiosApi.post('billing/create-customer-portal-session',JSON.stringify({billingData}))
  if(session?.data?.result?.session?.url) window.location.href = session.data.result.session.url;
};

const stripeRetrieveUpcomingInvoice = async (billingData) => {
  return await AxiosApi.post('billing/retrieve-upcoming-invoice',JSON.stringify(billingData));
};

const stripeUpgradeCustomerPlan = async (billingData) => {
  return await AxiosApi.post('billing/upgrade-customer-plan',JSON.stringify(billingData));
};

const stripeUpgradeSubscription = async (payInvoice) => {
  return await AxiosApi.post('billing/upgrade-subscription',JSON.stringify(payInvoice));
};

export { stripeCustomerPortalRedirect, stripeRetrieveUpcomingInvoice, stripeUpgradeCustomerPlan, stripeUpgradeSubscription }
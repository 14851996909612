import AxiosApi from '../../config/axios-api';
import {
  PLAN_DETAILS_REQUEST,PLAN_DETAILS_REQUEST_SUCCESS,PLAN_DETAILS_REQUEST_FAIL,
  PLAN_ALL_DETAILS_REQUEST, PLAN_ALL_DETAILS_REQUEST_SUCCESS,PLAN_ALL_DETAILS_REQUEST_FAIL,
} from "../Constants/planDetailsConstant";

const planDetailsAction =   (userId,type) => async (dispatch) => {
  try {
    if(type!=='update')
      dispatch({ type: PLAN_DETAILS_REQUEST, payload: userId });
    const { data } = await AxiosApi.get('billing/get-plan-details/' + userId);
    dispatch({ type: PLAN_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: PLAN_DETAILS_REQUEST_FAIL, payload: error });
  }
};

const planAllDetailsAction = () => async (dispatch) => {
  try {
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST });
    let hasPlan = 'false';
    let userId = localStorage.getItem('userId')
    const serializedState = localStorage.getItem('state');
    if (serializedState !== null && userId) {
      let planData = JSON.parse(serializedState);
      if(planData?.planDetails?.planData?.planData?.records[0]?.name)
        hasPlan = 'true';
    }
    const { data } = await AxiosApi.get('billing/get-all-plans-details/?hasPlan='+hasPlan);
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: PLAN_ALL_DETAILS_REQUEST_FAIL, payload: error.message });
  }
};

export { 
  planDetailsAction,
  planAllDetailsAction,
};
import React from 'react'
import parse from 'html-react-parser';
import emailTypesJson from '../../Json/emailTypesPopover.json'

const Tooltips = (type) => {
  return (
    <div style={{width:"710px"}}>
      {
        emailTypesJson[type]?.map((tips,index) => {
          let { title, content } = tips;
          return  (
            <div key={index}>
              {title && <h2 className={`The-Allegrow-platforMargin ${title.toLowerCase().replace(' ','-')}`}>{title}</h2>}
              {content && <p className={`The-Allegrow-platfor ${!type.includes('daily') && 'py-2'}`}>{parse(content)}</p>}
            </div>
          )
        })
      }
    </div>
  )
}

export default Tooltips

import AxiosApi from '../../config/axios-api';
import {
  COMPANY_DETAILS_REQUEST,COMPANY_DETAILS_REQUEST_SUCCESS,COMPANY_DETAILS_REQUEST_FAIL,
  COMPANY_DETAILS_SAVE_REQUEST,COMPANY_DETAILS_SAVE_SUCCESS,COMPANY_DETAILS_SAVE_FAIL
} from "../Constants/companyDetailsConstant";

const companyDetailsAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_DETAILS_REQUEST, payload: companyId });
    const { data } = await AxiosApi.get('company/get-company-details/' + companyId);
    dispatch({ type: COMPANY_DETAILS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: COMPANY_DETAILS_REQUEST_FAIL, payload: error.message });
  }
};

//companyData : {"company_id":1,"website":"https://google.com","name": "google"}
const companyDetailsSaveAction = (companyData) => async (dispatch) => {
  try {
    console.log('companyData',companyData);
    dispatch({ type: COMPANY_DETAILS_SAVE_REQUEST, payload: companyData });
    AxiosApi.defaults.headers.put['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.put('company/update-company-details',companyData);
    dispatch({ type: COMPANY_DETAILS_SAVE_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: COMPANY_DETAILS_SAVE_FAIL, payload: error.message });
  }
};

export { 
  companyDetailsAction,
  companyDetailsSaveAction 
};
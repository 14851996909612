import React, { useState,useEffect } from 'react'
import {Layout,PageHeader,Form,Input,Button,Spin,notification} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { companyDetailsAction,companyDetailsSaveAction } from '../Store/Actions/companyDetailsActions';

const CompanyScreen = () => {

    var companyId;
    const dispatch = useDispatch();
    const demoData = localStorage.getItem("demoData")
    const companyDetails = useSelector((state) => state.companyDetails);
    const { companyData, 
        /*loading, error*/ 
    } = companyDetails;
    const [loader, setloader] = useState(false);
    const openNotification = placements => {
        notification[placements.type]({
          message: `${placements.msg}`,
          description: 
            placements.description,
          placement:placements.apperence,
        });
    };

    console.log('companyData',companyData)
    const {Content} = Layout;
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 5,
        },
    };
    
    const tailLayout = {
        wrapperCol: {
            offset: 1,
            span: 4,
        },
    };

    const onFinish = async values => {
        setloader(true);
        console.log(localStorage.getItem("companyId"))
        companyId=localStorage.getItem("companyId");
        let objData = {...values,...{company_id:companyId}};
        try{
            await dispatch(companyDetailsSaveAction(objData));
            dispatch(companyDetailsAction(companyId));

            openNotification({
                    type: 'success',
                    msg: 'Success',
                    description: 'Company updated successfully',
                    apperence: 'topRight',
                });
          } catch (e) {
            console.log('error user-details API:', e);
            openNotification({
                type: 'error',
                msg: 'Error',
                description: e.message,
                apperence: 'topRight',
            });
          }
          setloader(false);
    };

    if(companyData && companyData.records) {
        form.setFieldsValue({
            name: companyData.records[0].name,
            website: companyData.records[0].website
        });
      }

    useEffect(() => {
      const fetchData = async() => {
            try{
              companyId = localStorage.getItem("companyId");
              if(companyId)
                dispatch(companyDetailsAction(companyId));
              
            } catch (e) {
              console.log('error user-details API:', e);
            }
      }
      fetchData();
      
    },[])

    return (
        <div>
            <Content className="mx-6 my-6">
                <div className="ns-shadow-xl ns-rounded-lg" style={{height:'265px'}}>
                    <PageHeader className="p-5"  title="Company Details"/>
                     {       
                        companyData && companyData.records
                        ?  (
                              <Spin spinning={loader}>
                                    <Form {...layout} form={form}   name="control-hooks" onFinish={onFinish}>
                                        <Form.Item
                                            name="name"
                                            // initialValue={companyData.records[0].name || ''}
                                            label="Company Name"
                                            rules={[{
                                                    required: true,
                                                },
                                            ]}
                                            
                                        >
                                            <Input placeholder="Company Name"  className="rounded-md" />
                                        </Form.Item>
                                        <Form.Item
                                            name="website"
                                            label="Website"
                                            // initialValue={companyData.records[0].website || ''}
                                            rules={[{
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input  placeholder="Website" className="rounded-md" />
                                        </Form.Item>
                                        <Form.Item {...tailLayout} className="float-right  p-5">
                                        {!demoData && <Button type="primary"  className="rounded-md"  htmlType="submit">
                                                Update Company
                                            </Button>}
                                        </Form.Item>
                                    </Form>
                                </Spin>
                        )
                    :<Spin spinning={true} tip="Loading Company Details..." ></Spin>
                    }
                </div>
            </Content>
        </div>
    )
}

export default CompanyScreen
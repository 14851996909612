import React, { useState, useEffect } from 'react'
import { Card, DatePicker, Typography } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

const DateRangeCard = ({form,Form,cardValueInit,card1ValueInit,contentTestsDraft,cardTitle="Test End",cardTitle1="Remaining",isSelectable=false}) => {
  const { Title  } = Typography;
  const [cardValue, setCardValue] = useState(cardValueInit);
  const [cardValue1, setCardValue1] = useState(card1ValueInit);
  const [dates, setDates] = useState([]);
  const disabledDate = current => {
    return moment().add(-1, 'days') >= current || 
    (document.querySelector('.ant-picker-input-active input')?.hasAttribute("id")===false && (moment(dates[0],"MMM	D").add(30, 'days') > current));
  };

  useEffect(() => {
    contentTestsDraft?.startDate && setCardValue(moment(contentTestsDraft?.startDate, 'YYYY-MM-DD').format("MMM	D"))
    contentTestsDraft?.endDate && setCardValue1(moment(contentTestsDraft?.endDate, 'YYYY-MM-DD').format("MMM D"))
  }, [contentTestsDraft])

  const onChange = (seletedDates,dateStrings,info) => {
    console.log(`seletedDates`, {seletedDates,dateStrings,info})
    let endDateMoreThanMonth = moment(dateStrings[1],"MMM D") > moment(dateStrings[0],"MMM D").add(30, 'd');
    seletedDates[0] = moment(dateStrings[0],"MMM D")
    seletedDates[1] = endDateMoreThanMonth ? moment(dateStrings[1],"MMM D") : moment(dateStrings[0],"MMM D").add(30, 'd');
    console.log(`dates`, dates)
    setDates(seletedDates);
  }
  
  const cardKey = cardTitle.toLowerCase().replace(' ','_');
  return (
    <div className="date-range-card">
    <Card className={`statistics-joined-card  ns-bg-light-white ns-rounded-lg   ${cardKey} `}  >
      <div className="flex-col">
        <div><Title style={{color:'rgba(38, 38, 38, 0.85)'}} level={4}>Test Period</Title></div>
        <Form.Item
            name="test_date"
            initialValue={[moment(cardValue, 'MMM	D'),moment(cardValue1, 'MMM	D')]}
            rules={[{
                    required: true,
                    message: 'Please select date!',
                },
            ]}
            className="m-0 cursor-pointer"
        >
          <RangePicker  
            inputReadOnly
            allowClear={false} separator={''}
            value={dates}   format={'MMM D'}
            disabledDate={disabledDate} 
            onCalendarChange={onChange}
            className={`date-range-picker  antd-font`} bordered={false} 
            
          />
        </Form.Item>
      </div>
    </Card>
  </div>
  )
}

export default DateRangeCard
import React from 'react'

const EmptyMailboxScreen = ({emptyTitle}) => {
    return (
        <div className="site-layout-background">
            <div className="ns-shadow-xl rounded-lg mt-10">
                <p className="p-4 text-bold text-2xl">{emptyTitle ?? 'Add your first mailbox!'}</p>
            </div>
            <div>
                <img className="h-425px w-full my-8 text-center" alt="Empty Mailbox" src="/images/mailboxes_empty_state_background.svg"></img>
            </div>
        </div>
    )
}

export default EmptyMailboxScreen
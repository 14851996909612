import React from 'react';
import './App.css';
import './assets/main.css'
import { BrowserRouter as Router,Switch,Route } from "react-router-dom";
import SignupScreen from './Screens/SignupScreen';
import PublicRoute from './Routes/PublicRoute';
import LoginScreen from './Screens/LoginScreen';
import AppSumoScreen from './Screens/AppSumoScreen';
import PricingScreen from './Screens/PricingScreen';
import SinglePageRoute from './Routes/SinglePageRoute';
import ProtectedUserRoute from './Routes/ProtectedUserRoute';
import PageNotFoundScreen from './Screens/PageNotFoundScreen';
import LogoutScreen from './Screens/LogoutScreen';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <PublicRoute exact path="/" component={LoginScreen} />
          <PublicRoute path="/signup" component={SignupScreen} />
          <PublicRoute path="/logout" component={LogoutScreen} />
          <SinglePageRoute path="/pricing" component={PricingScreen} />
          <PublicRoute path="/appsumo/:appsumo_code" component={AppSumoScreen} />
          <PublicRoute path="/promocode/:appsumo_code" component={AppSumoScreen} />
          <Route path="/user" render={ProtectedUserRoute} />
          <Route path="" component={PageNotFoundScreen} />
        </Switch>
      </div>
    </Router>
  )
}


export default App;

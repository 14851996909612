import React , { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Image, Spin, Input, Button, Divider,notification, Select, Result} from "antd";
import { ExclamationCircleOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import ConnectedAppSpecificManualJson from '../Json/ConnectedAppSpecificManual.json'
import SuccessfulConnectedMailboxModal from "./SuccessfulConnectedMailboxModal";
import { mailboxValidateAction, mailboxConnectAction } from '../Store/Actions/mailboxActions';
import { openNotificationError } from "../Helpers/globalNotification"
import { isEmpty } from 'lodash';

const ConnectMailboxFormModal = ({ setVisible, setVisibleForm, visible, mailbox, upgradeSuggestedPlan,  fetchStatistics }) => {
    
    const [visibleSuccessConnect, setVisibleSuccessConnect] = useState(false);
    const [successfullyConnect, setSuccessfullyConnect] = useState(false);
    const [spinTipMessage, setSpinTipMessage] = useState('Verifying credentials...');
    const [objMailbox, setObjMailbox] = useState({});
    const { mailboxValidateData, mailboxValidateError, mailboxValidateLoading } = useSelector((state) => state.mailboxValidateInfo);
    const { mailboxConnectData, mailboxConnectError, mailboxConnectLoading } = useSelector((state) => state.mailboxConnectInfo);
    const dispatch = useDispatch();
    const userId = localStorage.getItem("userId")
    const demoData = localStorage.getItem("demoData")
    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false);
    const layout = { labelCol: {span: 8,},wrapperCol: {span: 23,},};
    const tailLayout = {wrapperCol: {offset: 0,span: 23,},};

    const onFinish = async values => {
        try {
            setLoader(true);
            let objMailbox = {
                "userId": userId,
                "provider": mailbox,
                "firstName": values.firstName,
                "lastName": values.lastName,
                "email": values.email,
                "password": values.password || "",
            };
            if (mailbox === 'custom') {
                objMailbox['customProvider'] = {
                    imap: {
                        "username": values.imap_username,
                        "password": values.imap_password,
                        "host": values.imap_host,
                        "port": values.imap_port,
                        "secure": values.imap_secure,
                    },
                    smtp: {
                        "username": values.smtp_username,
                        "password": values.smtp_password,
                        "host": values.smtp_host,
                        "port": values.smtp_port,
                        "secure": values.smtp_secure,
                    }
                }
            }

            if(!demoData){
                setObjMailbox(objMailbox)
                dispatch(mailboxValidateAction(objMailbox));
            }else{
                setLoader(false);
            }
        } catch (e) {
            openNotificationError({description:e.message,duration:15});
        }
    }
    

    useEffect(() => { !isEmpty(objMailbox) &&  mailboxValidateData && onValidateSuccess(mailboxValidateData) }, [mailboxValidateData])
    useEffect(() => { !isEmpty(objMailbox) && mailboxValidateError && onValidateError(mailboxValidateError) }, [mailboxValidateError])
    useEffect(() => { !isEmpty(objMailbox) && mailboxConnectData && onConnectSuccess(mailboxConnectData) }, [mailboxConnectData])
    useEffect(() => { !isEmpty(objMailbox) && mailboxConnectError && onConnectError(mailboxConnectError) }, [mailboxConnectError])

    const onValidateSuccess = mailboxValidateData => {
        console.log('onValidateSuccess:', mailboxValidateData);
        if(mailboxValidateData){
            setSpinTipMessage('Connecting mailbox...')
            dispatch(mailboxConnectAction(objMailbox));
        }
    };
    
    const onValidateError = e => { onConnectError(e) };

    const onConnectSuccess =  values => {
        console.log('onConnectSuccess:', values);
        setSuccessfullyConnect(true);
    };

    const onConnectError = error => {
        console.log('onConnectError:', error);
        let errorMsgAppend = 'For help, please contact us on Intercom or at hello@neverspam.io.';
        let errorMessage,e={};
        e.errorMessage = error?.errorMessage || error;
        if(e.errorMessage.includes('Cannot read property')){
            e.errorMessage = 'Request Timeout: Looks like the request is taking longer than usual, please check your internet connection and try again.';
        }
        if(e.errorMessage)
            errorMessage = (<div><p key={1}>{e.errorMessage}</p><p className="pt-2" key={2}>{errorMsgAppend}</p></div>);
        if(
            e.errorMessage.includes('mailbox limit') || 
            e.errorMessage.includes('mailboxes limit')
        ){
            setVisibleForm(false);
            setLoader(false);
            upgradeSuggestedPlan(errorMessage);
            return '';
        }
        openNotificationError({msg:'Error '+spinTipMessage,description:errorMessage,duration:15});
        setLoader(false);
    };

    const onFinishFailed = async values => {
        console.log('Success:', values);
    };

    const onCancel = () => {
        setVisibleForm(false)
        setLoader(false)
        if(successfullyConnect){
            fetchStatistics();
            setVisibleSuccessConnect(true);
            setSuccessfullyConnect(false)
        }
    };

    const backToMailboxes = async () => {
        setVisible(true);
        setVisibleForm(false);
    };

    const appSpecificManual =   <p className="pl-1 pb-3">
                                    Make sure you connect using an app-specific password.&nbsp;&nbsp;
                                    <a className="text-blue-400" target="_blank" rel="noopener noreferrer"  href={ConnectedAppSpecificManualJson[mailbox]}>
                                        Here’s how
                                    </a>
                                </p>;


                        
  return (
    <>
    <SuccessfulConnectedMailboxModal
        visible={visibleSuccessConnect}
        setVisible={setVisibleSuccessConnect}
        onCancel={() => setVisibleSuccessConnect(false)}
    />
    <Modal
        lassName="connect-mailbox-form-modal ns-rounded-lg"
        visible={visible}
        title=""
        icon={<ExclamationCircleOutlined />}
        okText="Confirm Verification Code"
        onCancel={onCancel}
        width={520}
        onOk={onFinish}
        footer={null}
    >
    {successfullyConnect
        ?   <Result
                status="success"
                title="Successfully connected your new mailbox!"
                extra={[
                    <Button className="rounded-md" type="primary" key="console" onClick={onCancel}>
                        Ok, got it
                    </Button>,
                ]}
            />
        : <>    
        <h2 className="pb-2 text-4xl text-center"><ArrowLeftOutlined className="float-left pt-2 pl-4 align-middle "  onClick={()=>backToMailboxes()} /> Connect New Email</h2>
        {mailbox!=='custom' 
            ? (<div className="flex flex-col px-4">
                <div className="text-center px-4 py-4 m-2"><Image width={mailbox==='icloud'?115:75} height={75}  preview={false} src={`/images/mailbox/${mailbox}.png`}></Image></div>
                </div>)
            : (<div className="pt-10"></div>)
        }
        <div className="pl-4">
            <Spin tip={spinTipMessage} spinning={loader}>
                <Form
                {...layout}
                form={form} 
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your First Name!',
                            },
                            ]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Input placeholder="First Name" className="rounded-md" />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Last Name!',
                            },
                            ]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <Input placeholder="Last Name" className="rounded-md" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                            type: 'email'
                        },
                        ]}
                    >
                        <Input placeholder="Email" className="float-left rounded-md" />
                    </Form.Item>
                    {mailbox!=='custom' &&
                    <div>
                    <Form.Item
                        name="password"
                        className="mb-0"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        ]}
                    >
                        <Input.Password autoComplete="create-password" placeholder="Password" className="rounded-md" />
                    </Form.Item>
                    {appSpecificManual}
                    </div>
                    }
                    {mailbox==='custom' &&
                    (
                        <div>
                            <Divider orientation="left">IMAP</Divider>
                            <Form.Item
                                name="imap_username"
                                className=""
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                                ]}
                            >
                                <Input placeholder="Username" className="rounded-md" />
                            </Form.Item>     
                            <Form.Item
                                name="imap_password"
                                className="mb-0"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                ]}
                            >
                                <Input.Password placeholder="Password" className="rounded-md" />
                            </Form.Item>
                            {appSpecificManual}
                            <Form.Item
                                name="imap_host"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your IMAP host!',
                                    },
                                    ]}
                                style={{ display: 'inline-block', width: 'calc(65% - 8px)' }}
                            >
                                <Input placeholder="Host (e.g. imap.gmail.com)" className="rounded-md" />
                            </Form.Item>
                            <Form.Item
                                name="imap_port"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Port required!',
                                    },
                                    ]}
                                style={{ display: 'inline-block', width: 'calc(35% - 8px)', margin: '0 8px' }}
                            >
                                <Input placeholder="port (e.g. 993)" className="rounded-md" />
                            </Form.Item>
                            <Form.Item  name="imap_secure">
                                <Select  defaultValue="ssltls" placeholder="Security Protocol"  className="w-full rounded-md">
                                    <Select.Option value="ssltls">SSL/TLS</Select.Option>
                                    <Select.Option value="insecure">Insecure</Select.Option>
                                </Select>
                            </Form.Item>
                            <Divider orientation="left">SMTP</Divider>
                            <Form.Item
                                name="smtp_username"
                                className=""
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                                ]}
                            >
                                <Input placeholder="Username" className="rounded-md" />
                            </Form.Item>     
                            <Form.Item
                                name="smtp_password"
                                className="mb-0"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                ]}
                            >
                                <Input.Password placeholder="Password" className="rounded-md" />
                            </Form.Item>
                            {appSpecificManual}
                            <Form.Item
                                name="smtp_host"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your SMTP host!',
                                    },
                                    ]}
                                style={{ display: 'inline-block', width: 'calc(65% - 8px)' }}
                            >
                                <Input placeholder="Host (e.g. smtp.gmail.com)" className="rounded-md" />
                            </Form.Item>
                            <Form.Item
                                name="smtp_port"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Port required!',
                                    },
                                    ]}
                                style={{ display: 'inline-block', width: 'calc(35% - 8px)', margin: '0 8px' }}
                            >
                                <Input placeholder="port (e.g. 465)" className="rounded-md" />
                            </Form.Item>
                            
                            <Form.Item  name="smtp_secure">
                                <Select defaultValue="starttls" placeholder="Security Protocol"  className="w-full rounded-md">
                                    <Select.Option value="starttls">STARTTLS</Select.Option>
                                    <Select.Option value="ssltls">SSL/TLS</Select.Option>
                                    <Select.Option value="insecure">Insecure</Select.Option>
                                </Select>
                            </Form.Item>

                        </div> 
                    )
                    }
                    <Form.Item className="pt-5" {...tailLayout}>
                        <Button type="primary" className="rounded-md w-full" size="large" htmlType="submit">
                            Connect Your Mailbox
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
    </div>
    </>
         }
</Modal>
</>
  );
};

export default ConnectMailboxFormModal;
import React from 'react'
import { Row, Col, Skeleton } from 'antd';
import StatisticsCard from './Cards/StatisticsCard';

const ChartLoadingScreen = ({ domainName, subDomain }) => {
    const skeletonBtn = <Skeleton.Button className='px-3 pt-1' style={{ width: 200 }} shape="round" active />
    return (
      <div>
        <div  className="site-layout-background pt-2 ns-rounded-lg">
            <div className="site-statistic-demo-card"></div>
              <div className="w-full">
                  <Row className="ns-shadow-xl ns-rounded-lg m-5 personal-statistic" gutter={8}>
                      <Col className="text-left ns-p-0" span={16}>
                          <h1 className='Spam-Rate pl-5 pt-5'>{domainName || skeletonBtn}</h1>
                          <div className={`line-chart`} >
                              <div className="chart-container"  style={{height:'292px', width:'54vw',display: 'flex',flexDirection: 'column-reverse',padding: '16px'}}>
                                <Skeleton active paragraph={{ rows: 6 }} />
                              </div>
                          </div>
                      </Col>
                      <Col span={8} className='chartCard chartCardContent'>
                        <Row key={1} className={`pt-5 pr-4`} gutter={16} style={{marginBottom:10}}>
                            <Col span={12} >
                                <StatisticsCard  chartData={" "} variatIndex={1} cardTitle=" &nbsp;" />
                            </Col>
                            <Col span={12} >
                                <StatisticsCard  chartData={" "} variatIndex={2} cardTitle=" &nbsp;" />
                            </Col>
                        </Row>
                        <Row key={2} className={`pt-2 pr-4`} gutter={16} style={{marginBottom:10}}>
                            <Col span={12} >
                                <StatisticsCard  chartData={" "} variatIndex={1} cardTitle=" &nbsp;" />
                            </Col>
                            <Col span={12} >
                                <StatisticsCard  chartData={" "} variatIndex={2} cardTitle=" &nbsp;" />
                            </Col>
                        </Row>
                      </Col>
                  </Row>
              </div>
          </div>
          {
            subDomain &&
              <div className={`ns-rounded-lg mt-6 flex w-full ns-shadow-xl`}>
                <div className="px-4 py-2 w-3/4 text-bold  text-left pl-5 text-2xl">
                  {skeletonBtn}
                </div>
              </div>
          }
      </div>
    )
}

export default ChartLoadingScreen
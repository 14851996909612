// Wrapper call like await demoDataWrap(() => yourFnName(x,y,z));
async function demoDataWrapper (func) {
  const demoData = localStorage.getItem("demoData")
  if(demoData) return;
  await func()
}

function loadingWrapper(func,setLoader) {
  let setLoaderNeeded = setLoader !== undefined;
  let funcCallback = async () => {
    const result = await func()
    setLoaderNeeded && setLoader(false);
    return result;
  }
  setLoaderNeeded && setLoader(true);
  return funcCallback();
}

export { demoDataWrapper, loadingWrapper }
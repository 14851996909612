import AxiosApi from '../../config/axios-api';
import {
  USER_INSIGHTS_REQUEST,USER_INSIGHTS_REQUEST_SUCCESS,USER_INSIGHTS_REQUEST_FAIL,
  MAILBOX_INSIGHTS_REQUEST,MAILBOX_INSIGHTS_REQUEST_SUCCESS,MAILBOX_INSIGHTS_REQUEST_FAIL,
  ARCHIVED_INSIGHTS_REQUEST,ARCHIVED_INSIGHTS_REQUEST_SUCCESS,ARCHIVED_INSIGHTS_REQUEST_FAIL,
  DOMAIN_INSIGHTS_COUNT_REQUEST,DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS,DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL
} from "../Constants/insightsConstants";

const userInsightsAction = (userId,domainName) => async (dispatch) => {
  try {
    let queryParams;
    if(userId) {
      dispatch({ type: USER_INSIGHTS_REQUEST, payload: userId });
      queryParams = 'userId='+userId;
    } else {
      dispatch({ type: USER_INSIGHTS_REQUEST, payload: domainName });
      queryParams = 'domainName='+domainName;
    }
    const { data } = await AxiosApi.get('insights/user-insights?'+queryParams);
    dispatch({ type: USER_INSIGHTS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: USER_INSIGHTS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxInsightsAction = (userMailboxId) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_INSIGHTS_REQUEST, payload: userMailboxId });
    const { data } = await AxiosApi.get('insights/mailbox-insights?userMailboxId='+userMailboxId);
    dispatch({ type: MAILBOX_INSIGHTS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_INSIGHTS_REQUEST_FAIL, payload: error.message });
  }
};

const archivedInsightsAction = (insightId,mailboxId) => async (dispatch) => {
  try {
    dispatch({ type: ARCHIVED_INSIGHTS_REQUEST, payload: insightId });
    AxiosApi.defaults.headers.put['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.put('insights/mark-archived',
      mailboxId ? {insightId,mailboxId,value:true} : {insightId,value:true}
    );
    dispatch({ type: ARCHIVED_INSIGHTS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: ARCHIVED_INSIGHTS_REQUEST_FAIL, payload: error.message });
  }
};

const domainInsightsCountAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: DOMAIN_INSIGHTS_COUNT_REQUEST, payload: userId });
    const { data } = await AxiosApi.get('insights/domain-insights-count?userId='+userId);
    dispatch({ type: DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL, payload: error.message });
  }
};

export { userInsightsAction,mailboxInsightsAction,archivedInsightsAction,domainInsightsCountAction };

import React, { useRef, useEffect, useState } from 'react'
import { Row, Col, Tag, Typography } from 'antd';
import { Textfit } from 'react-textfit';

import ChartJs from 'chart.js';
import customTootip from '../Shared/ChartCustomTootip'
import StatisticsCard from './Cards/StatisticsCard';
import StatisticsJoinedCard from './Cards/StatisticsJoinedCard';
import { getlocalStorageValue } from '../../Helpers/globalValues'
import moment from 'moment';
import { startCase } from 'lodash';

const ContentChartScreen = ({ domainName, contentTest }) => {
    const { Title  } = Typography;
    const {variations} = contentTest;
    const  pendingStaus = ['draft','waiting_to_start'];
    const [width, setWidth] = useState(window.innerWidth*0.5);
    const [height, setHeight] = useState(window.innerHeight);
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const collectingMessage ='Collecting data, chart available after 3 business days.';
    const draftMessage ='Start Test to get chart available';
    const timeFrom = (X) => {
        const dates = [];
        for (let I = 0; I < Math.abs(X); I++) {
            dates.unshift(moment(new Date(new Date().getTime() - ((X >= 0 ? I : (I - I - I)) * 24 * 60 * 60 * 1000))).format('YYYY-MM-DD'));
        }
        return dates;
    }
    const arrEmailAccounts = JSON.parse(localStorage.getItem('arrEmailAccounts'));
    let selectedMailbox = userSearch(contentTest?.mailbox_id);
    function userSearch(mailboxId) {
        return arrEmailAccounts.find(m => m.id === mailboxId);
    }
    console.log('timeFrom', timeFrom(-12))
    const chartData = variations
    console.log('chartDataArr',chartData);
    const [activeChartLayout, setActiveChartLayout] = useState('reputation');
    const emptyStateChart = (!variations[0]?.reputation?.length)?true:false
    const commonProperties = {
        label: true,
        fill: true,
        pointBackgroundColor: 'white',
        pointHoverBackgroundColor: 'white',
        pointBorderWidth:2.5,
        pointHoverBorderWidth:2.5,
        pointHoverRadius: 6,
        pointStyle: 'circle',
        pointRadius: function(context) {      
            return (context.dataIndex === context.dataset.data.length-1) ? 6 : 0; 
        },
        zIndex:9999
    }

    const chartTypeReputation = [
        {
            borderColor: '#218000',
            lightColor: '#52C234',
            titleColor: '#52C234',
            labelName: 'Reputaion ('+variations[0].variation_name+')',
            data: variations[0].reputation,
            ...commonProperties
        },
        {
            borderColor: '#F8452F',
            lightColor: '#FF6A00',
            titleColor: '#FF6A00',
            labelName: 'Reputaion ('+variations[1].variation_name+')',
            data: variations[1].reputation,
            ...commonProperties
        }
    ];

    const chartTypeSpamRate = [
        {
            ...chartTypeReputation[0],
            labelName: 'Spam Rate ('+variations[0].variation_name+')',
            data: variations[0].spam_rate,
            ...commonProperties
        },
        {
            ...chartTypeReputation[1],
            labelName: 'Spam Rate ('+variations[1].variation_name+')',
            data: variations[1].spam_rate,
            ...commonProperties
        }
    ];


    const chartContainer = useRef([]);
    const [chartInstance, setChartInstance] = useState([]);
    const chartConfig = {
        type: 'line',
        maintainAspectRatio: true,
        data: {
            labels: demoData?timeFrom(12):contentTest.dates,
            datasets:chartTypeReputation
        },
        options: {
            layout: {
                padding: {
                    top: 7,
                    right: 7,
                }
            },
            
            maintainAspectRatio:false,
            legend: false,
            responsive: true,
            z: 2,
            plugins: [{
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: function(context) {
                            return {
                                pointStyle: 'triangle',
                                rotation: 0
                            };
                        }
                    }
                }
            }],
            tooltips: {
                enabled: false,
                position: 'nearest',
                mode: 'index',
                custom: customTootip,
                intersect: false,
                yPadding: 10,
                xPadding: 17,
                caretSize: 10,
                bodyFontSize: 15,
                borderWidth: 1,
                displayColors: false,
                backgroundColor: '#FFF',
                borderColor: '#dbdbdbc9',
                titleFontSize: 16,
                labelFontColor: '#AC105C',
                titleFontColor: '#262626d9',
                titleFonFamily: 'Nunito',
                bodyFontFamily: 'Nunito',
                bodyFontColor: '#262626d9',
                rtl:false,
                callbacks: {
                    label: function(tooltipItem, data) {
                      
                        let label = data.datasets[tooltipItem.datasetIndex].labelName || '';

                        if (label) {
                            label += `: ${tooltipItem.value}%`;
                        }
                        return label;
                    },
                    labelTextColor: function(tooltipItem, data) {
                        console.log(`data`, data.tooltip._data.datasets[tooltipItem.datasetIndex])
                        return data.tooltip._data.datasets[tooltipItem.datasetIndex].titleColor;
                    }
                }
            },
            elements:{
                point:{
                    pointStyle: 'circle'
                }
            },
            scales: {
                xAxes: [{
                    display: false,
                }],
                yAxes: [{
                    display: false,
                }]
            }
        }
    };

    useEffect(() => {
        if (chartContainer && chartContainer.current && !(chartInstance instanceof ChartJs)) {
            const { gradient_bd_color:bd2, gradient_bg_color:bg2 } = createGradient(width,chartTypeReputation[0].borderColor,chartTypeReputation[0].lightColor);
            chartConfig.data.datasets[0].borderColor = bd2;
            chartConfig.data.datasets[0].backgroundColor = bg2;
            const { gradient_bd_color:bd1, gradient_bg_color:bg1 } = createGradient(width,chartTypeReputation[1].borderColor,chartTypeReputation[1].lightColor);
            chartConfig.data.datasets[1].borderColor = bd1;
            chartConfig.data.datasets[1].backgroundColor = bg1;
            
            const chartInstanceCreate = new ChartJs(chartContainer.current, chartConfig);
            setChartInstance(chartInstanceCreate);
        }
    }, [chartContainer]);
    

    const createGradient = (widthChart, dark = "#9835c3", light = "#ef7ef8") => {
        let gradient_bd_color = chartContainer.current.getContext("2d").createLinearGradient(0, 0, widthChart, 0);
        gradient_bd_color.addColorStop(0, dark);
        gradient_bd_color.addColorStop(1, light);

        let gradient_bg_color = chartContainer.current.getContext("2d").createLinearGradient(0, 0, widthChart, 0);
        gradient_bg_color.addColorStop(0, dark+'15');
        gradient_bg_color.addColorStop(.90, light+'25');
        gradient_bg_color.addColorStop(1, light+'02');

        return { gradient_bd_color, gradient_bg_color };
    }

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth*0.5);
        setHeight(window.innerHeight);
        // chartResize();
        // width = (window.innerWidth) | 0;
        chartLayout();
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    },[width,height]);

    useEffect(() => { chartLayout(); },[activeChartLayout]);

    const fillGradient = (multiType) => {
        console.log(`multiType`, multiType)
        let chartInstanceData = chartInstance?.data?.datasets;
            if(chartInstanceData){
                chartInstance.data.datasets = multiType;
                for (let index = 0; index < multiType.length; index++) {
                    let { gradient_bd_color, gradient_bg_color } = createGradient(width,multiType[index].borderColor,multiType[index].lightColor);
                    chartInstance.data.datasets[index].borderColor = gradient_bd_color;
                    chartInstance.data.datasets[index].backgroundColor = gradient_bg_color;
                    
                }
            }
    }
    
    const DomainTag = ({domainName}) =>{ 
        return (
        <Tag className="ml-4 py-1 px-4 ns-rounded-lg absolute" style={{marginTop:"0px",border:"2px solid"}} color="green">
            <span className="mt-0 capitalize" style={{color:"#51b200",marginTop:"0px",fontSize:"20px"}}>{startCase(domainName)}</span>
        </Tag>
    )}
    
    const chartLayout = () => {
        if(chartInstance instanceof ChartJs) {
            if(activeChartLayout=='reputation')
                fillGradient(chartTypeReputation);
            else
                fillGradient(chartTypeSpamRate);
            chartInstance.update();
            setChartInstance(chartInstance);
        }
    }
    return (
        <div className="w-full">
            <Row className="ns-shadow-xl ns-rounded-lg m-5 personal-statistic" gutter={8}>
                <Col className="text-left ns-p-0" span={16}>
                    <h1 className='Spam-Rate pl-5 pt-5'>{domainName || 'allegrow.co'}<DomainTag domainName={contentTest.test_status} /></h1>
                    {
                        emptyStateChart && contentTest.test_status!=='completed' &&
                        <h1 className={`pl-5 pt-1 Reputation`}>
                            {contentTest.test_status==='draft'?draftMessage:collectingMessage}
                        </h1>
                    }
                    <div className={`line-chart pt-4 ${emptyStateChart && 'hidden'}`} >
                        <div className="chart-container"  style={{height:'292px', width:'54vw'}}>
                            <canvas className="ns-rounded-bl-lg" ref={chartContainer} />
                        </div>
                    </div>
                </Col>
                <Col span={8} className='chartCard chartCardContent'>
                    {
                        domainName!=="Overview" &&
                        <div>
                            {
                                variations.map((contentVariat,variatIndex) => {
                                    return (
                                        <Row key={variatIndex} className={`${!variatIndex && 'pt-5'} pr-4`} gutter={16} style={{marginBottom:10}}>
                                            <Col span={12} onClick={()=>setActiveChartLayout('reputation')}>
                                                <StatisticsCard cursorPointer={true} activeChartLayout={activeChartLayout}  chartData={contentVariat.reputation} variatIndex={variatIndex} />
                                            </Col>
                                            <Col span={12} onClick={()=>setActiveChartLayout('spam_rate')}>
                                                <StatisticsCard cursorPointer={true} activeChartLayout={activeChartLayout} chartData={contentVariat.spam_rate} variatIndex={variatIndex} cardTitle="Spam Rate" />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                            <Row  className={`pr-4`} gutter={16} style={{marginBottom:10}}>
                                <Col span={24}>
                                    <StatisticsJoinedCard 
                                        cardValue={demoData?moment().add(3, 'd').format("MMM	D"):contentTest.test_end} 
                                        cardValue1={pendingStaus.includes(contentTest.test_status) ? "-" : contentTest.remaining_day}  
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                    {
                        domainName==="Overview" &&
                        <div>
                            <Row className={`pt-5 pr-4`} gutter={16} style={{marginBottom:10}}>
                                <Col span={24}>
                                    <StatisticsJoinedCard
                                        cardValue={demoData?moment().format("MMM	D"):contentTest.test_start} 
                                        cardValue1={demoData?moment().add(3, 'd').format("MMM	D"):contentTest.test_end} 
                                        cardTitle="Test Start"
                                        cardTitle1="Test End"
                                    />
                                </Col>
                            </Row>
                            <Row className={`pt-1 pr-4 statistics-joined-card`} gutter={16} style={{marginBottom:10}}>
                                <Col  span={12}>
                                    <StatisticsCard  variatIndex={3} chartData={contentTest.email_sent} cardTitle="Email Sent" />
                                </Col>
                                <Col span={12}>
                                    <StatisticsCard  variatIndex={3}  chartData={pendingStaus.includes(contentTest.test_status) ? "-" : contentTest.remaining_day} cardTitle="Remaining" />
                                </Col>
                            </Row>
                            <Row className={`pb-4 pt-2`} gutter={16}>
                                <Col span={23}>
                                    <div>
                                        <div className={`statistics-joined-card mailbox-shrink ns-bg-light-white ns-rounded-lg px-4 py-2`}  >
                                            <Title className="" style={{color:'rgba(38, 38, 38, 0.85)'}} level={4}>Mailbox</Title>
                                            <Textfit className="pb-2" forceSingleModeWidth={false}  mode="single"><span>{selectedMailbox?.email}</span></Textfit>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </Col>
            </Row>
                        
        </div>
    )
}

export default ContentChartScreen
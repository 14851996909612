import React from 'react'
import { Statistic, Card } from 'antd';

const StatisticsCard = ({activeChartLayout,chartData=[],cardTitle="Reputation",cursorPointer=false,variatIndex}) => {
  const cardKey = cardTitle.toLowerCase().replace(' ','_');
  const typeofChartDataObj = typeof chartData === 'object';
  return (
    <Card className={`ns-bg-light-white ns-rounded-lg  ${cursorPointer && 'cursor-pointer'} ${cardKey} ${activeChartLayout===cardKey && 'activeChartCard'} variat_${variatIndex}`}  >
      <Statistic
        title={cardTitle}
        value={
          typeofChartDataObj
          ? !chartData?.length  ?"-":chartData.slice(-1)+"%"
          : chartData ? chartData : "-"
        }
        precision={typeofChartDataObj?2:0}
        bordered={false}
      />
    </Card>
  )
}

export default StatisticsCard
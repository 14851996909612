import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Spin, PageHeader, Button, Space } from 'antd';
import { getlocalStorageValue } from '../../Helpers/globalValues'
import DragableChart from '../Shared/DragableChart';
import { outgoingMailboxDailyFutureLimitAction } from '../../Store/Actions/outgoingMailboxActions';


const SendingPatternScreen = () => {

    const history = useHistory();
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const { Header, Content } = Layout;
    const dispatch = useDispatch();
    let { mailboxId } = useParams();
    const { dailySettings } = useSelector((state) => state.outgoingMailboxDailyFutureLimit);
    

    const DragableChart1 = {};
    if(dailySettings && dailySettings.result && dailySettings.result.length){
            console.log('dailySettings',dailySettings)
            console.log('email_limit',dailySettings.result.map(settingListing => settingListing.id))
            DragableChart1['email_limit'] = dailySettings.result.map(settingListing => settingListing.email_limit)
            DragableChart1['reply_limit'] = dailySettings.result.map(settingListing => settingListing.reply_limit)
            console.log('DragableChart1',DragableChart1);
    }


    useEffect(() => {
        dispatch(outgoingMailboxDailyFutureLimitAction(mailboxId));
        return () => console.log(`unmount SendingPatternScreen`)
    },[mailboxId])

    
    return (
        <div>
            <Header className="site-layout-sub-header-background p-0 fixed herder-div-width"> 
                <PageHeader 
                    className="site-page-header"  
                    title="Sending Pattern"
                />
            </Header>
            <Content className="mx-6 my-6 text-center space-y-4 ant-layout-content-static pb-16">
            <div>    
                {
                    (DragableChart1 && DragableChart1.email_limit) 
                    ? <DragableChart data={DragableChart1} />
                    : <Spin className="p-56" size="large" tip="Loading Sending Pattern..."  spinning={true}></Spin>
                }
            </div>
            </Content>
        </div>
    )
}


export default SendingPatternScreen
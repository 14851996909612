import React from 'react'

function LabIcon() {
  return (
    <span role="img" aria-label="bulb" className="anticon anticon-bulb ant-menu-item-icon">
      <svg 
          version="1.1" id="Layer_1"  x="0px" y="0px"  className="lab-icon inline" fill="currentColor"   focusable="false"  aria-hidden="true" 
          viewBox="0 0 512 512"  width="1em" height="1em" >
              <path 
                  id="XMLID_5_" d="M478.5,402.2L320.2,138.7V31.7h15.8c8.4,0,15.8-7.4,15.8-15.8S344.4,0,336.1,0H175.9
                  c-8.4,0-15.8,7.4-15.8,15.8s7.4,15.8,15.8,15.8h15.8v107.1L33.5,402.2C-2.8,462.7,25.1,512,95.9,512h320.2
                  C486.9,512,514.8,462.7,478.5,402.2z M120.1,320.2L223.4,148V31.7h64.2v115.4L391,319.3H120.1V320.2z"
              />
      </svg>
    </span>
  )
}

export default LabIcon

import AxiosApi from '../../config/axios-api';
import {
  CONTENT_TESTS_REQUEST, CONTENT_TESTS_SUCCESS, CONTENT_TESTS_FAIL,
  CONTENT_TESTS_CREATE_REQUEST, CONTENT_TESTS_CREATE_SUCCESS, CONTENT_TESTS_CREATE_FAIL,
  CONTENT_TESTS_DRAFT_REQUEST, CONTENT_TESTS_DRAFT_SUCCESS, CONTENT_TESTS_DRAFT_FAIL,
  CONTENT_TESTS_DELETE_REQUEST, CONTENT_TESTS_DELETE_SUCCESS, CONTENT_TESTS_DELETE_FAIL,
  CONTENT_TESTS_FINISH_REQUEST, CONTENT_TESTS_FINISH_SUCCESS, CONTENT_TESTS_FINISH_FAIL,
} from "../Constants/contentTestsConstant";

const contentTestsAction = () => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_TESTS_REQUEST, payload: null });
    const { data } = await AxiosApi.get('analytics/user-content-test-statistics/');
    dispatch({ type: CONTENT_TESTS_SUCCESS, payload: data?.result?.data });
  } catch (error) {
    dispatch({ type: CONTENT_TESTS_FAIL, payload: error.message });
  }
};

const contentTestsCreateUpdateAction = (contentTestData) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_TESTS_CREATE_REQUEST, payload: null });
    const { data } = await AxiosApi.post('outgoing/content-test',contentTestData);
    dispatch({ type: CONTENT_TESTS_CREATE_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONTENT_TESTS_CREATE_FAIL, payload: error });
  }
};

const contentTestsFinishAction = (contentTestData) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_TESTS_FINISH_REQUEST, payload: null });
    const { data } = await AxiosApi.post('outgoing/update-content-test-to-completed',contentTestData);
    dispatch({ type: CONTENT_TESTS_FINISH_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONTENT_TESTS_FINISH_FAIL, payload: error.message });
  }
};

const contentTestsdeleteAction = (contentTestId) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_TESTS_DELETE_REQUEST, payload: null });
    const { data } = await AxiosApi.post('outgoing/delete-content-test/'+contentTestId);
    dispatch({ type: CONTENT_TESTS_DELETE_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: CONTENT_TESTS_DELETE_FAIL, payload: error.message });
  }
};

const contentTestsDraftAction = (draftId) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_TESTS_DRAFT_REQUEST, payload: null });
    const { data } = await AxiosApi.get('outgoing/get-content-test-draft/'+draftId);
    dispatch({ type: CONTENT_TESTS_DRAFT_SUCCESS, payload:  data?.result ?? "" });
  } catch (error) {
    dispatch({ type: CONTENT_TESTS_DRAFT_FAIL, payload: error.message });
  }
};

export { 
  contentTestsAction,
  contentTestsCreateUpdateAction,
  contentTestsDraftAction,
  contentTestsdeleteAction,
  contentTestsFinishAction
};
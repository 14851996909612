import React,{ useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { Layout, Spin, PageHeader, Button, notification, Space } from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import AxiosApi from '../config/axios-api';
import { loadStripe } from '@stripe/stripe-js';

import ChartLoadingScreen from './Shared/ChartLoadingScreen';
import SpamRateOfMailboxScreen from './Shared/SpamRateOfMailboxScreen';
import EmptyMailboxScreen from './EmptyMailboxScreen';

import ConnectMailboxModal from '../Modals/ConnectMailboxModal';
import ConnectMailboxFormModal from '../Modals/ConnectMailboxFormModal';
import CongratulationsModal from '../Modals/CongratulationsModal';
import StripeElementsModal from '../Modals/StripeElementsModal';
import ConfirmUpgradePlanModal from '../Modals/ConfirmUpgradePlanModal';

import { domainInsightsCountAction } from '../Store/Actions/insightsActions';
import { mailboxDomainListsAction, mailboxDomainStatisticsAction } from '../Store/Actions/mailboxActions';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';

import { USER_EDIT_MAILBOX } from '../Routes/Constants/RouteUrls';

const MailboxScreen = () => {
    const demoData = localStorage.getItem("demoData")
    
    const [visibleConfirmCharge, setVisibleConfirmCharge] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleStripeModal, setVisibleStripeModal] = useState(false);
    const [addMailboxloader, setAddMailboxloader] = useState(false);
    const [visibleCongratulations, setVisibleCongratulations] = useState(true);
    const [mailbox, setMailbox] = useState('');
    const [visibleForm, setVisibleForm] = useState(false);
    const [invoiceUpcoming, setInvoiceUpcoming] = useState(null);
    const [stripeConfig, setStripeConfig] = useState({});

    const dispatch = useDispatch();
    const {Header,Content} = Layout;
    const userId = localStorage.getItem("userId");
    const companyId = localStorage.getItem("companyId");
    const perviousPage = localStorage.getItem("perviousPage");
    const urlParams = new URLSearchParams(window.location.search);
    const congratulations = urlParams.get('congratulations');
    const isDelete = urlParams.get('isDelete');
    const arrEmailAccounts = [];
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const domainInsightsCountDetails = useSelector((state) => state.domainInsightsCountDetails);
    const { planData } = useSelector((state) => state.planDetails);
    const { mailboxDomainLists } = useSelector((state) => state.mailboxDomainListsInfo);
    const { mailboxDomainStatistics } = useSelector((state) => state.mailboxDomainStatisticsInfo);

    let currentPlan;
    if (planData?.planData?.records[0]?.plan_type) {
      currentPlan = planData.planData.records[0];
    }
    
    const openNotification = placements => {
        notification[placements.type]({
          message: `${placements.msg}`,
          description: 
            placements.description,
          duration: 15,
          placement:placements.apperence,
        });
      };

    const fetchStatistics = () => {
        try{
            dispatch(mailboxDomainListsAction(userId));
            dispatch(mailboxDomainStatisticsAction(userId));
        } catch (e) {
            console.log('error analytics API:', e);
        }
    }
    

    useEffect(() => {
        if(perviousPage!=="editMailbox" || isDelete){
            dispatch(domainInsightsCountAction(userId))
            fetchStatistics();
        } else {
            localStorage.setItem("perviousPage","Mailbox");
        }
        return () => console.log('Mailbox Unmount component');
    }, []);

    const upgradeSuggestedPlan = async (errorMsg) => {
        // Get Stripe.js instance
        if(demoData) return;
        if(errorMsg && currentPlan.name.includes("Lifetime") && !currentPlan.hasCustomerStripe){
            if(!currentPlan.stripe_price_id){
                openNotification({
                    type: 'error',
                    msg: 'Error',
                    description: errorMsg,
                    apperence: 'topRight',
                });
                return'';
            } else {
                setStripeConfig({priceId:currentPlan.stripe_price_id,planId:currentPlan.id,planPrice:currentPlan.extra_mailbox_price,planName:currentPlan.name});
                setVisibleStripeModal(true);
                return;
            }
        }
        setAddMailboxloader(true);
        const billingData = { userId, companyId, planId:currentPlan.id, upgradeStripePriceId:currentPlan.stripe_price_id, newMailboxPayment:true }
        
        AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
        let invoice = await AxiosApi.post('billing/retrieve-upcoming-invoice',JSON.stringify(billingData));
        console.log('invoice', invoice);
        setAddMailboxloader(false);
        if(invoice?.data?.result){ 
            invoice.data.result.planName = currentPlan.name;
            setInvoiceUpcoming(invoice?.data?.result);
            setVisibleConfirmCharge(true);
        }
    };
    
    const upgradeSuggestedPlanPay = async () => {
        // Get Stripe.js instance
        setVisibleConfirmCharge(false);
        if(demoData) return;
        // setloader(true);
        setAddMailboxloader(true);
        const stripe = await stripePromise;
        const billingData = { userId, companyId, planId:currentPlan.id, upgradeStripePriceId:currentPlan.stripe_price_id, newMailboxPayment:true }
        
        AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
        let payInvoice = await AxiosApi.post('billing/upgrade-customer-plan',JSON.stringify(billingData));
        console.log('pay invoice', payInvoice);
        if( 
            payInvoice?.data?.result?.paymentIntentStatus==="requires_action" &&
            payInvoice?.data?.result?.clientSecret
        ){
          stripe.confirmCardPayment(
            payInvoice.data.result.clientSecret,
            { setup_future_usage: 'off_session' }
          ).then(function(result) {
            if (result.error) {
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
            //   setloader(false);
            setAddMailboxloader(false);
              console.log('error confirm api:', result.error);
              openNotification({
                  type: 'error',
                  msg: 'Error',
                  description: result.error.message,
                  apperence: 'topRight',
              });
            } else {
              // Show a success message to your customer
              //confirmSubscription(subscription.id);
              confirmSubscription(payInvoice);
            }
          }).catch(function(error) { 
                console.log(error);
          });
        }
        else if(payInvoice?.data?.successMessage==='Success'){
          setTimeout(async() => { await successMsg(); 
            setAddMailboxloader(false);
            },5000);
        } else {
        //   setloader(false);
        }
      };

      const confirmSubscription = async (payInvoice) => {
        // setloader(true);
        const confirmSubscription = await AxiosApi.post('billing/upgrade-subscription',JSON.stringify(payInvoice.data.result));
        console.log('confirmSubscription...', confirmSubscription);
        if (confirmSubscription?.data?.successMessage==='Success') {
          successMsg();
        }
        setAddMailboxloader(false);
      }
  
      const successMsg = async () => {
        let userId = localStorage.getItem("userId");
        openNotification({
          type: 'success',
          msg: 'Success',
          description: 'Payment has done successfully, Now you can able to add a new mailbox',
          apperence: 'topRight',
        });
        await dispatch(planDetailsAction(userId,'update'));
      }    

    return (
        <div>
            { invoiceUpcoming && <ConfirmUpgradePlanModal visible={visibleConfirmCharge}
                upgradeSuggestedPlanPay={upgradeSuggestedPlanPay} invoiceUpcoming={invoiceUpcoming} 
                onCancel={() => setVisibleConfirmCharge(false)}  paymentType='purchaseMailbox' />
            }
            { congratulations && currentPlan && currentPlan?.welcome_message && <CongratulationsModal visible={visibleCongratulations} currentPlan={currentPlan}
                onCancel={() => setVisibleCongratulations(false)} />
            }
           <ConnectMailboxModal 
                visible={visible}
                setMailbox={setMailbox}
                setVisibleForm={setVisibleForm}
                setVisible={setVisible}
                onCancel={() => setVisible(false)}
            />     
           <StripeElementsModal 
                visible={visibleStripeModal}
                setVisible={setVisibleStripeModal}
                onCancel={() => setVisibleStripeModal(false)}
                setStripeConfig={setStripeConfig} stripeConfig={stripeConfig} stripePromise={stripePromise}
            />     
           <ConnectMailboxFormModal 
                visible={visibleForm}
                setVisibleForm={setVisibleForm}
                fetchStatistics={fetchStatistics}
                setVisible={setVisible}
                mailbox={mailbox}
                upgradeSuggestedPlan={upgradeSuggestedPlan}
                onCancel={() => setVisibleForm(false)}
            />     
            <Header className="site-layout-sub-header-background p-0 fixed herder-div-width"> 
                <PageHeader 
                    className="site-page-header"  
                    extra={[<Spin key={1} spinning={addMailboxloader}><button key={1} className="btn text-bold focus:outline-none ns-bg-green  py-2 px-4 rounded-lg text-white" 
                    onClick={() =>  setVisible(true) }>New Mailbox</button></Spin>]}
                    title="Email Accounts"
                />
            </Header>
            <Content className="mx-6 my-6 text-center space-y-4 ant-layout-content-static pb-16">
                {
                    mailboxDomainLists 
                    ?
                    mailboxDomainLists.length 
                        ?   (mailboxDomainLists.map((account,MailIndex) => {
                                return (
                                        <div key={MailIndex}>
                                            <div  className="site-layout-background pt-2 ns-rounded-lg">
                                                <div className="site-statistic-demo-card">
                                                    {
                                                        mailboxDomainStatistics
                                                        ? <SpamRateOfMailboxScreen chartDataStat={mailboxDomainStatistics} dailyExpectedEmails={null}  className="flex" domainName={account.domain_name} domainInsightsCountDetails={domainInsightsCountDetails}/>
                                                        : <ChartLoadingScreen className="flex"  domainName={account.domain_name}/>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                account.sub_accounts.map((sub_accounts,account_index) => {
                                                    arrEmailAccounts.push(
                                                        {
                                                            id:sub_accounts.id,email:sub_accounts.email,domain_name:account.domain_name,
                                                            sub_accounts
                                                        
                                                    });
                                                    localStorage.setItem('arrEmailAccounts', JSON.stringify(arrEmailAccounts))
                                                    return (
                                                        <div key={account_index} className={`${(account_index > -1) && 'ns-shadow-xl' } ns-rounded-lg mt-6 flex w-full`}>
                                                            <p className="px-4 py-2 w-3/4 text-bold  text-left pl-5 text-2xl">{sub_accounts.email} { sub_accounts.connection_error && <ExclamationCircleTwoTone twoToneColor="#ff4d4f" className="px-2 align-middle " />}</p>
                                                            <Button className="text-right w-1/4 align-middle mt-3 mr-2 text-gray-500 hover:text-gray-500 active:text-gray-500" type="link" block><Link to={USER_EDIT_MAILBOX+sub_accounts.id}>Edit</Link></Button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                )
                                              
                            }))
                        : <EmptyMailboxScreen />
                    : <Space className="w-full" direction="vertical"><ChartLoadingScreen subDomain={true} /><ChartLoadingScreen subDomain={true} /><ChartLoadingScreen subDomain={true} /></Space>
                    
                }
            </Content>
        </div>
    )
}


export default MailboxScreen
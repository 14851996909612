import React from 'react'
import { Input,  Row, Col, Tag } from 'antd';
import StatisticsCard from './Cards/StatisticsCard';

const ContentVariatScreen = ({ domainName, contentTest }) => {
    const { variations } = contentTest;
    const { TextArea } = Input;

    const DomainTag = ({domainName}) =>{ 
      return (
        <Tag className="ml-4 py-1 px-4 ns-rounded-lg absolute" style={{marginTop:"0px",border:"2px solid"}} color="green">
          <span className="mt-0" style={{color:"#51b200",marginTop:"0px",fontSize:"20px"}}>{domainName}</span>
        </Tag>
    )}
    return (
    <div>
       {
          variations?.map((contentVariat,variatIndex) => {
            return (
                    <div key={variatIndex}  className="site-layout-background pt-2 ns-rounded-lg">
                      <div className="site-statistic-demo-card">    
                        <div className="w-full">
                          <Row className="ns-shadow-xl ns-rounded-lg m-5 personal-statistic" gutter={8}>
                            <Col className="text-left ns-p-0" span={16}>
                              <h1 className='Spam-Rate pl-5 pt-5'>{contentVariat.variation_name} { (!variatIndex && contentTest?.test_status==='completed') && <DomainTag domainName="Winner" />}</h1>
                            </Col>
                            <Col className="text-left m-4" span={15}>
                              <div style={{ margin: '24px 0' }} />
                              <TextArea className="px-4 py-2 rounded-md ns-cursor-disabled" disabled value={contentVariat.variation_mail_subject} autoSize />
                              <div style={{ margin: '24px 0' }} />
                              <TextArea
                                className="px-4 py-2 rounded-md ns-cursor-disabled scrolling-auto"
                                disabled
                                value={contentVariat.variation_mail_body}
                                autoSize={{ minRows: 4.7, maxRows: 8 }}
                              />
                            </Col>
                            <Col span={8} className='chartCard chartCardContent'>
                              <Row key={variatIndex} className={`pt-8 pl-8`} gutter={20} style={{marginBottom:16}}>
                                <Col span={12}>
                                  <StatisticsCard chartData={contentVariat.reputation} variatIndex={variatIndex} />
                                </Col>
                                <Col span={12}>
                                  <StatisticsCard chartData={contentVariat.spam_rate} variatIndex={variatIndex} cardTitle="Spam Rate" />
                                </Col>
                                <Col span={12} className={`pt-3`}>
                                  <StatisticsCard chartData={contentVariat.email_sent} variatIndex={variatIndex} cardTitle="Emails Sent" />
                                </Col>
                                <Col span={12} className={`pt-3`}>
                                  <StatisticsCard chartData={!contentVariat.difference?contentVariat.difference:[contentVariat.difference]} variatIndex={variatIndex} cardTitle="Difference" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )
              })
        }
      </div>
    )
}

export default ContentVariatScreen
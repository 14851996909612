import { notification } from 'antd';

const openNotification = placements => {
  notification[placements.type]
  (
    {
      message: `${placements.msg}`,
      description: placements.description,
      duration: placements?.duration || 0,
      placement:placements.apperence,
    }
  );
};

const openNotificationSuccess = placements => {
  openNotification({
    type: 'success',
    msg: 'Success',
    apperence: 'topRight',
    ...placements
  });
};

const openNotificationError = placements => {
  openNotification({
    type: 'error',
    msg: 'Error',
    apperence: 'topRight',
    ...placements
  });
};

export { openNotification, openNotificationSuccess, openNotificationError }
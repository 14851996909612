import React, { useEffect, useState } from 'react';
import { Menu, Image, Layout, Typography, Tooltip } from 'antd';
import { SettingOutlined, MailOutlined, BulbOutlined, LogoutOutlined, BarChartOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from "aws-amplify";
import { USER_MAILBOXES, USER_INSIGHTS,USER_SETTINGS, USER_CONTENT_TESTS, USER_EDIT_CONTENT_TESTS, USER_CREATE_CONTENT_TESTS, USER_SENDING_PATTERN  } from '../Routes/Constants/RouteUrls';
import LabIcon from '../assets/images/sidebar/LabIcon';
import { getlocalStorageValue } from '../Helpers/globalValues'
import { groupBy } from 'lodash';
import { mailboxDomainListsAction } from '../Store/Actions/mailboxActions';

const SiderLayout = () => {
    const [openKeys, setOpenKeys] = useState([]);
    const { Text } = Typography;
    const [showSendingPattern, setShowSendingPattern] = useState(false);
    const { mailboxDomainLists } = useSelector((state) => state.mailboxDomainListsInfo);
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const arrEmailAccounts = groupBy(JSON.parse(localStorage.getItem('arrEmailAccounts')),'domain_name');
    const history = useHistory();
    const dispatch = useDispatch();
    const { SubMenu } = Menu;
    const sendingPattern = (type) => {
        // document.querySelector('.ant-menu-submenu-arrow').click()
        //document.querySelector('.ant-menu-submenu-arrow')?.click()
        if(type==='open'){
            setShowSendingPattern(true);
            setOpenKeys(['sub1'])
            setloader(locationKey)
            // document.querySelector('.ant-menu-submenu-arrow').attr
        }else {
            setShowSendingPattern(false);
        }
    }
        
    const handleLogout = async () => {
        window.heap.resetIdentity(); 
        localStorage.clear();
        await Auth.signOut({ global: true });
        history.push("/");
    }
    const [loader, setloader] = useState('1');
    const location = useLocation();
    let locationKey='1';
    if(location){
        switch (location.pathname){
            case USER_MAILBOXES:
                    locationKey ='1';
                    break;
            case USER_INSIGHTS:
                    locationKey ='2';
                    break;
            case USER_CONTENT_TESTS:
            case USER_EDIT_CONTENT_TESTS:
            case USER_CREATE_CONTENT_TESTS:
                    locationKey ='5';
                    break;
            case USER_SETTINGS:
                    locationKey ='3';
                    break;
            default:
                if(
                    location.pathname.includes(USER_EDIT_CONTENT_TESTS) ||
                    location.pathname.includes(USER_CREATE_CONTENT_TESTS)
                )
                    locationKey ='5';
                location?.pathname?.includes(USER_SENDING_PATTERN) && (locationKey = location?.pathname?.split('/')[3]);
                // do nothing
        }
        if(loader!==locationKey)
            setloader(locationKey)
    }

   console.log(`location...`, location)
    useEffect(() => {
        let host = window.location.host;
        if(host==='app.neverspam.io'){
            const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
            let intercomBootObj = {
                app_id: "c5w3j811",
                user_id: localStorage.getItem("userId"), // User id
                name: authResult.attributes['custom:first_name'] + " " + authResult.attributes['custom:last_name'], // Full name
                email: authResult.attributes['email'], // Email address
                // created_at: "1312182000" // Signup date as a Unix timestamp
            };
            window.Intercom("boot", intercomBootObj);
        }
        
        (!mailboxDomainLists && !mailboxDomainLists?.length)
            && dispatch(mailboxDomainListsAction(userId));

        location?.pathname?.includes(USER_SENDING_PATTERN) && sendingPattern('open')
    }, [])

   
    const planDetails = useSelector((state) => state.planDetails);
    const { planData, 
        /* loading, error */
    } = planDetails;
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    if(!authResult.attributes['nickname'] && (!planData || !planData.planData)){
        localStorage.setItem("signupUserData",JSON.stringify({userId: localStorage.getItem("userId"),companyId:localStorage.getItem("companyId")}));
        localStorage.setItem("signupUserEmail",authResult.attributes['email']);
        history.push("/pricing");
    }
    const planPermission = JSON.parse(planData?.planData?.records[0]?.plan_policy ?? '{}');
    const contentTestsFlag = planPermission?.contentTests

    if(planData?.planData?.records[0]?.subscription_status==='cancelled'){
        history.push("/pricing");
    }

    useEffect(() => {
        if(loader===5) {
            (!demoData || !contentTestsFlag) && history.push(USER_MAILBOXES);
        }
    }, [loader])

    const {Sider} = Layout;
    return (
        <Sider theme="white" className="h-screen fixed">
            <div className="logo" style={{margin:"20px 25px 11px 14px"}}>
                <Image width={135} height={40}  preview={false} src="/images/logo_allegrow.svg"></Image>
            </div>
            <div className="static" >
                    {
                        !showSendingPattern
                        ?
                               <Menu  subMenuOpenDelay={2} subMenuCloseDelay={1} theme="white"  className="v-align-3 ns-menu" mode={`${'vertical'}`} selectedKeys={loader}> 
                                    <Menu.Item key="1"  icon={<MailOutlined />}>
                                        <Link to={USER_MAILBOXES} >Performance</Link>
                                    </Menu.Item>
                                    <Menu.Item key="7"  icon={<BarChartOutlined />} >
                                        <Link to="#" onClick={()=>sendingPattern('open')} >Sending Pattern</Link>
                                    </Menu.Item>
                                    
                                    { (demoData || contentTestsFlag) &&
                                        <Menu.Item key="5"  icon={<LabIcon/>}>
                                            <Link to={USER_CONTENT_TESTS} >Content Tests</Link>
                                        </Menu.Item>
                                    }
                                    <Menu.Item key="2"  icon={<BulbOutlined />}>
                                        <Link to={USER_INSIGHTS} >Insights</Link>
                                    </Menu.Item>
                                    <Menu.Item key="3" className="ns-absolute bottom-0 w-full" style={{marginBottom:'45px'}} icon={<SettingOutlined />}>
                                        <Link to={USER_SETTINGS}>Settings</Link>
                                    </Menu.Item>
                                    <Menu.Item key="4" className="ns-absolute bottom-0 w-full"   icon={<LogoutOutlined />}>
                                        <Link to="#" onClick={handleLogout}>Logout</Link>
                                    </Menu.Item>
                               </Menu>
                        :
                            <Menu className="v-align-2 ns-menu" mode={`${'inline'}`} selectedKeys={loader} expandIcon={'  '}  subMenuOpenDelay={20} subMenuCloseDelay={10} openKeys={openKeys} theme="white">
                                <SubMenu className="ns-menu" onTitleClick={()=>sendingPattern('close')} icon={<LeftOutlined />} title="Sending Pattern"  key="sub1">
                                        {   
                                            !!(mailboxDomainLists && mailboxDomainLists?.length)
                                            ?
                                                mailboxDomainLists?.map((domain) => {
                                                    return (
                                                        <Menu.ItemGroup key={domain.domain_name} title={<Tooltip mouseEnterDelay={1}  placement="right" title={domain.domain_name}><Text className="text-gray-500 w-full"  ellipsis={true}>{domain.domain_name}</Text></Tooltip>}>
                                                            {domain.sub_accounts.map((emailAccount,emailIndex) =>  <Menu.Item className="domain-email-ellipsis" key={emailAccount.id}><Tooltip mouseEnterDelay={1} className=""  placement="left" title={emailAccount.email}><Link className="" to={USER_SENDING_PATTERN+emailAccount.id}><Text className="w-full text-gray-500"  ellipsis={true}><span className="text-black">{emailAccount.email.split('@')[0]}</span>@{emailAccount.email.split('@')[1]}</Text></Link></Tooltip></Menu.Item>)}
                                                        </Menu.ItemGroup>
                                                    )
                                                })
                                            : <Menu.ItemGroup key={444} title={'No connections are available'}></Menu.ItemGroup>
                                        }
                                </SubMenu>
                            </Menu>
                    }          
                
            </div>
      </Sider>
    )
}

export default SiderLayout;
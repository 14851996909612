import React, { useEffect, useState } from 'react'
import {Layout, Alert, Button, Spin, notification} from 'antd';
import { useHistory } from "react-router-dom";
import { CheckCircleTwoTone } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { loadStripe } from '@stripe/stripe-js';
import AxiosApi from '../config/axios-api';
import { USER_SETTINGS } from '../Routes/Constants/RouteUrls';
import StripeSplitElementsScreen from './StripeSplitElementsScreen';
import ConfirmUpgradePlanModal from '../Modals/ConfirmUpgradePlanModal';


const SubscriptionScreen = () => {
    const {Content} = Layout;
    const history = useHistory();
    const dispatch = useDispatch();
    const demoData = localStorage.getItem("demoData")
    const [loader, setloader] = useState(false);
    const planDetails = useSelector((state) => state.planDetails);
    const { planData, /* loading, error */ } = planDetails;
    const userId = localStorage.getItem("userId")
    const companyId = localStorage.getItem("companyId")
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const urlParams = new URLSearchParams(window.location.search);
    const tabNumber = urlParams.get('tab') || 1;
    const returnUrl = `${window.location.origin+USER_SETTINGS}?tab=4`;
    const sessionId = urlParams.get('session_id');
    const redirect = urlParams.get('redirect');
    const [stripeConfig, setStripeConfig] = useState({});
    const [visible, setVisible] = useState(false);
    const [planType, setPlanType] = useState(null);
    const [invoiceUpcoming, setInvoiceUpcoming] = useState(null);

    const openNotification = placements => {
      notification[placements.type]({
        message: `${placements.msg}`,
        description: 
          placements.description,
        placement:placements.apperence,
      });
    };
    // const rowSelection = {}
    // const columns = [
    //     {
    //       title: 'Month',
    //       dataIndex: 'month',
    //       key: 'month',
    //       sorter: true,
    //       render: text => <a href="/">{text}</a>,
    //     },
    //     {
    //       title: '',
    //       key: 'action',
    //       width:20,
    //       render: (text, record) => (
    //         <Button type="link">Download</Button>
    //       ),
    //     },
    //   ];
      
    //   const data = [
    //     {
    //       key: '51',
    //       month: 'July 2020'
    //     },
    //     {
    //       key: '52',
    //       month: 'June 2020'
    //     },
    //     {
    //       key: '53',
    //       month: 'May 2020'
    //     },
    //   ];
    console.log('planData',planData)  

    const upgradeAnyPlan = async () => {
      // Get Stripe.js instance
      if(demoData) return;
      setloader(true);
      const billingData = { userId, companyId,  returnUrl }
      
      AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
      let session = await AxiosApi.post('billing/create-customer-portal-session',JSON.stringify({billingData}))
      console.log('session', session)
      if(session.data.result.session.url) window.location.href = session.data.result.session.url;
      // When the customer clicks on the button, redirect them to Checkout.
      setloader(false);
      
    };

    const upgradeSuggestedPlan = async (planType) => {
      // Get Stripe.js instance
      setPlanType(planType);
      if(demoData) return;
       
      if(
          ( 
            currentPlan.plan_type === 'lifetime_neverspam' ||
            currentPlan.plan_type === 'lifetime_appsumo' || currentPlan.plan_type === 'lifetime_appsumo_premium' ||
            currentPlan.plan_type === 'lifetime_dealify_starter' || currentPlan.plan_type === 'lifetime_dealify_premium'
          ) && !currentPlan.hasCustomerStripe
        ) 
         return subscribePlan(currentPlan.upgradeStripePriceId, currentPlan.upgrade_plan_id, currentPlan.upgradePrice, currentPlan.upgradeName)

      setloader(true);
      const billingData = { userId, companyId }
      if(planType==='upgrade'){
        billingData.planId=currentPlan.upgrade_plan_id;  
        billingData.upgradeStripePriceId=currentPlan.upgradeStripePriceId;
      } else {
        billingData.planId=currentPlan.id;  
        billingData.upgradeStripePriceId=currentPlan.stripe_price_id;
      }
      
      AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
      let invoice = await AxiosApi.post('billing/retrieve-upcoming-invoice',JSON.stringify(billingData));
      console.log('invoice', invoice);
      setloader(false);

      if(invoice?.data?.result){ 
        invoice.data.result.planName = currentPlan.upgradeName;
        setInvoiceUpcoming(invoice?.data?.result);
        setVisible(true);
      }
      
    };

    const confirmSubscription = async (payInvoice) => {
      setloader(true);
      const confirmSubscription = await AxiosApi.post('billing/upgrade-subscription',JSON.stringify(payInvoice.data.result));
      console.log('confirmSubscription...', confirmSubscription);
      if (confirmSubscription?.data?.successMessage==='Success') {
        successMsg();
      }
      setloader(false);
    }

    const upgradeSuggestedPlanPay = async () => {
      // Get Stripe.js instance
      setVisible(false);
      if(demoData) return;
      setloader(true);
      const stripe = await stripePromise;
      const billingData = { userId, companyId }
      if(planType==='upgrade'){
        billingData.planId=currentPlan.upgrade_plan_id;  
        billingData.upgradeStripePriceId=currentPlan.upgradeStripePriceId;
      } else {
        billingData.planId=currentPlan.id;  
        billingData.upgradeStripePriceId=currentPlan.stripe_price_id;
      }
      
      AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
      let payInvoice = await AxiosApi.post('billing/upgrade-customer-plan',JSON.stringify(billingData));
      console.log('pay invoice', payInvoice);
      if( 
        payInvoice?.data?.result?.paymentIntentStatus==="requires_action" &&
        payInvoice?.data?.result?.clientSecret
      ){
        stripe.confirmCardPayment(
          payInvoice.data.result.clientSecret,
          { setup_future_usage: 'off_session' }
        ).then(function(result) {
          if (result.error) {
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setloader(false);
            console.log('error confirm api:', result.error);
            openNotification({
                type: 'error',
                msg: 'Error',
                description: result.error.message,
                apperence: 'topRight',
            });
          } else {
            // Show a success message to your customer
            //confirmSubscription(subscription.id);
            confirmSubscription(payInvoice);
          }
        }).catch(function(error) { 
              console.log(error);
        });
      }
      else if(payInvoice?.data?.successMessage==='Success'){
        setTimeout(async() => { await successMsg(); setloader(false); },5000);
      } else {
        setloader(false);
      }

      // if(invoice?.data?.result){ 
      //   invoice.data.result.planName = currentPlan.upgradeName;
      //   setInvoiceUpcoming(invoice?.data?.result);
      //   setVisible(true);
      // }
      
    };

    const successMsg = async () => {
      let userId = localStorage.getItem("userId");
      openNotification({
        type: 'success',
        msg: 'Success',
        description: 'Payment has done successfully',
        apperence: 'topRight',
      });
      await dispatch(planDetailsAction(userId,'update'));
    }

    const upgradePlanAction = async () => {
      if (currentPlan.plan_type === 'lifetime_neverspam') return;
      (
        (currentPlan.plan_type === 'lifetime_appsumo' || currentPlan.plan_type === 'lifetime_appsumo_premium' ||
        currentPlan.plan_type === 'lifetime_dealify_starter' || currentPlan.plan_type === 'lifetime_dealify_premium')
        && !currentPlan.hasCustomerStripe
      ) 
        ? subscribePlan(currentPlan.upgradeStripePriceId, currentPlan.upgrade_plan_id, currentPlan.upgradePrice, currentPlan.upgradeName) 
        : upgradeAnyPlan();
      //subscribePlan(currentPlan.upgradeStripePriceId, currentPlan.upgrade_plan_id);
    }

    const subscribePlan = async (priceId,planId,planPrice,planName) => {
      setStripeConfig({priceId,planId,planPrice,planName});
      return;
    }

    let currentPlan;
    if(planData?.planData?.records[0]?.plan_type){
      currentPlan = planData.planData.records[0];
    }

    const appsumoUserAssignedPlan = async (sessionId,redirect) => {
        setloader(true);
        try{
            AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
            //let session = await AxiosApi.post('billing/retrive-checkout-session',JSON.stringify({sessionId}))
            await dispatch(planDetailsAction(userId),'update');
        } catch (e) {
            console.log(e.message);
        }
        setloader(false);
        
    }

    useEffect(() => {
      if(sessionId) { 
        appsumoUserAssignedPlan(sessionId,redirect);
        return;
      }
      
      if(tabNumber==='4'){
        let fetchPlan = async() => {
            setloader(true);
            await dispatch(planDetailsAction(userId,'update'));
            setloader(false);
        }
        fetchPlan();
      }

    }, [])
  let  paymentErrorMsg =  `Your current plan renewal has failed. 
                            To resume using Allegrow, please`;
  let  paymentError= (<div>
                        <span className="text-base">{paymentErrorMsg}</span>
                        <a className="pl-2 text-red-700" onClick={() =>  upgradeSuggestedPlan('renewal')}  href="#/">Retry Card</a>&nbsp;&nbsp;or
                        <a className="pl-2 text-red-700" onClick={() =>  upgradePlanAction()}  href="#/">Update Card Details</a>
                      </div>);
   
    return (
        <div>
          <Content className="mx-4 mt-6 mb-0">
          {invoiceUpcoming && <ConfirmUpgradePlanModal visible={visible} upgradeSuggestedPlanPay={upgradeSuggestedPlanPay} invoiceUpcoming={invoiceUpcoming} onCancel={() => setVisible(false)} />}
            {       
              currentPlan
                ?  (
                    <Spin tip="Loading Subscription Plan..." spinning={loader}>
                      <div className="site-layout-background">
                        <section
                          className="flex flex-col flex-start lg:flex-row items-center w-full lg:px-0 py-0"
                        >
                          <article
                            className="bg-white mr-10 h-500 ns-shadow-xl border w-1/4 lg:w-custom mb-10 lg:px-4 px-6 py-3 text-center text-primary-dark ns-rounded-lg"
                            style={{ width: '27%' }}
                          >
                            <h5 className="font-bold text-2xl pt-3 pb-4">{currentPlan.name || ''}</h5>
                            {
                              (currentPlan?.subscription_status==='active' || !currentPlan?.subscription_status)
                              ?   (
                                  <div>
                                    <h2 className="text-base text-primary-normal font-bold pb-12 pt-16">
                                      Your current plan<br/>
                                      {!Boolean(currentPlan.upgradeName) && <Button onClick={() => history.push("/pricing")} type="link" className="my-3" key="1">Upgrade to new plans</Button>}
                                    </h2>
                                  </div>
                                  )
                              : currentPlan?.subscription_status==='renewal_failed' && <Alert
                                  className="text-left ns-rounded-lg my-4"
                                  banner
                                  message={paymentError}
                                  type="error"
                                />
                            }
                            {/* <h2 className="pb-4 py-10 flex justify-center font-bold border-gray-300">
                              <span className="text-base mt-8 mr-1">$</span><span className="text-5xl">{currentPlan.price}</span><span className="text-base mt-8 ml-1 capitalize">/{currentPlan.plan_type==='lifetime_neverspam'?'lifetime':currentPlan.plan_type}</span>
                            </h2> */}
                            <ul className="text-md mb-4 text-left pl-1 font-normal ns-gray v-align-2">
                            {
                                JSON.parse(currentPlan.benefits_description).map((benefitsDescription, benefits_description_index) => {
                                return (
                                  <li className="pt-3 flex"  key={benefits_description_index} ><CheckCircleTwoTone twoToneColor="#52c41a"  className="pl-3 pt-1 pr-3" />{benefitsDescription}</li>
                                  )
                                })
                            }  
                            </ul>
                            { !(
                                (
                                  currentPlan.plan_type === 'lifetime_neverspam' ||
                                  currentPlan.plan_type === 'lifetime_appsumo' || currentPlan.plan_type === 'lifetime_appsumo_premium' ||
                                  currentPlan.plan_type === 'lifetime_dealify_starter' || currentPlan.plan_type === 'lifetime_dealify_premium'
                                ) && !currentPlan.hasCustomerStripe
                              ) && <Button  type="link" onClick={() => upgradePlanAction()} className="my-5 mt-12 ns-left-5 ns-bottom" 
                                            key="1">Invoices & Payment Methods</Button>
                            }
                          </article>
                          { Boolean(currentPlan.upgradeName)
                            && <article
                                className="flex h-500 bg-white ns-shadow-xl border w-3/4 lg:w-custom mb-10 lg:px-6 px-6 py-5 text-center text-primary-dark ns-rounded-lg"
                              >
                                <div className="w-3/4">
                                    <h2 className="pb-12 pt-1 text-2xl text-primary-normal text-left font-bold">Upgrade today and get</h2>
                                    <ul className="text-lg ns-gray pt-6 text-left font-normal v-align-2">
                                      {
                                        JSON.parse(currentPlan.upgradeBenefitsDescription).map((benefitsDescription, benefits_description_index) => {
                                          return (
                                            <li className="pt-3"  key={benefits_description_index}><CheckCircleTwoTone twoToneColor="#52c41a" className="pl-1 pr-3" />{benefitsDescription}</li>
                                          )
                                        })
                                      }
                                    </ul>
                                    <h5 className="text-sm text-left ns-gray text-primary-normal pt-12 w-3/4">{currentPlan.upgradeDescription}</h5>
                                  </div>
                                  <div className="pr-2 pt-2" style={{ width: '50%' }}>
                                      <article
                                      className="bg-white border ns-shadow-xl w-full lg:w-custom lg:px-4 px-6 py-2 text-center text-primary-dark ns-rounded-lg"
                                    >
                                      <button key={123} className="btn my-5 mt-2 focus:outline-none bg-yellow-500 py-1 px-4 ns-rounded-lg text-white">RECOMMENDED</button>
                                      <h2 className="text-2xl text-primary-normal font-bold pb-2 pt-1">{currentPlan.upgradeName}</h2>
                                      <h2 className="flex justify-center font-bold border-gray-300">
                                        <span className="text-base mt-8 mr-1">$</span><span className="text-4xl pt-3">{currentPlan.upgradePlanType==='annual'?currentPlan.upgradePrice/12:currentPlan.upgradePrice}</span><span className="text-base mt-2 ml-1">Per Mailbox<br/>/ <span className="capitalize">{currentPlan.upgradePlanType==='monthly'?currentPlan.upgradePlanType:'Month, Billed Annually'}</span></span>
                                      </h2>
                                      <ul className="text-md text-left font-normal pl-5 ns-gray v-align-2">
                                        <li className="pt-3"><CheckCircleTwoTone twoToneColor="#52c41a" className="pl-3 pr-1" />14-day money-back guarantee</li>
                                        <li className="pt-3"><CheckCircleTwoTone twoToneColor="#52c41a" className="pl-3 pr-1" />Cancel anytime</li>
                                        <li className="pt-3"><CheckCircleTwoTone twoToneColor="#52c41a" className="pl-3 pr-1" />Works with all email providers</li>
                                      </ul>
                                      <button key={1} onClick={() => upgradeSuggestedPlan('upgrade')} 
                                        className="btn my-5 mt-8 focus:outline-none ns-bg-green py-1 px-4 ns-rounded-lg text-white">Upgrade Today</button>
                                      <br/>
                                    </article>
                                    <Button onClick={() => history.push("/pricing")} type="link" className="my-5 ns-right-12 ns-bottom" key="1">See other plans</Button>
                                  </div> 
                              </article>
                          }
                        </section>
                        {/* <div className="ns-shadow-xl px-5  ns-rounded-lg">
                          <PageHeader className="text-lg pb-5 "  title="Invoices"/>
                          <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
                        </div> */}
                       
                      </div>
                     </Spin>
                    )
                  : <Spin spinning={true}  tip="Loading Subscription..."></Spin>
              }
            </Content>
            { stripePromise && stripeConfig.planId && 
              <section>
                <StripeSplitElementsScreen setStripeConfig={setStripeConfig} stripeConfig={stripeConfig} stripePromise={stripePromise} />
              </section>
            }
        </div>
    )
}

export default SubscriptionScreen

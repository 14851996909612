import React from 'react';
import { Route } from "react-router-dom";

const SinglePageRoute = ({ component: Component, ...rest }) => {
    const component = props => (
      <div className="h-screen">
        <div className="w-full h-full">
          <Component {...props} />
        </div>
      </div>
    )
    return <Route {...rest} component={component} />
}

export default SinglePageRoute
import React, { useEffect, useState } from 'react'
import { Layout, PageHeader, Form, Input, Button, Spin, notification } from 'antd';
import { Auth } from "aws-amplify";
import AxiosApi from '../config/axios-api';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';
import en from 'world_countries_lists/data/en/world.json';

const MyProfileScreen = () => {
    
    const [loader, setloader] = useState(false);
    const [userDataDetails, setUserDetails] = useState(null);
    const {Content} = Layout;
    const [form] = Form.useForm();
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));

    const userId = Number(localStorage.getItem("userId"))
    const demoData = localStorage.getItem("demoData")
    const openNotification = placements => {
        notification[placements.type]({
            message: `${placements.msg}`,
            description: placements.description,
            placement:  placements.apperence,
        });
    };
    const layout = {
        labelCol: {
            span: 3,
        },
        wrapperCol: {
            span: 5,
        },
    };
    
    const tailLayout = {
        wrapperCol: {
            offset: 1,
            span: 4,
        },
    };

    const getUserDetails = async() => {
        const userDetails = await AxiosApi.get('user-details/'+authResult.attributes['email']);
        if(userDetails?.data?.result?.userData?.records.length)
        {
            setUserDetails(userDetails?.data?.result?.userData?.records[0])
        }
        console.log(`userDetails`, userDetails)
    }

    useEffect(() => {
        getUserDetails();
        // document.querySelector(".ant-select-selection-search-input").setAttribute("placeholder","+44");
    },[])

    const changeNumber = async value => {
        console.log(`changeNumber value`, value)
        value && 
        await form.setFieldsValue({
            phone_number:
            {
                phone : parseInt(value) && value?.length < 16 ? ""+parseInt(value.slice(0,14)) : value
            }
        });
        return 'Please enter valid phone number'
    }
    const onFinish = values => {
        let phone_number = values.phone_number.code+values.phone_number.phone;
        console.log(`changeobj`, {...values,...{"phone_number": "+"+phone_number }})
        console.log(`values`, values)
        setloader(true);
        delete values.EmailAddress;
        Auth.currentAuthenticatedUser()
        .then(user => {
            return  Auth.updateUserAttributes(user, {...values,...{"phone_number": "+"+phone_number }});
        })
        .then(async data => 
            {
                authResult.attributes = {...authResult.attributes,...values};
                localStorage.setItem('AuthenticationResult',JSON.stringify(authResult))
                try {
                    let objData = {...values,...{"user_id": userId,"first_name": values["custom:first_name"],"last_name": values["custom:last_name"],phone:phone_number}};
                    AxiosApi.defaults.headers.patch['Content-Type'] = 'application/json';
                    await AxiosApi.patch('user/profile',JSON.stringify(objData));
                    openNotification({
                        type: 'success',
                        msg: 'Success',
                        description: 'Profile updated successfully',
                        apperence: 'topRight',
                    });
                } catch (e) {
                    openNotification({
                        type: 'error',
                        msg: 'Error',
                        description: e.message,
                        apperence: 'topRight',
                    });
                }
                setloader(false);
            }
        )
        .catch(err => {
            // alert(e.message);
            openNotification({
                type: 'error',
                msg: 'Error',
                description: err.message,
                apperence: 'topRight',
            });
            setloader(false);
        });
        
    };

    return (
        <ConfigProvider locale={en} >
        <div className="my-profile">
            <Content className="mx-6 my-6">
                <div className="ns-shadow-xl ns-rounded-lg ns-content-section pb-16">
                    <PageHeader className="p-5"  title="Contact Details"/>
                    { 
                    userDataDetails
                    ?
                        (<Spin spinning={loader}>
                            <Form {...layout}  form={form} 
                                name="control-hooks" onFinish={onFinish}>
                                <Form.Item
                                    name="custom:first_name"
                                    label="First Name"
                                    initialValue={userDataDetails?.first_name}
                                    rules={[{
                                            required: true,
                                            message: 'Please input your First Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Name"  className="rounded-md" />
                                </Form.Item>
                                <Form.Item
                                    name="custom:last_name"
                                    label="Last Name"
                                    initialValue={userDataDetails?.last_name}
                                    rules={[{
                                            required: true,
                                            message: 'Please input your Last Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Name"  className="rounded-md" />
                                </Form.Item>
                                <Form.Item
                                    name="EmailAddress"
                                    label="Email Address"
                                    initialValue={authResult.attributes['email']}
                                    rules={[{
                                            required: true,
                                            type: 'email'
                                        },
                                    ]}
                                >
                                    <Input disabled placeholder="Email Address" className="rounded-md" />
                                </Form.Item>
                                <Form.Item 
                                    name="phone_number"
                                    label="Phone Number"
                                    // onChange={(e)=>{
                                    //     e.preventDefault();
                                    //     changeNumber(e.target.value)}}
                                    initialValue={
                                        {
                                            short : userDataDetails?.phone_country_short_name || 'us',
                                            code : userDataDetails?.phone_country_code,
                                            phone : userDataDetails?.phone?.replace(userDataDetails?.phone_country_code,""),
                                        }
                                    }
                                    rules={[{
                                            required: true,
                                            validator: (_, value) =>{
                                            let lenValue = value?.phone?.length > 9 && value?.phone?.length < 16;
                                            return (value.code && lenValue && !isNaN(value?.phone))
                                                    ? Promise.resolve() : Promise.reject(new Error('Please enter valid phone number'))
                                            } 
                                        },
                                    ]}
                                    
                                >
                                    <CountryPhoneInput placeholder="1112223456"  className="rounded-md" />
                                </Form.Item>
                                <Form.Item {...tailLayout} className="float-right p-5 pt-3">
                                    {!demoData && <Button type="primary"  className="rounded-md" htmlType="submit">
                                        Update
                                    </Button>}
                                </Form.Item>
                            </Form>
                        </Spin>)
                :<Spin spinning={true} tip="Loading User Profile Details..." ></Spin>
                }
                </div>
            </Content>
        </div>
    </ConfigProvider>
    )
}

export default MyProfileScreen
import React from "react";
import { Modal, Button } from "antd";
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';

const SuccessfulConnectedMailboxModal = ({ visible, setVisible,  onCancel}) => {
    
  const onFinish = async values => {
      console.log('Success:', values);
  };

  const BoldText = ({text}) => <span className="text-black text-font-bold">{text}</span>
  const BoldLink = ({text,link}) => <u><a target="_blank" rel="noopener noreferrer" href={link}>{text}</a></u>

  return (
    <Modal
    className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={700}
      onOk={onFinish}
      footer={null}
    >
      <h2 className="pb-2 text-4xl text-center">Awesome work <span role="img" aria-label="party face">🥳🥳🥳</span> - that’s now the mailbox you want to use on Allegrow connected.</h2>
      <div className="flex flex-row px-4 pl-0">
        <div className="text-center px-2 py-2 m-2">
          <p key={1} className="text-gray-700 py-4 text-base text-left">Before moving on and connecting another there are 3 checks and points to be aware of:</p>
          {/* <p className="text-gray-700 py-4 text-base text-left">{parse(welcomeMessage.dealMessage)}</p> */}
          <p key={2} className="text-gray-700 py-4 text-base text-left flex flex-row">
            <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-2 py-1" />
            <span className="nowrap whitespace-normal">
              <BoldText text="Expect "/> emails to be 
              <BoldText text=" Sent & Received "/> from that email account that have &nbsp;
              <BoldLink text='" -myNS."' link="https://help.neverspam.io/en/articles/4645848-create-productivity-label"/>
              &nbsp;&nbsp;in their subject line. (you should 
              <BoldText text=" NOT "/>move these emails from where they land manually or using a rule). 
            </span>
          </p>
          <p key={3} className="text-gray-700 py-4 text-base text-left flex flex-row">
            <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-2 py-1" />
            <span className="nowrap whitespace-normal">
              Look at the email account on your dashboard and make sure it isn’t showing an error symbol like this
              ( <ExclamationCircleTwoTone twoToneColor="#ff4d4f" className="align-middle" /> )
              next to it - as this means we haven’t connected properly. (this is usually shown when an app password hasn’t been used). 
            </span>
          </p>
          <p key={4} className="text-gray-700 py-4 text-base text-left flex flex-row">
            <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-2 py-1" />
            <span className="nowrap whitespace-normal">
              Remember it takes a little bit of time for the system to calculate your spam rate as it needs to send enough emails to calculate a fair average over a number of days.
              <BoldText text=" (usually 3 days) "/>
            </span>
          </p>
        </div>
      </div>
      <div className="flex items-start flex-col">
          <h3 className="pl-4 p-2 font-black text-gray-700 text-left text-base">
            After you’ve checked these 3 points go ahead and <BoldText text=" add more email inboxes "/>
            by pressing ‘New Mailbox’ this will help calculate a more accurate score and increase your reputation faster.
          </h3>
          <Button type="primary" onClick={()=>setVisible(false)} className="rounded-md ml-16 mt-8 w-5/6" size="large" block>
              I’ve checked the 3 points
          </Button>
      </div>
</Modal>
  );
};

export default SuccessfulConnectedMailboxModal;
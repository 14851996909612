import React from "react";
import { Modal } from "antd";
import { CheckCircleTwoTone } from '@ant-design/icons';
import parse from 'html-react-parser';

const CongratulationsModal = ({ visible,  setVisible, currentPlan, onCancel}) => {
    
  const onFinish = async values => {
      console.log('Success:', values);
  };

  const welcomeMessage = JSON.parse(currentPlan.welcome_message);
  console.log('welcomeMessage',welcomeMessage);

  return (
    <Modal
    className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={700}
      onOk={onFinish}
      footer={null}
    >
            <h2 className="pb-2 text-4xl text-center">Congratulations! <span role="img" aria-label="party face">🎉</span></h2>
            <div className="flex flex-row px-4 pl-0">
              <div className="text-center px-2 py-2 m-2">
                <p className="text-gray-700 py-4 text-base text-left">You’ve successfully redeemed the {welcomeMessage.dealName} deal!</p>
                <p className="text-gray-700 py-4 text-base text-left">{parse(welcomeMessage.dealMessage)}</p>
                { welcomeMessage.dealCovered.length 
                  && welcomeMessage.dealCovered.map((dealCover,dealIndex) => {
                      return (
                        <p key={dealIndex} className="text-gray-700 py-4 text-base text-left flex flex-row">
                          <CheckCircleTwoTone twoToneColor="#52c41a"className="pt-1 px-2 py-1" />
                            {parse(dealCover)}
                        </p>
                      )}
                    )
                }

              </div>
            </div>
            <div className="flex items-start flex-col">
                <h3 className="pl-4 p-2 font-black text-gray-700 text-left text-base">
                  To get instructions on how to connect your 1st email account go <u> <a target="_blank" rel="noopener noreferrer" href="https://help.neverspam.io/en/collections/2638775-account-set-up">here</a></u>. (We’ll email step by step instructions shortly ✍️)<br/><br/>
                  For any question, write to us at <a href="mailto:hello@neverspam.io">hello@neverspam.io</a> or let us know via Intercom.<br/><br/>
                  The&nbsp;Allegrow&nbsp;Team
                </h3>
            </div>
</Modal>
  );
};

export default CongratulationsModal;

import React, { useRef, useEffect, useState } from 'react'
import { Statistic, Card, Row, Col,Alert, Popover } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import ChartJs from 'chart.js';
import { Link } from "react-router-dom";
import { USER_INSIGHTS } from '../../Routes/Constants/RouteUrls';
import Tooltips from './Tooltips';
import { startCase, replace } from 'lodash';
import customTootip from '../Shared/ChartCustomTootip'

const SpamRateOfMailboxScreen = ({setVisibleUpdateCredentialsForm,dailyExpectedEmails,selectedMailbox,statistics=null,domainName,domainInsightsCountDetails,chartDataStat=null}) => {
    console.log('selectedMailbox', selectedMailbox)
    let {connection_status:connectionStatus,connection_error:connectionError,email} = selectedMailbox ? selectedMailbox.sub_accounts : {};
    const titlePopover = (title) => <span className="Allegrow-Email-Categ">Allegrow {title}</span>;
    const EmailTypesPopover = ({title})  => <span  className="info-emails cursor-pointer" ><Popover  placement="bottom"  content={Tooltips(activeChartLayout)} title={title}><InfoCircleFilled /></Popover></span>;
    const [width, setWidth] = useState(window.innerWidth*0.5);
    const [height, setHeight] = useState(window.innerHeight);
    const { domainInsightsCount
        /*, loading, error */
    } = domainInsightsCountDetails || {};
    const dailyTodayEmails = (dailyExpectedEmails?.expectedEmails?.emails_today ?? dailyExpectedEmails?.[domainName]?.emails_today);
    const dailyTodayReplies = (dailyExpectedEmails?.expectedEmails?.replies_today ?? dailyExpectedEmails?.[domainName]?.replies_today);
    const collectingMessage ='Collecting data, chart available after 3 business days.';
    console.log('domainInsightsCount', domainInsightsCount)
    
    let chartData1 =    {
                            'reputation' : [],
                            'spam_rate' : [],
                            'daily_emails' : [],
                            'daily_replies' : []
                        };


    // let chartDataStat = JSON.parse(localStorage.getItem('mailboxStat'))

    console.log('chartDataStat',chartDataStat);
    console.log('statistics',statistics);
    console.log(domainName);
    console.log('connectionStatus', connectionStatus)
    if(connectionStatus && connectionStatus==='credentials_error'){
        connectionError= <div><span>{connectionError}</span><a className="pl-2 text-red-700" onClick={()=>setVisibleUpdateCredentialsForm(true)}  href="#/">Update credentials here.</a></div>;
    }

    const finalData = statistics ?  statistics[domainName] :  chartDataStat?.data[domainName] || []
    const chartData = [finalData]
    console.log('chartDataArr',chartData);
    const [activeChartLayout, setActiveChartLayout] = useState('daily_emails');
    chartData[0]['daily_emails'] =  chartData[0]?.multi_type_emails?.clef_emails || [];
    chartData[0]['daily_replies'] =  chartData[0]?.multi_type_replies?.dorian_replies || [];
    const emptyStateChart = (!chartDataStat?.data[domainName] && !statistics?.[domainName])?true:false
    const commonProperties = {
        label: true,
        fill: true,
        pointBackgroundColor: 'white',
        pointHoverBackgroundColor: 'white',
        pointBorderWidth:2.5,
        pointHoverBorderWidth:2.5,
        pointHoverRadius: 6,
        pointStyle: 'circle',
        // pointStyle: ['triangle', 'triangle', 'triangle', 'triangle', 'triangle', 'triangle','triangle', 'triangle', 'triangle', 'triangle', 'triangle', 'triangle','triangle', 'triangle', 'triangle', 'triangle', 'triangle', 'triangle'],
        pointRadius: function(context) {      
            return (context.dataIndex === context.dataset.data.length-1) ? 6 : 0; 
        },
        zIndex:9999
    }
    const multiTypeEmails = [{
        borderColor: '#F8452F',
        lightColor: '#FF6A00',
        titleColor: '#FF6A00',
        labelName: 'Clef Emails',
        data: chartData[0]?.multi_type_emails?.clef_emails,
        ...commonProperties
    },{
        borderColor: '#8E54E9',
        lightColor: '#4776E6',
        titleColor: '#353fc3',
        labelName: 'Dorian Emails',
        data: chartData[0]?.multi_type_emails?.dorian_emails,
        ...commonProperties
    },{
        borderColor: '#3CD3AD',
        labelName: 'Motif Emails',
        lightColor: '#4CB8C4',
        titleColor: '#4CB8C4',
        data: chartData[0]?.multi_type_emails?.motif_emails,
        ...commonProperties
    }];
    const multiTypeReplies = [
        {
            borderColor: '#F8452F',
            lightColor: '#FF6A00',
            titleColor: '#FF6A00',
            labelName: 'Clef Replies',
            data: chartData[0]?.multi_type_replies?.clef_replies,
            ...commonProperties
        },
        {
            borderColor: '#218000',
            lightColor: '#52C234',
            titleColor: '#52C234',
            labelName: 'Dorian Replies',
            data: chartData[0]?.multi_type_replies?.dorian_replies,
            ...commonProperties
        }
    ];


    const chartContainer = useRef([]);
    const [chartInstance, setChartInstance] = useState([]);
    const chartConfig = {
        type: 'line',
        maintainAspectRatio: true,
        data: {
            labels: chartData[0].dates || [],
            datasets:multiTypeEmails
        },
        options: {
            layout: {
                padding: {
                    top: 7,
                    right: 7,
                }
            },
            
            maintainAspectRatio:false,
            legend: false,
            responsive: true,
            z: 2,
            plugins: [{
                tooltip: {
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: function(context) {
                            return {
                                pointStyle: 'triangle',
                                rotation: 0
                            };
                        }
                    }
                }
            }],
            tooltips: {
                enabled: false,
                position: 'nearest',
                mode: 'index',
                custom: customTootip,
                intersect: false,
                yPadding: 10,
                xPadding: 17,
                caretSize: 10,
                bodyFontSize: 15,
                borderWidth: 1,
                displayColors: false,
                backgroundColor: '#FFF',
                borderColor: '#dbdbdbc9',
                titleFontSize: 16,
                labelFontColor: '#AC105C',
                titleFontColor: '#262626d9',
                titleFonFamily: 'Nunito',
                bodyFontFamily: 'Nunito',
                bodyFontColor: '#262626d9',
                rtl:false,
                callbacks: {
                    label: function(tooltipItem, data) {
                      
                        let label = data.datasets[tooltipItem.datasetIndex].labelName || '';

                        if (label) {
                            label += `: ${tooltipItem.value}${(data.datasets.length===1) ? '%':''}`;
                        }
                        return label;
                    },
                    labelTextColor: function(tooltipItem, data) {
                        console.log(`data`, data.tooltip._data.datasets[tooltipItem.datasetIndex])
                        return data.tooltip._data.datasets[tooltipItem.datasetIndex].titleColor;
                    }
                }
            },
            elements:{
                point:{
                    pointStyle: 'circle'
                }
            },
            scales: {
                xAxes: [{
                    display: false,
                }],
                yAxes: [{
                    display: false,
                }]
            }
        }
    };

    useEffect(() => {
        if (chartContainer && chartContainer.current && !(chartInstance instanceof ChartJs)) {
            const { gradient_bd_color:bd1, gradient_bg_color:bg1 } = createGradient(width,multiTypeEmails[1].borderColor,multiTypeEmails[1].lightColor);
            chartConfig.data.datasets[1].borderColor = bd1;
            chartConfig.data.datasets[1].backgroundColor = bg1;
            const { gradient_bd_color:bd2, gradient_bg_color:bg2 } = createGradient(width,multiTypeEmails[2].borderColor,multiTypeEmails[2].lightColor);
            chartConfig.data.datasets[2].borderColor = bd2;
            chartConfig.data.datasets[2].backgroundColor = bg2;
            const { gradient_bd_color,  gradient_bg_color } = createGradient(width,multiTypeEmails[0].borderColor,multiTypeEmails[0].lightColor);
            chartConfig.data.datasets[0].borderColor = gradient_bd_color;
            chartConfig.data.datasets[0].backgroundColor = gradient_bg_color;
            const chartInstanceCreate = new ChartJs(chartContainer.current, chartConfig);
            setChartInstance(chartInstanceCreate);
        }
    }, [chartContainer]);
    

    const createGradient = (widthChart, dark = "#9835c3", light = "#ef7ef8") => {
        let gradient_bd_color = chartContainer.current.getContext("2d").createLinearGradient(0, 0, widthChart, 0);
        gradient_bd_color.addColorStop(0, dark);
        gradient_bd_color.addColorStop(1, light);

        let gradient_bg_color = chartContainer.current.getContext("2d").createLinearGradient(0, 0, widthChart, 0);
        gradient_bg_color.addColorStop(0, dark+'15');
        gradient_bg_color.addColorStop(.90, light+'25');
        gradient_bg_color.addColorStop(1, light+'02');

        return { gradient_bd_color, gradient_bg_color };
    }

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth*0.5);
        setHeight(window.innerHeight);
        // chartResize();
        // width = (window.innerWidth) | 0;
        chartLayout(activeChartLayout);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    },[width,height]);

    
    const fillGradient = (multiType) => {
        console.log(`multiType`, multiType)
        let chartInstanceData = chartInstance?.data?.datasets;
            if(chartInstanceData){
                chartInstance.data.datasets = multiType;
                for (let index = 0; index < multiType.length; index++) {
                    let { gradient_bd_color, gradient_bg_color } = createGradient(width,multiType[index].borderColor,multiType[index].lightColor);
                    chartInstance.data.datasets[index].borderColor = gradient_bd_color;
                    chartInstance.data.datasets[index].backgroundColor = gradient_bg_color;
                    
                }
            }
    }
    
    const chartLayout = (keyOfchart) => {
            console.log(`keyOfchart`, keyOfchart)
            setActiveChartLayout(keyOfchart);

            if(chartInstance instanceof ChartJs) {

                if(keyOfchart==='daily_emails'){
                    fillGradient(multiTypeEmails);
                } else if (keyOfchart==='daily_replies'){
                    fillGradient(multiTypeReplies);
                } else {
                    chartInstance.data.datasets =   [{
                        data: chartData[0][keyOfchart],
                        labelName: startCase(keyOfchart),
                        titleColor: (keyOfchart==='reputation')?'#9835c3':'#AC105C',
                        ...commonProperties
                   }];
                    const { gradient_bd_color, gradient_bg_color } = (keyOfchart==='reputation')?createGradient(width):createGradient(width,'#AC105C','#ED2788');
                    chartInstance.data.datasets[0].borderColor = gradient_bd_color;
                    chartInstance.data.datasets[0].backgroundColor = gradient_bg_color;
                }
                chartInstance.update();
                setChartInstance(chartInstance);

            }
    }
    const emtpyStatistics = !chartData[0][activeChartLayout]?.length && !emptyStateChart;
    return (
        <div className="w-full">
            { connectionError 
                && <Alert
                    className='mt-4 ns-rounded-lg text-left'
                    message="Error"
                    description={connectionError}
                    type="error"
                    showIcon
                    />
            }

            <Row className="ns-shadow-xl ns-rounded-lg m-5 personal-statistic" gutter={8}>
                <Col className="text-left ns-p-0" span={16}>
                    <h1 className='Spam-Rate pl-5 pt-5'>{email || domainName || 'allegrow.co'}</h1>
                    <h1 className={`pl-5 pt-1 Reputation ${activeChartLayout==='spam_rate' && 'spamRateCard'} ${!emptyStateChart && 'capitalize1' + replace((email || domainName),/[^a-zA-Z ]/g,'')}`}>
                        {
                            emptyStateChart
                            ? collectingMessage
                            : <span className='capitalize multi-type-emails'>{
                                    activeChartLayout==='daily_emails' 
                                        ? <>
                                            <span className="clef-emails" >Clef Emails</span>
                                            <span className="px-4 dorian-emails" >Dorian Emails</span>
                                            <span  className="motif-emails" >Motif Emails</span>
                                            {!emtpyStatistics && <EmailTypesPopover title={titlePopover('Email Categories')} />}
                                        </>
                                        : activeChartLayout==='daily_replies' 
                                            ? <>
                                                <span  className="pr-4 clef-emails">Clef Replies</span>
                                                <span className="pr-1 dorian-replies" >Dorian Replies</span>
                                                {!emtpyStatistics && <EmailTypesPopover title={titlePopover('Reply Categories')}  />}
                                            </>
                                            : <>
                                                {activeChartLayout.replace('_',' ')}
                                                {!emtpyStatistics && <EmailTypesPopover title={titlePopover(activeChartLayout==='reputation'?'Sender Reputation':'Spam Rate')}  />}
                                              </>
                                }</span>
                        }
                        {   
                            !chartData[0][activeChartLayout]?.length && !emptyStateChart 
                            && ` (${collectingMessage})`
                        }
                    </h1>
                    <div className={`line-chart ${emptyStateChart && 'hidden'}`} >
                        <div className="chart-container"  style={{height:'250px', width:'54vw'}}>
                            <canvas className="ns-rounded-bl-lg" ref={chartContainer} />
                        </div>
                    </div>
                </Col>
                <Col span={8} className='chartCard'>
                    <Row className="pt-5 pr-4 " gutter={16} style={{marginBottom:10}}>
                        <Col span={12}>
                            <Card  onClick={()=>chartLayout('reputation')} className={`ns-bg-light-white ns-rounded-lg cursor-pointer ${activeChartLayout==='reputation' && 'activeChartCard'}`} >
                                <Statistic
                                title="Reputation"
                                value={(emptyStateChart || !chartData[0].reputation.length) ?"-":chartData[0].reputation.slice(-1)+"%"}
                                precision={2}
                                bordered={false}
                                valueStyle={{ color: '#3f8600' }}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card  onClick={()=>chartLayout('spam_rate')} className={`ns-bg-light-white ns-rounded-lg cursor-pointer ${activeChartLayout==='spam_rate' && 'activeChartCard'}`} >
                                <Statistic
                                title="Spam Rate"
                                value={(emptyStateChart || !chartData[0].spam_rate.length)?"-":chartData[0].spam_rate.slice(-1)+"%"}
                                precision={2}
                                bordered={false}
                                valueStyle={{ color: '#3f8600' }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row className="pt-2 pr-4" gutter={16}>
                        <Col span={12}>
                            <Card onClick={()=>chartLayout('daily_emails')} className={`ns-bg-light-white ns-rounded-lg cursor-pointer ${activeChartLayout==='daily_emails' && 'activeChartCard'}`}>
                                <Statistic
                                    title={(<>Emails Today</>)}
                                    value={(emptyStateChart || !chartData[0].multi_type_emails.dorian_emails.length)
                                            ?"-"
                                            : (   (+chartData[0].multi_type_emails.dorian_emails.slice(-1)) 
                                                + (+chartData[0].multi_type_emails.motif_emails.slice(-1))
                                                + (+chartData[0].multi_type_emails.clef_emails.slice(-1))
                                            )
                                        }
                                    bordered={false} 
                                    valueStyle={{ color: '#3f8600' }}
                                    // suffix={!emptyStateChart && !!dailyTodayEmails && ('/ '+dailyTodayEmails)}
                                />
                        </Card>
                        </Col>
                        <Col  span={12}>
                            <Card onClick={()=>chartLayout('daily_replies')} className={`ns-bg-light-white ns-rounded-lg cursor-pointer ${activeChartLayout==='daily_replies' && 'activeChartCard'}`}>
                                <Statistic
                                    title={(<>Replies Today</>)}
                                    value={(emptyStateChart || !chartData[0].multi_type_replies.dorian_replies.length)
                                            ?"-"
                                            : (   (+chartData[0].multi_type_replies.dorian_replies.slice(-1)) 
                                                + (+chartData[0].multi_type_replies.clef_replies.slice(-1))
                                            )
                                        }
                                    bordered={false} 
                                    valueStyle={{ color: '#3f8600' }}
                                    // suffix={!emptyStateChart && !!dailyTodayReplies && ('/ '+dailyTodayReplies)}
                                />
                            </Card>
                            {emptyStateChart && <div className="mt-5"></div>}
                        </Col>
                        {
                         !!(domainInsightsCount && domainInsightsCount?.result?.length && domainInsightsCount?.result[0][domainName])
                         && <div>
                                <h3 className="block p-5 pt-6 font-black text-gray-700 text-left">
                                    You have <span className="font-extrabold text-black">{domainInsightsCount.result[0][domainName]}  ways</span> to improve your scores.<br/>
                                    <Link to={`${USER_INSIGHTS+(domainName?'?domain='+domainName:'')}`} className="underline" >View your insights.</Link>
                                </h3>
                            </div>
                        }
                    </Row>
                </Col>
            </Row>
                        
        </div>
    )
}

export default SpamRateOfMailboxScreen

import React from "react";
import { Modal, Button, Typography } from "antd";
import { CreditCardOutlined } from '@ant-design/icons';

const ConfirmUpgradePlanModal = ({ visible, invoiceUpcoming, paymentType='upgradePlan', upgradeSuggestedPlanPay, setVisible, onCancel}) => {
  console.log('invoiceUpcoming', invoiceUpcoming);
  const onFinish = async values => {
      console.log('Success:', values);
  };

  const { Title } = Typography;
  const purchaseMailboxMsg = 'Purchase a New Mailbox';
  const upgradePlanMsg = 'Upgrade Your Plan';
  const finalMsg = (paymentType==='purchaseMailbox')?purchaseMailboxMsg:upgradePlanMsg;

  return (
    <Modal
    className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={500}
      onOk={onFinish}
      footer={null}
    >
      <h2 className="pb-2 text-4xl text-center">{finalMsg}</h2>
      <div className="flex flex-row px-4 pl-0">
        <div className="text-center px-2 py-2 m-2">
          <p className="text-gray-700 py-4 text-base text-left">One more step: please click on the Pay button to <span className="lowercase">{finalMsg}</span>.</p>
        </div>
      </div>
      <div className="flex items-start flex-col">
          <h3 className="pl-4 p-2 font-black text-gray-700 text-left">
            Your below saved <span className="capitalize">{invoiceUpcoming.cardDetail.funding}</span> Card will be charged<br></br>
            <div className="flex flex-row items-start space-x-2 py-2">
              <div><CreditCardOutlined /></div>
              <div className=""><Title level={5}>{invoiceUpcoming.cardDetail.brand}</Title></div> 
              <div className="py-1">**** **** **** {invoiceUpcoming.cardDetail.last4}</div>
            </div>
          </h3>
          <Button type="primary" onClick={() => upgradeSuggestedPlanPay()} className={`rounded-md  mt-8 ${(paymentType==='purchaseMailbox')?'':'w-5/6 ml-8'} `} size="large" block>
                Pay ${invoiceUpcoming.amountDue/100}  for {(paymentType==='purchaseMailbox') ? ' 1 mailbox as ':' '} {invoiceUpcoming.planName}
          </Button>
      </div>
    </Modal>
  );
};

export default ConfirmUpgradePlanModal;
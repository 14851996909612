import React ,{ useState }from 'react'
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, Button, Spin, notification, Checkbox } from 'antd';
import AxiosApi from '../config/axios-api';
import { Auth } from "aws-amplify";
// import ConfirmSignupModal from '../Modals/ConfirmSignupModal';
import ConfirmSignupMessageModal from '../Modals/ConfirmSignupMessageModal';
import { useDispatch } from 'react-redux';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import {USER_MAILBOXES} from '../Routes/Constants/RouteUrls';

const openNotification = placements => {
    notification.error({
      message: `Signup ${placements.msg}`,
      description: 
        placements.description,
      placement:placements.apperence,
    });
};

const AppSumoScreen = () => {
    const history = useHistory();
    let { appsumo_code } = useParams();
    const dispatch = useDispatch();
    const [loader, setloader] = useState(false);
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const urlParams = new URLSearchParams(window.location.search);
    const demoData = urlParams.get('demo-data');

    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 23,
        },
    };

    const tailLayout = {
        wrapperCol: {
          offset: 0,
          span: 23,
        },
    };
      
    const onFinish = async values => {
        setloader(true);
        setEmail(values.email);
        console.log(`email`, email);
        try {
            const email = values.email.toLowerCase();
            const objUser = {
                username: email,
                password: values.password,
                first_name: values.first_name,
                last_name: values.last_name,
                attributes: {
                  email,
                  'custom:first_name': values.first_name,
                  'custom:last_name': values.last_name,
                  'nickname': values.nickname,
                },
              };

            
            const signup = await Auth.signUp(objUser);
            AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
            await AxiosApi.post('auth/signup',JSON.stringify({...objUser,...{password:'',company_name:values.company_name || '   ' }}))
                            .catch(error => {
                                throw (error.response.data);
                            });

            //localStorage.setItem('userId',user)
            if (!signup.userConfirmed) {
                setVisible(true);
                setloader(false);
            } else {
                try {
                    const user = await Auth.signIn(email, values.password);
                    if (user) {
                        if(demoData)
                            localStorage.setItem("demoData",1);
                        console.log('userlogin  ',user);
                        localStorage.setItem('AuthenticationResult', JSON.stringify(user));
                        let getJwtToken = user.signInUserSession.idToken.jwtToken
                        AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${getJwtToken}`
                        try{
                            if(!user.attributes['custom:user_id'] || !user.attributes['custom:company_id']) {
                                const userDetails = await AxiosApi.get('user-details/'+email);
                                if(userDetails.data.result.userData.records && userDetails.data.result.userData.records.length){
                                    await Auth.updateUserAttributes(user, {
                                        'custom:user_id': ""+userDetails.data.result.userData.records[0].id,
                                        'custom:company_id': ""+userDetails.data.result.userData.records[0].company_id,
                                    });
                                    localStorage.setItem("userId",userDetails.data.result.userData.records[0].id);
                                    localStorage.setItem("companyId",userDetails.data.result.userData.records[0].company_id);
                                    
                                }
                            } else{
                                localStorage.setItem("userId",user.attributes['custom:user_id']);
                                localStorage.setItem("companyId",user.attributes['custom:company_id']);
                            }
                            window.heap.resetIdentity();
                            window.heap.identify(localStorage.getItem("userId"));
                            window.heap.addUserProperties({ "First Name":  user.attributes['custom:first_name'], "Last Name":user.attributes['custom:last_name'],email,"Company Name":values.company_name});
                            process.env.REACT_APP_PROFITWELL_API_KEY && window.profitwell({ 'user_email': email });
                            await dispatch(planDetailsAction(localStorage.getItem("userId")));
                        } catch (e) {
                            console.log('error get userId:', e);
                        }
                        history.push(USER_MAILBOXES+"?congratulations=true");
                    }
                } catch (e) {
                    setloader(false);
                    console.log('API call signup error :',e.message);
                    console.log('e', e)
               
                    // alert(e.message);
                    openNotification({
                        type: 'error',
                        msg: 'Error',
                        description: e.message,
                        apperence: 'topRight',
                    });
                }
            }
          } catch (e) {
            setloader(false);
            console.log('pre signup error :',e.message);
            console.log('e', e)
            if(e.name==='UserLambdaValidationException' && e.message.includes('PreSignUp'))
                e.message = 'Invalid AppSumo Code - it has probably been used before.';
            if (e.errors) {
                e.message = e.errors.map((err,errIndex) => (<p key={errIndex}>{err.message.replaceAll("_"," ")}</p>))
                e.message = <div>{e.message}</div>
            }
            openNotification({
                type: 'error',
                msg: 'Error',
                description: e.message,
                apperence: 'topRight',
            });
          }
    };
    
    const onFinishFailed = errorInfo => {
        
    };
    
    
    
    return (
        <>
            {/* <ConfirmSignupModal username={email}
                                    visible={visible}
                                    onCancel={() => setVisible(false)}
                            />  */}
            <ConfirmSignupMessageModal visible={visible} onCancel={() => setVisible(false)}
                            /> 
            <div className="w-46 pb-8">
                <h4 className="Welcome-Please-sign">
                    Welcome please sign up below.
                </h4>
            </div>
            <div className="w-46 pb-12">
                <Spin spinning={loader}>
                    <Form
                    {...layout}
                    form={form} 
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                                ]}
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        >
                            <Input placeholder="First Name" className="rounded-md" />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last Name!',
                                },
                                ]}
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        >
                            <Input placeholder="Last Name" className="rounded-md" />
                        </Form.Item>
                        
                        <Form.Item
                            name="email"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                                type: 'email'
                            },
                            ]}
                        >
                             <Input placeholder="Email" className="float-left rounded-md" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ]}
                        >
                            <Input.Password placeholder="Password" className="rounded-md" />
                        </Form.Item>
                        <Form.Item
                            name="nickname"
                            initialValue={appsumo_code}
                            rules={[
                            {
                                required: true,
                                message: 'Please input your AppSumo Code!',
                            },
                            ]}
                        >
                            <Input disabled placeholder="AppSumo Code" className="float-left rounded-md" />
                        </Form.Item>
                        <Form.Item
                          name="agreement"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Please accept Terms of Use and the Privacy Policy'),
                            },
                          ]}
                          {...tailLayout}
                        >
                          <Checkbox>
                            I agree to the <a className="text-blue-500"  rel="noopener noreferrer" href="https://www.neverspam.io/terms-and-conditions" target="_blank">Terms of Use</a> and the <a className="text-blue-500"  rel="noopener noreferrer" href="https://www.neverspam.io/privacy-policy" target="_blank">Privacy Policy</a>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item className="" {...tailLayout}>
                            <Button type="primary" className="float-left w-40 rounded-md" size="large" htmlType="submit">
                                    Register
                            </Button>
                        </Form.Item>
                    </Form>
                 </Spin>
            </div>
       </>
    )
}

export default AppSumoScreen

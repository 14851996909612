import React, { useEffect } from 'react'
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Layout, PageHeader, Modal, Menu, Form, Input, Card, Statistic, Spin, message, Select } from 'antd';
import { LeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { USER_CONTENT_TESTS } from '../Routes/Constants/RouteUrls';
import { getlocalStorageValue } from '../Helpers/globalValues'
import DateRangeCard from './Shared/Cards/DateRangeCard';
import { contentTestsCreateUpdateAction, contentTestsDraftAction, contentTestsdeleteAction } from '../Store/Actions/contentTestsActions';
import { openNotification,openNotificationError } from "../Helpers/globalNotification"
import { CONTENT_TESTS_CREATE_REQUEST_UNLOAD, CONTENT_TESTS_DELETE_REQUEST, CONTENT_TESTS_DRAFT_REQUEST } from '../Store/Constants/contentTestsConstant';

const CreateContentTestsScreen = () => {
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const arrEmailAccounts = JSON.parse(localStorage.getItem('arrEmailAccounts'));
    const thisMonthDay = moment().format("MMM	D");
    const nextMonthDay = moment().add(28, 'd').format("MMM	D");
    const { Header, Content } = Layout;
    const { confirm } = Modal;
    const history = useHistory();
    const { contentId } = useParams();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const { contentTestsCreateUpdate, loading, errorContentTestsCreateUpdate } = useSelector((state) => state.contentTestsCreateUpdateInfo);
    const { contentTestsDraft, loading:loadingDraft } = useSelector((state) => state.contentTestsDraftInfo);
    console.log(`contentTestsCreateUpdate`, contentTestsCreateUpdate)
    console.log(`contentTestsDraft`, contentTestsDraft)
    
    useEffect(() => {
      contentId && dispatch(contentTestsDraftAction(contentId))
    }, [])

    useEffect(() => {
      if(typeof contentTestsDraft === 'string' && !contentTestsDraft){
        message.info('Sorry, Nothing matched found');
        history.push(USER_CONTENT_TESTS)
      }
      if(contentTestsDraft && contentTestsDraft?.endDate){
        form.setFieldsValue({
          test_title: contentTestsDraft?.title,
          test_mailbox_id: contentTestsDraft?.outgoingMailboxId,
          test_date: [moment(contentTestsDraft?.startDate, 'YYYY-MM-DD'),moment(contentTestsDraft?.endDate, 'YYYY-MM-DD')],
          variat_a_percentage : 50,
          variat_a_subject : contentTestsDraft?.variations[0]?.subject,
          variat_a_body : contentTestsDraft?.variations[0]?.message,
          variat_b_percentage : 50,
          variat_b_subject : contentTestsDraft?.variations[1]?.subject,
          variat_b_body : contentTestsDraft?.variations[1]?.message,
          
        })
      }
    }, [contentTestsDraft])
    
    console.log(`thisMonthDay`, thisMonthDay)
    
    useEffect(() => {
      errorContentTestsCreateUpdate?.errorMessage &&
        openNotificationError({description:errorContentTestsCreateUpdate?.errorMessage})
    }, [errorContentTestsCreateUpdate])
    
    useEffect(() => {
      if(contentTestsCreateUpdate?.title){
        openNotification({
            type: 'success',
            msg: 'Success',
            description: `Content Test ${contentId?'updated':'created'} successfully`,
            apperence: 'topRight',
        });
        history.push(USER_CONTENT_TESTS+'?waitCall=true')
      }

      return () => {
        if (errorContentTestsCreateUpdate?.errorMessage || contentTestsCreateUpdate?.title) {
          dispatch({ type: CONTENT_TESTS_CREATE_REQUEST_UNLOAD, payload: null });
          dispatch({ type: CONTENT_TESTS_DRAFT_REQUEST, payload: null });
          console.log(`unmount CreateContentTestsScreen`);
        }
      }
    }, [contentTestsCreateUpdate])
    const dispatch = useDispatch();
    const onChange = e => {
      document.querySelector(`#${e.target.id}`).parentNode.setAttribute("data-words",e.target.value ? e.target.value.split(' ').length : 0)
    };

    const onchangePercentage = e => {
      console.log(`e targe`, e.target.value)
      console.log(`e id`, e.target.id)
      console.log(`e target`, e.target)
      if(!isNaN(e.target.value) && e.target.value > 0 && e.target.value < 101){
        form.setFieldsValue({
          [e.target.id==='control-hooks_variat_a_percentage'?'variat_b_percentage':'variat_a_percentage'] : (100-e.target.value)
        })
      } else {
        if(e.target.value)
          form.setFieldsValue({
            variat_a_percentage : 50,
            variat_b_percentage : 50
          })
      }
    };

    const onFinish = values => {
      console.log(`create content values`, values)
    }

    const deleteTest = contentTestId => {
        confirm({
          title: 'Are you sure you want to delete this test?',
          icon: <ExclamationCircleOutlined />,
          content: 'This will delete all your variations as well and it cannot be undone.',
          async onOk() {
            !demoData && await dispatch(contentTestsdeleteAction(contentTestId))
            openNotification({
              type: 'success',
              msg: 'Success',
              description: `Content Test deleted successfully`,
              apperence: 'topRight',
            });
            history.push(USER_CONTENT_TESTS+'?waitCall=true')
            dispatch({ type: CONTENT_TESTS_DELETE_REQUEST, payload: null });
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }

    const handleFormSubmit = (status='start') => {
      form.validateFields()
        .then((values) => {
          console.log(`values`, values)
          // if(status==='start' && window.location.host==='app.neverspam.io')
          //   return message.info('Please get in touch with your Account Executive to start your first test');

          const submitData = {
            "title": values.test_title,
            "outgoingMailboxId": values.test_mailbox_id,
            "startDate": values.test_date[0].format('YYYY-MM-DD'),
            "endDate": values.test_date[1].format('YYYY-MM-DD'),
            "status":status,
            "variations": [
              {
              "subject": values.variat_a_subject,
              "message": values.variat_a_body,
              "percentage": values.variat_a_percentage
              },
              {
              "subject": values.variat_b_subject,
              "message": values.variat_b_body,
              "percentage": values.variat_b_percentage
              }
            ]	
          }
          if (contentId) {
              submitData['id'] = contentId
              submitData.variations[0]['id'] = contentTestsDraft?.variations[0]?.id
              submitData.variations[1]['id'] = contentTestsDraft?.variations[1]?.id
          }
          console.log(`submitData`, submitData)
          dispatch(contentTestsCreateUpdateAction(submitData))
        })
        .catch((errorInfo) => {
          console.log(`errorInfo`, errorInfo)
        });
    };   

    useEffect(() => {
        document.querySelectorAll('.ant-input-textarea-show-count').forEach((ele) => {
          ele.setAttribute("data-words",0);
        });
    },[])
    console.log(`contentId`, contentId)

    function onChange1(value) {
      console.log(`selected ${value}`);
    }
    
    function onBlur() {
      console.log('blur');
    }
    
    function onFocus() {
      console.log('focus');
    }
    
    function onSearch(val) {
      console.log('search:', val);
    }

    return (
        <div className="create-content-tests">
            <Header className="site-layout-sub-header-background p-0 fixed herder-div-width"> 
                <PageHeader 
                    className="site-page-header"  
                    title="Content Tests"
                    backIcon={<Link to={USER_CONTENT_TESTS}><LeftOutlined className="text-gray-500 text-2xl p-1" /></Link>} 
                    extra={  <>
                              { ((!loadingDraft &&  contentId) || (!loading && !contentId))
                                ?  <div className="save-as-draft">
                                    {
                                      <div>
                                        {contentId &&
                                          <button key={2}   type="link" 
                                                  className="btn ant-btn-link focus:outline-none text-bold hover:text-blue-400  text-gray-600  mr-2  py-2 px-4" 
                                                  onClick={() => !demoData && deleteTest(contentId) }
                                              >
                                              Delete Test
                                          </button>
                                        }
                                        <button key={3}  type="link" 
                                                className="btn text-bold focus:outline-none ant-btn-link hover:text-blue-400  text-gray-600  mr-2 py-2 px-4" 
                                                onClick={() => !demoData && handleFormSubmit('draft') }
                                            >
                                            Save As Draft
                                        </button>
                                      </div>
                                    }
                                    {
                                      //contentId &&
                                      <button key={1} 
                                              className="btn text-bold focus:outline-none ns-bg-green  py-2 px-4 rounded-lg text-white" 
                                              onClick={() => !demoData && handleFormSubmit() }
                                          >
                                          Start Test
                                      </button>
                                    }
                                  </div>
                                : <Spin  spinning={true} />
                              }
                            </>
                            } 
                    onBack={() => null} title={contentId?'Edit Draft':'Create New Test'}
                />
            </Header>
            <Content className="mx-6 my-6 text-center space-y-4 ant-layout-content-static pb-16">
              <Form  form={form} name="control-hooks" onFinish={onFinish}>
                <div>
                  <div  className="site-layout-background pt-2 ns-rounded-lg">
                    <div className="site-statistic-demo-card">
                      <div className="w-full p-4">
                        <div className="ns-shadow-xl ns-rounded-lg p-8 flex flex-wrap personal-statistic justify-between">
                          <div className={`w-8/12`}>
                            <Form.Item
                                  className={`p-0 mb-0`}
                                  name="test_title"
                                  initialValue={""}
                                  rules={[{
                                          required: true,
                                          message: 'Please input your Test title!',
                                      },
                                  ]}
                              >
                                  <TextArea bordered={false} autoSize={{ minRows: 2, maxRows: 4 }} placeholder="Test Title..."  className="rounded-md text-3xl" />
                              </Form.Item>
                          </div>
                 
                            <div className={`w-3/12`}>
                                <DateRangeCard
                                  isSelectable={true}
                                  contentTestsDraft={contentTestsDraft}
                                  cardTitle="Test Period" form={form} Form={Form} cardTitle1={<span>&nbsp;</span>}
                                  cardValueInit={thisMonthDay} card1ValueInit={nextMonthDay}  
                                />
                            </div>
                            <div className={`w-8/12`}>
                              <Form.Item
                                    className={`p-0 mb-0 Spam-Rate rounded-md`}
                                    style={{  }}
                                    name="test_mailbox_id"
                                    // initialValue={""}
                                    rules={[{
                                            required: true,
                                            message: 'Please select your mailbox!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        className="" 
                                        
                                        placeholder="Select a mailbox..."
                                        optionFilterProp="children"
                                        onChange={onChange1}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                      >{
                                          arrEmailAccounts &&
                                              arrEmailAccounts.map((emailAccount,accountIndex) => {
                                                if(emailAccount.sub_accounts.connection_status==='connected')
                                                  return (<Option key={accountIndex} value={emailAccount.id}>{emailAccount.email}</Option>)
                                              })
                                        }
                                      </Select>
                                </Form.Item>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                  </div>
                  <div  className="site-layout-background pt-2 ns-rounded-lg">
                    <div className="site-statistic-demo-card">
                    <div className="w-full p-4">
                        <div className="ns-shadow-xl ns-rounded-lg py-4 px-8  personal-statistic">
                        <h1 className='Spam-Rate text-left mb-4'>Variation A</h1>
                          <div  className={`flex justify-between`}>
                            <div className={`w-8/12`}>
                              <Form.Item
                                    name="variat_a_subject"
                                    initialValue={""}
                                    rules={[{
                                            required: true,
                                            message: 'Please input your Email Subject!',
                                        },
                                    ]}
                              >
                                <TextArea placeholder="Email Subject..." onChange={onChange} size="large" style={{borderRadius:'5px!important'}} 
                                          showCount autoSize={{ minRows: 1, maxRows: 2 }}  className="rounded-md" />
                              </Form.Item>
                              <div style={{ margin: '50px 0' }} />
                              <Form.Item
                                    name="variat_a_body"
                                    initialValue={""}
                                    rules={[{
                                            required: true,
                                            message: 'Please input your Email Body!',
                                        },
                                    ]}
                              >
                                <TextArea  placeholder="Email Body..." onChange={onChange} size="large" style={{borderRadius:'5px!important'}} 
                                            showCount  autoSize={{ minRows: 10, maxRows: 10 }}  className="rounded-md" />
                              </Form.Item>
                            </div>
                            <div className={`w-3/12 statistics-joined-card statistics-percentage-card`}>
                              <Card className={`statistics-joined-card ns-bg-light-white ns-rounded-lg cursor-pointer`}  >
                                <Statistic
                                  title={`Percentage of Emails`}
                                  prefix={
                                      <Form.Item name="variat_a_percentage"
                                        initialValue={50} 
                                        rules={[{
                                          required: true,
                                          message: 'Please input your Percentage!',
                                        },
                                      ]}>
                                      <Input width="10px" onChange={onchangePercentage} maxLength="2" bordered={false} />
                                    </Form.Item>
                                  }
                                  value={'%'}
                                  bordered={false}
                                />
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  className="site-layout-background pt-2 ns-rounded-lg">
                    <div className="site-statistic-demo-card">
                    <div className="w-full p-4">
                        <div className="ns-shadow-xl ns-rounded-lg py-4 px-8  personal-statistic">
                        <h1 className='Spam-Rate text-left mb-4'>Variation B</h1>
                          <div  className={`flex justify-between`}>
                            <div className={`w-8/12`}>
                              <Form.Item
                                    name="variat_b_subject"
                                    initialValue={""}
                                    rules={[{
                                            required: true,
                                            message: 'Please input your Email Subject!',
                                        },
                                    ]}
                              >
                                <TextArea placeholder="Email Subject..." onChange={onChange} size="large" style={{borderRadius:'5px!important'}} 
                                          showCount autoSize={{ minRows: 1, maxRows: 2 }}  className="rounded-md" />
                              </Form.Item>
                              <div style={{ margin: '50px 0' }} />
                              <Form.Item
                                    name="variat_b_body"
                                    initialValue={""}
                                    rules={[{
                                            required: true,
                                            message: 'Please input your Email Body!',
                                        },
                                    ]}
                                    
                              >
                                <TextArea placeholder="Email Body..." onChange={onChange} size="large" style={{borderRadius:'5px!important'}} 
                                          showCount autoSize={{ minRows: 10, maxRows: 10 }}  className="rounded-md" />
                              </Form.Item>
                            </div>
                            <div className={`w-3/12 statistics-joined-card statistics-percentage-card`}>
                              <Card className={`statistics-joined-card ns-bg-light-white ns-rounded-lg cursor-pointer`}  >
                                <Statistic
                                  title={`Percentage of Emails`}
                                  prefix={
                                      <Form.Item name="variat_b_percentage"
                                      initialValue={50} 
                                        rules={[{
                                          required: true,
                                          message: 'Please input your Percentage!',
                                        },
                                      ]}>
                                      <Input width="10px" maxLength="2" onChange={onchangePercentage} bordered={false} />
                                    </Form.Item>
                                  }
                                  value={'%'}
                                  bordered={false}
                                />
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Content>
        </div>
    )
}


export default CreateContentTestsScreen
import React, { useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin, message } from 'antd';
import { Auth } from "aws-amplify";
import FogortPasswordModal from '../Modals/FogortPasswordModal';
import ResetPasswordModal from "../Modals/ResetPasswordModal";
import AxiosApi from '../config/axios-api';
import { useDispatch } from 'react-redux';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { USER_MAILBOXES } from '../Routes/Constants/RouteUrls';


const LoginScreen = () => {

    const [email, setEmail] = useState("");
    const [forgotEmail, setForgotEmail] = useState("");
    const [password, setPassword] = useState("");
    const [visible, setVisible] = useState(false);
    const [visibleReset, setVisibleReset] = useState(false);
    const [loader, setloader] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const userId = localStorage.getItem("userId")
    const companyId = localStorage.getItem("companyId")
    if (userId && companyId) {
        history.push(USER_MAILBOXES);
    }

    const [form] = Form.useForm();
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 23,
        },
    };

    const tailLayout = {
        wrapperCol: {
          offset: 0,
          span: 23,
        },
    };
    
    const urlParams = new URLSearchParams(window.location.search);
    const demoData = urlParams.get('demo-data');
    let user;
    
    const onFinish = async values => {
        setloader(true);
        let email=values.email;
        try {
            localStorage.clear();
            user = await Auth.signIn(values.email, values.password).catch( async error => {
                console.log(error);
                if(error.name === 'UserNotFoundException'){
                    email=values.email.toLowerCase();
                    user = await Auth.signIn(email, values.password);
                    return user;
                }
                throw (error);
            });
            if (user) {
                if(demoData)
                    localStorage.setItem("demoData",1);
                console.log('userlogin  ',user);
                localStorage.setItem('AuthenticationResult', JSON.stringify(user));
                let getJwtToken = user.signInUserSession.idToken.jwtToken
                AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${getJwtToken}`
                try{
                    if(!user.attributes['custom:user_id'] || !user.attributes['custom:company_id']) {
                        const userDetails = await AxiosApi.get('user-details/'+email);
                        if(userDetails.data.result.userData.records && userDetails.data.result.userData.records.length){
                            await Auth.updateUserAttributes(user, {
                                'custom:user_id': ""+userDetails.data.result.userData.records[0].id,
                                'custom:company_id': ""+userDetails.data.result.userData.records[0].company_id,
                            });
                            localStorage.setItem("userId",userDetails.data.result.userData.records[0].id);
                            localStorage.setItem("companyId",userDetails.data.result.userData.records[0].company_id);
                        }
                    } else {
                        localStorage.setItem("userId",user.attributes['custom:user_id']);
                        localStorage.setItem("companyId",user.attributes['custom:company_id']);
                    }
                    window.heap.resetIdentity();
                    window.heap.identify(localStorage.getItem("userId"));
                    window.heap.addUserProperties({ "First Name":  user.attributes['custom:first_name'], "Last Name":user.attributes['custom:last_name'],email});
                    process.env.REACT_APP_PROFITWELL_API_KEY && window.profitwell({ 'user_email': email });
                    await dispatch(planDetailsAction(localStorage.getItem("userId")));
                } catch (e) {
                    console.log('error get userId:', e);
                }
                history.push(USER_MAILBOXES);
            }
          } catch (e) {
            console.log('e object', e);
            setloader(false);
            if(e.message==='User is not confirmed.'){
                e.message = <>User not verified: Please look for a verification email from info@neverspam.io<p> and click the link inside it to verify your account.</p></>;
            }
            message.warning(e.message,10);
          }
    };
    const openFogortPasswordModal = () => {
        //setForgotEmail(email);
        setVisible(true);
    }
    const onFinishFailed = errorInfo => {
        
        console.log('Failed:', errorInfo);
    };
      
    return (
        <>
            <div className="w-46 pb-8">
                <h4 className="Welcome-Please-sign">
                    Welcome back. Please Log in below.
                </h4>
            </div>
            <div className="w-46 pb-12">
              <Spin tip="Login..."  spinning={loader}>
                <Form
                {...layout}
                form={form} 
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        autoFocus
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                            type: 'email'
                        },
                        ]}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    >
                        <Input placeholder="Email" className="float-left rounded-md" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        ]}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    >
                        <Input.Password autoComplete="current-password" placeholder="Password" className="rounded-md" />
                    </Form.Item>

                    <Form.Item className="mb-3">
                        <Form.Item className="w-40" name="remember" valuePropName="checked" noStyle>
                            <Checkbox className="float-left">Remember me</Checkbox>
                        </Form.Item>
                            <Button type="link" onClick={() => openFogortPasswordModal() } className="p-0 pb-3 float-right" size="large">
                                Forgot password
                            </Button>
                            <FogortPasswordModal
                                    setForgotEmail={setForgotEmail}   
                                    visible={visible}
                                    setVisible={setVisible}
                                    setVisibleReset={setVisibleReset}
                                    onCancel={() => setVisible(false)}
                            />
                             <ResetPasswordModal
                                    _email={forgotEmail}   
                                    visible={visibleReset}
                                    onCancel={() => setVisibleReset(false)}
                            />
                    </Form.Item>
                    
                    <Form.Item className="" {...tailLayout}>
                        <Button type="primary" className="float-left w-40 rounded-md" size="large" htmlType="submit">
                            Log In
                        </Button>
                        <Link to="/signup">
                            <Button className="float-right w-40 text-gray-500 rounded-md"  size="large" htmlType="submit">
                                Sign up
                            </Button>
                        </Link>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
     </>
    )
}

export default LoginScreen
import {
  USER_INSIGHTS_REQUEST,USER_INSIGHTS_REQUEST_SUCCESS,USER_INSIGHTS_REQUEST_FAIL,
  MAILBOX_INSIGHTS_REQUEST,MAILBOX_INSIGHTS_REQUEST_SUCCESS,MAILBOX_INSIGHTS_REQUEST_FAIL,
  ARCHIVED_INSIGHTS_REQUEST,ARCHIVED_INSIGHTS_REQUEST_SUCCESS,ARCHIVED_INSIGHTS_REQUEST_FAIL,
  DOMAIN_INSIGHTS_COUNT_REQUEST,DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS,DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL
} from "../Constants/insightsConstants";

function userInsightsReducer(state = {userInsights: []}, action) {
  switch (action.type) {
    case USER_INSIGHTS_REQUEST:
      return { loading: true, userInsights: [] };
    case USER_INSIGHTS_REQUEST_SUCCESS:
      return { loading: false, userInsights: action.payload };
    case USER_INSIGHTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function mailboxInsightsReducer(state = {mailboxInsights: []}, action) {
  switch (action.type) {
    case MAILBOX_INSIGHTS_REQUEST:
      return { loading: true, mailboxInsights: [] };
    case MAILBOX_INSIGHTS_REQUEST_SUCCESS:
      return { loading: false, mailboxInsights: action.payload };
    case MAILBOX_INSIGHTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function archivedInsightsReducer(state = {archivedInsights: []}, action) {
  switch (action.type) {
    case ARCHIVED_INSIGHTS_REQUEST:
      return { loading: true, archivedInsights: [] };
    case ARCHIVED_INSIGHTS_REQUEST_SUCCESS:
      return { loading: false, archivedInsights: action.payload };
    case ARCHIVED_INSIGHTS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function domainInsightsCountReducer(state = {domainInsightsCount: []}, action) {
  switch (action.type) {
    case DOMAIN_INSIGHTS_COUNT_REQUEST:
      return { loading: true, domainInsightsCount: [] };
    case DOMAIN_INSIGHTS_COUNT_REQUEST_SUCCESS:
      return { loading: false, domainInsightsCount: action.payload };
    case DOMAIN_INSIGHTS_COUNT_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}


export {
  userInsightsReducer,mailboxInsightsReducer,archivedInsightsReducer,domainInsightsCountReducer
}
export const USER_MAILBOXES = '/user/mailboxes';
export const USER_EDIT_MAILBOX_ID = '/user/editmailbox/:mailboxId';
export const USER_EDIT_MAILBOX = '/user/editmailbox/';

export const USER_INSIGHTS = '/user/insights';

export const USER_SENDING_PATTERN = '/user/sending-pattern/';
export const USER_SENDING_PATTERN_ID = `${USER_SENDING_PATTERN}:mailboxId`;

export const USER_CONTENT_TESTS = '/user/content-tests';
export const USER_CREATE_CONTENT_TESTS = '/user/create-content-test/';
export const USER_CREATE_CONTENT_TESTS_ID = `${USER_CREATE_CONTENT_TESTS}:contentId`;
export const USER_EDIT_CONTENT_TESTS = '/user/edit-content-test/';
export const USER_EDIT_CONTENT_TESTS_ID = `${USER_EDIT_CONTENT_TESTS}:contentId`;

export const USER_SETTINGS = '/user/settings';
export const USER_TEAM = '/user/team';
export const USER_PROFILE = '/user/myprofile';
export const USER_SECURITY = '/user/security';
export const USER_SUBSCRIPTION = '/user/subscription';





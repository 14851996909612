import React, { useState } from "react";
import { Modal, Form, Input, Button, Spin, message } from "antd";
import {useHistory} from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify";
import AxiosApi from '../config/axios-api';
import { useDispatch } from 'react-redux';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import {USER_MAILBOXES} from '../Routes/Constants/RouteUrls';


const ResetPasswordModal = ({ _email, visible, onCancel, onCreate}) => {

    const [loader, setloader] = useState(false);
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const demoData = urlParams.get('demo-data');
    const history = useHistory();
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
        span: 8,
        },
        wrapperCol: {
        span: 23,
        },
    };

    const tailLayout = {
        wrapperCol: {
        offset: 0,
        span: 23,
        },
    };

    const onFinish = async values => {
        setloader(true);
        try {
            await Auth.forgotPasswordSubmit(values.email,values.verification_code,values.password);
            try {
                const user = await Auth.signIn(values.email, values.password);
                if (user) {
                    if(demoData)
                        localStorage.setItem("demoData",1);
                    console.log('userlogin  ',user);
                    localStorage.setItem('AuthenticationResult', JSON.stringify(user));
                    let getJwtToken = user.signInUserSession.idToken.jwtToken
                    AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${getJwtToken}`
                    try{
                        if(!user.attributes['custom:user_id'] || !user.attributes['custom:company_id']) {
                            const userDetails = await AxiosApi.get('user-details/'+values.email);
                            if(userDetails.data.result.userData.records && userDetails.data.result.userData.records.length){
                                await Auth.updateUserAttributes(user, {
                                    'custom:user_id': ""+userDetails.data.result.userData.records[0].id,
                                    'custom:company_id': ""+userDetails.data.result.userData.records[0].company_id,
                                });
                                localStorage.setItem("userId",userDetails.data.result.userData.records[0].id);
                                localStorage.setItem("companyId",userDetails.data.result.userData.records[0].company_id);
                            }
                        } else{
                            localStorage.setItem("userId",user.attributes['custom:user_id']);
                            localStorage.setItem("companyId",user.attributes['custom:company_id']);
                        }
                        await dispatch(planDetailsAction(localStorage.getItem("userId")));
                    } catch (e) {
                        console.log('error get userId:', e);
                        message.error(e.message);
                    }
                    window.heap.resetIdentity();
                    window.heap.identify(localStorage.getItem("userId"));
                    window.heap.addUserProperties({ "First Name":  user.attributes['custom:first_name'], "Last Name":user.attributes['custom:last_name'],"email":values.email});
                    process.env.REACT_APP_PROFITWELL_API_KEY && window.profitwell({ 'user_email': values.email });
                    message.success('Reset Password successfully');
                    history.push(USER_MAILBOXES);
                }
            } catch (error) {
              setloader(true);
              message.error(error.message);
            }
          } catch (error) {
              setloader(true);
              message.error(error.message);
          }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
  return (
    <Modal
    className="forgot-password-modal ns-rounded-lg"
      visible={visible}
      title=""
      icon={<ExclamationCircleOutlined />}
      okText="Reset My Password"
      onCancel={onCancel}
      width={340}
      onOk={onFinish}
      footer={null}
    >
      <Spin tip="Reset Password..." spinning={loader}>
      <Form  form={form}
        {...layout}
        initialValues={{
            remember: true,
        }}
        
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
       layout="vertical">
          <Form.Item className="" {...tailLayout}>
              <h2 className="pb-6 pt-1 text-4xl text-center">Reset Password</h2>
              <p className="please-type-in-your pb-6 pt-1">Please type your verification code which we just sent you in your email and enter your new password</p>
          </Form.Item>
          <Form.Item
              name="email"
              initialValue={_email}
              rules={[
              {
                  required: true,
                  message: 'Please input your email!',
                  type: 'email'
              },
              ]}
          >
              <Input disabled placeholder="Email"  className="float-left rounded-md" />
          </Form.Item>
          <Form.Item
              name="verification_code"
              rules={[
              {
                  required: true,
                  message: 'Please input verification code!',
              },
              ]}
          >
              <Input placeholder="Verification Code" className="float-left rounded-md" />
          </Form.Item>
          <Form.Item
              name="password"
              rules={[
              {
                  required: true,
                  pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"),
                  message: 'Your password is weak',
              },
              ]}
          >
              <Input.Password placeholder="Password" className="rounded-md" />
          </Form.Item>
          <Form.Item className="" {...tailLayout}>
              <Button type="primary" className="rounded-md mt-3" size="large" htmlType="submit" block>
                  Reset My Password
              </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ResetPasswordModal;
import { 
  COMPANY_DETAILS_REQUEST, COMPANY_DETAILS_REQUEST_SUCCESS,COMPANY_DETAILS_REQUEST_FAIL,
  COMPANY_DETAILS_SAVE_REQUEST,COMPANY_DETAILS_SAVE_SUCCESS,COMPANY_DETAILS_SAVE_FAIL
 } from "../Constants/companyDetailsConstant";

function  companyDetailsReducer(state = {companyData: []}, action) {
  switch (action.type) {
    case COMPANY_DETAILS_REQUEST:
      return { loading: true, companyData: [] };
    case COMPANY_DETAILS_REQUEST_SUCCESS:
      return { loading: false, companyData: action.payload };
    case COMPANY_DETAILS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  companyDetailsSaveReducer(state = { companyData: {} }, action) {
  switch (action.type) {
    case COMPANY_DETAILS_SAVE_REQUEST:
      return { loading: true};
    case COMPANY_DETAILS_SAVE_SUCCESS:
      return { loading: false,success: true, companyData: action.payload };
    case COMPANY_DETAILS_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}


export {
  companyDetailsReducer,
  companyDetailsSaveReducer
}
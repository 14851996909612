import React from "react";
import { Modal, Button, Image} from "antd";
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { mailboxConnectAction } from '../Store/Actions/mailboxActions';

const ConnectMailboxModal = ({ visible, setVisibleForm, setMailbox, setVisible, onCancel}) => {
    
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  // const mailboxConnect = useSelector((state) => state.mailboxConnect);
  // const { mailboxConnectData, loading, error } = mailboxConnect;
  const urlParams = new URLSearchParams(window.location.search);
  const googlePass = urlParams.get('google')==='pass';
  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
  const onFinish = async values => {
      console.log('Success:', values);
  };

  const onNextPage = async values => {
    setMailbox(values)
    setVisible(false)
    setVisibleForm(true)
  };

  const onSuccess = (res) => {
    console.log(res);
    // console.log('Login Success: currentUser:', res.profileObj);
    // console.log('Google Token:', res.tokenObj);
    // alert(
    //   `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    // );
    // // refreshTokenSetup(res);
    let goolgeOAuthPayload = {
        "userId": userId,
        "provider": 'google',
        "code": res.code,
    };
    console.log('goolgeOAuthPayload ', goolgeOAuthPayload)
    // console.log(`getBasicProfile`, res.getAuthResponse().id_token);
    signOut();
    dispatch(mailboxConnectAction(goolgeOAuthPayload));
      
  };

  const onLogoutSuccess = (res) => {
    console.log('Logged out Success');
    console.log(res);
    alert('Logged out Successfully ✌');
  };


  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    // alert(
    //   `Failed to login. 😢 `
    // );
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    fetchBasicProfile: true,
    clientId,
    isSignedIn: false,
    accessType: 'offline',
    responseType: 'code',
    prompt: 'consent',
    // approvalPrompt:"force"
    // prompt: 'select_account',
  });


  return (
    <Modal
    className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={440}
      onOk={onFinish}
      footer={null}
    >
            <h2 className="pb-2 text-4xl text-center">Connect New Email</h2>
            <div className="flex flex-row px-4">
              <div className="text-center px-4 py-4 m-2">
              {/* <button onClick={signOut} className="button">
                <img src="icons/google.svg" alt="google login" className="icon"></img>
                <span className="buttonText">Sign out</span>
              </button> */}
                <Image className="cursor-pointer" onClick={() => googlePass ? signIn() : onNextPage('google')}  width={75} height={75}  preview={false} src="/images/mailbox/google.png"></Image><p className="text-gray-700 py-2 text-base">Google</p>
                {/* <Image className="cursor-pointer" onClick={() => onNextPage('google')}  width={75} height={75}  preview={false} src="/images/mailbox/google.png"></Image><p className="text-gray-700 py-2 text-base">Google</p> */}
              </div>
              <div className="text-center px-4 py-4 m-2"><Image className="cursor-pointer" onClick={() => onNextPage('outlook')} width={75} height={75} preview={false}  src="/images/mailbox/outlook.png"></Image><p className="text-gray-700 py-2 text-base">Outlook</p></div>
              <div className="text-center pl-4 py-4 m-2"><Image className="cursor-pointer" onClick={() => onNextPage('office365')} width={70} height={75} preview={false}  src="/images/mailbox/office365.png"></Image><p className="text-gray-700 py-2 text-base">Office 365</p></div>
            </div>
            <div className="flex flex-row px-4">
              <div className="text-center py-4"><Image className="cursor-pointer" width={125} height={75}   preview={false} onClick={() => onNextPage('icloud')} src="/images/mailbox/icloud.png"></Image><p className="text-gray-700 py-4 text-base">iCloud</p></div>
              <div className="text-center px-4 py-4 m-2"><Image className="cursor-pointer" width={75} height={75} preview={false}  onClick={() => onNextPage('yahoo')} src="/images/mailbox/yahoo.png"></Image><p className="text-gray-700 py-2 text-base">Yahoo</p></div>
              {/* <div className="text-center px-2 py-2 m-2"><Image className="cursor-pointer" width={100} height={75}  preview={false}  onClick={() => onNextPage('exchange')} src="/images/mailbox/exchange.png"></Image><p className="text-gray-700 py-4 text-base">Exchange</p></div> */}
            </div>
            <div className="flex items-center flex-col">
                <h3 className="p-2 font-black text-gray-700 text-center">
                  <Button onClick={() => onNextPage('custom')} type="link"><span className="underline text-gray-600">Set Up Manually</span></Button>
                </h3>
            </div>
</Modal>
  );
};

export default ConnectMailboxModal;
import { 
  OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST, OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_SUCCESS,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_FAIL,
  OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_REQUEST,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_SUCCESS,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_FAIL,
  OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_REQUEST, OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_SUCCESS, OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_FAIL,
} from "../Constants/outgoingMaillboxConstants";

function  outgoingMailboxDailyFutureLimitReducer(state = {dailySettings: []}, action) {
  switch (action.type) {
    case OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST:
      return { loading: true, dailySettings: [] };
    case OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_SUCCESS:
      return { loading: false, dailySettings: action.payload };
    case OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  outgoingMailboxDailyFutureLimitSaveReducer(state = { dailySettings: {} }, action) {
  switch (action.type) {
    case OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_REQUEST:
      return { loading: true};
    case OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_SUCCESS:
      return { loading: false,success: true, dailySettings: action.payload };
    case OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  outgoingMailboxExpectedEmailsReducer(state = { dailyExpectedEmails: null }, action) {
  switch (action.type) {
    case OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_REQUEST:
      return { loading: true};
    case OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_SUCCESS:
      return { loading: false,success: true, dailyExpectedEmails: action.payload };
    case OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}


export {
  outgoingMailboxDailyFutureLimitReducer,
  outgoingMailboxDailyFutureLimitSaveReducer,
  outgoingMailboxExpectedEmailsReducer
}
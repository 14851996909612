import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import {Layout} from 'antd';
import SiderLayout from '../Layout/SiderLayout';

import MailboxScreen from '../Screens/MailboxScreen';
import MyProfileScreen from '../Screens/MyProfileScreen';
import SecurityScreen from '../Screens/SecurityScreen';
import SubscriptionScreen from '../Screens/SubscriptionScreen';
import TeamScreen from '../Screens/TeamScreen';
import SettingsScreen from '../Screens/SettingsScreen';
import EditMailBoxScreen from '../Screens/EditMailBoxScreen';
import InsightsScreen from '../Screens/InsightsScreen';
import {
  USER_MAILBOXES,USER_EDIT_MAILBOX_ID,
  USER_INSIGHTS,USER_SETTINGS,
  USER_CREATE_CONTENT_TESTS_ID,USER_EDIT_CONTENT_TESTS_ID,USER_CREATE_CONTENT_TESTS,
  USER_TEAM,USER_PROFILE,USER_SECURITY,USER_SUBSCRIPTION, USER_SENDING_PATTERN_ID, USER_CONTENT_TESTS
} from '../Routes/Constants/RouteUrls';
import PageNotFoundScreen from '../Screens/PageNotFoundScreen';
import ContentTestsScreen from '../Screens/ContentTestsScreen';
import EditContentTestsScreen from '../Screens/EditContentTestsScreen';
import CreateContentTestsScreen from '../Screens/CreateContentTestsScreen';
import SendingPatternScreen from '../Screens/SendingPattern/SendingPatternScreen';


const ProtectedUserRoute = (props) => {
  const userId = localStorage.getItem("userId")
  return (
          <>  
          {
            userId 
            ? (
                    <Layout className="1xl:h-screen">
                      <SiderLayout/>
                      <Layout className="static">
                        <div className="ns-sider-min-height 1xl:h-screen h-full">
                              <Switch>
                                <Route exact path={USER_MAILBOXES} component={MailboxScreen} />
                                <Route exact path={USER_EDIT_MAILBOX_ID} component={EditMailBoxScreen} />
                                <Route exact path={USER_CONTENT_TESTS} component={ContentTestsScreen} />
                                <Route exact path={USER_SENDING_PATTERN_ID} component={SendingPatternScreen} />
                                <Route exact path={USER_EDIT_CONTENT_TESTS_ID} component={EditContentTestsScreen} />
                                <Route exact path={USER_CREATE_CONTENT_TESTS} component={CreateContentTestsScreen} />
                                <Route exact path={USER_CREATE_CONTENT_TESTS_ID} component={CreateContentTestsScreen} />
                                <Route exact path={USER_INSIGHTS} component={InsightsScreen} />
                                <Route path={USER_PROFILE} component={MyProfileScreen} />
                                <Route path={USER_TEAM} component={TeamScreen} />
                                <Route path={USER_SECURITY} component={SecurityScreen} />
                                <Route path={USER_SUBSCRIPTION} component={SubscriptionScreen} />
                                <Route path={USER_SETTINGS} component={SettingsScreen} />
                                <Route path={USER_SETTINGS} component={SettingsScreen} />
                                <Route path="" component={PageNotFoundScreen} />
                              </Switch>
                            {/* <FooterLayout/> */}
                          </div>
                      </Layout>
                  </Layout>
                )
            :<Redirect to="/" />
          }
         </>
    )
}

export default ProtectedUserRoute
import React from "react";
import { Modal } from "antd";
import StripeSplitElementsScreen from "../Screens/StripeSplitElementsScreen";

const StripeElementsModal = ({ visible, setVisible, onCancel,setStripeConfig,stripeConfig,stripePromise}) => {
  console.log('StripeElementsModal stripeConfig', stripeConfig)
  return (
    <Modal
      className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={640}
      footer={null}
    >
      <h2 className="pb-2 text-4xl text-center">Add your card details</h2>
      <p className="please-type-in-your pb-6 pt-1">To link another mailbox, please purchase more mailboxes</p>
      { stripePromise && stripeConfig.planId && 
          <StripeSplitElementsScreen setStripeElementsModal={setVisible} setStripeConfig={setStripeConfig} stripeConfig={stripeConfig} stripePromise={stripePromise} />
      }

    </Modal>
  );
};

export default StripeElementsModal;
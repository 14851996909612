import React from 'react';
import { Route } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
    const component = props => (
        <div className="h-screen">
             <div className="w-full h-full flex">
                <div className="px-12 text-left w-4/12 flex-col h-screen">
                    <div className="flex-row justify-center md:justify-start pt-6 h-full">
                        <div  className="pb-10">
                            <img width={180} height={40} alt="logo" src="/images/logo_allegrow.svg" />
                        </div>
                        <div className="w-46">
                            <h1 className='text-3xl'>Make sure your emails reach the inbox.</h1>
                        </div>
                        <div className="screen-middle-public flex-row">
                            <Component {...props} />
                        </div>
                        {/* <div className="w-46 absolute bottom-0" style={{bottom:'50px'}}>
                            <h4 className="On-Allegrow-weve-s text-md">
                              "On Allgrow we've seen a marked increase in demo requests and created approximately $100,000 worth of qualified sales oppertunities."
                            </h4>
                            <div className="text-right">
                                <h4 className="w-full pr-5 Welcome-Please-sign text-sm pt-2">
                                <span className="text-lg">Nadine ter Meulen,</span><br/>
                                Global Marketing Director - OnApp
                                </h4>
                            </div>
                        </div> */}
                    </div>
                </div>   
                <div className="w-8/12 signup-img p-20 h-screen">
                    <img className="w-full h-full" alt="Signup" src="/images/onboarding_background.svg" />
                </div>
            </div>                
        </div>
    )
    return <Route {...rest} component={component} />
}

export default PublicRoute
import React from 'react'
import {Layout, PageHeader, Tabs} from 'antd';
import { ProfileTwoTone, BankTwoTone, SecurityScanTwoTone, FundTwoTone } from '@ant-design/icons';
import MyProfileScreen from './MyProfileScreen';
import SecurityScreen from './SecurityScreen';
import SubscriptionScreen from './SubscriptionScreen';
import CompanyScreen from './CompanyScreen';


const SettingsScreen = () => {
    const {Header,Content} = Layout;
    const { TabPane } = Tabs;

    const urlParams = new URLSearchParams(window.location.search);
    const tabNumber = urlParams.get('tab') || 1;
    
    const callback = (key) => {
        console.log(key);
    }

    return (
        <div>
            <Header className="site-layout-sub-header-background p-0 fixed pb-5"> 
                <PageHeader className="site-page-header"  title="Settings"/>
            </Header>
            <Content className="mx-6 my-6 h-screen ant-layout-content-static">
                <div className="">
                    <Tabs defaultActiveKey={tabNumber} onChange={callback} >
                        <TabPane tab={<span className="flex flex-row ant-row-middle"><ProfileTwoTone />My Profile</span>} key="1">
                            <MyProfileScreen />
                        </TabPane>
                        <TabPane tab={<span className="flex flex-row ant-row-middle"><BankTwoTone />Company</span>} key="5">
                            <CompanyScreen />
                        </TabPane>
                        {/* <TabPane tab="Team" key="2">
                            <TeamScreen />
                        </TabPane> */}
                        <TabPane tab={<span className="flex flex-row ant-row-middle"><SecurityScanTwoTone />Security</span>} key="3">
                            <SecurityScreen />
                        </TabPane>
                        <TabPane tab={<span className="flex flex-row ant-row-middle"><FundTwoTone />Subscription</span>} key="4">
                            <SubscriptionScreen />
                        </TabPane>
                    </Tabs>
                </div>
            </Content>
        </div>
    )
}

export default SettingsScreen
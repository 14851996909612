import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Spin, PageHeader, Modal, Dropdown, Menu, message, Space, Alert } from 'antd';
import { LeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ContentChartScreen from './Shared/ContentChartScreen';
import ContentVariatScreen from './Shared/ContentVariatScreen';
import { USER_CONTENT_TESTS } from '../Routes/Constants/RouteUrls';
import { getlocalStorageValue } from '../Helpers/globalValues'
import { contentTestsAction, contentTestsdeleteAction, contentTestsFinishAction } from '../Store/Actions/contentTestsActions';
import ChartLoadingScreen from './Shared/ChartLoadingScreen';
import { openNotificationSuccess, openNotificationError } from "../Helpers/globalNotification"
import { CONTENT_TESTS_DELETE_REQUEST, CONTENT_TESTS_FINISH_FAIL, CONTENT_TESTS_FINISH_REQUEST, CONTENT_TESTS_FINISH_SUCCESS_CLEAR } from '../Store/Constants/contentTestsConstant';
import EditConnectMailboxFormModal from '../Modals/EditConnectMailboxFormModal';
//import contentTests from '../Json/contentTests.json'


const EditContentTestsScreen = () => {
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const [ visible, setVisible ] = useState(false);
    const [visibleForm, setVisibleForm] = useState(false);
    const { contentTests } = useSelector((state) => state.contentTestsInfo);
    const { contentTestsDelete, loading:loadingDelete } = useSelector((state) => state.contentTestsDeleteInfo);
    const { contentTestsFinish, loadingFinish, errorFinish } = useSelector((state) => state.contentTestsFinishInfo);
    const dispatch = useDispatch();
    const { Header, Content } = Layout;
    const { confirm } = Modal;
    const history = useHistory();
    let { contentId } = useParams();
    const userSearch = id => { 
        return contentTests.find(u => u.test_id === parseInt(contentId));
    }
    let selectedTest = (contentTests && contentTests.length) &&  userSearch(parseInt(contentId));

    const arrEmailAccounts = JSON.parse(localStorage.getItem('arrEmailAccounts'));
    const userSearchMailbox = mailboxId => arrEmailAccounts.find(m => m.id ===(mailboxId));
    let selectedMailbox = userSearchMailbox((selectedTest?.mailbox_id));
    let {connection_status:connectionStatus,connection_error:connectionError,email} = selectedMailbox ? selectedMailbox.sub_accounts : {};
    if(connectionStatus && connectionStatus==='credentials_error') {
      connectionError = <div><span>We could not connect to your mailbox.</span><a className="pl-2 text-red-700" onClick={()=>setVisibleForm(true)} href="#/">Please Update credentials here.</a></div>;
    }
    
    useEffect(() => {
      !contentTests && !contentTests?.length &&
        dispatch(contentTestsAction())
      return () => console.log('Content Unmount component');
    }, []);

    useEffect(() => {
      if(contentTestsDelete?.id){
        openNotificationSuccess({description: `Content Test deleted successfully`});
        history.push(USER_CONTENT_TESTS+'?waitCall=true')
      }
      return () => {
        dispatch({ type: CONTENT_TESTS_DELETE_REQUEST, payload: null });
        console.log(`unmount CONTENT_TESTS_DELETE_REQUEST`);
      }
    }, [contentTestsDelete])

    useEffect(() => {
      if(contentTestsFinish?.finishTest){
        openNotificationSuccess({description: `Content Test Finished successfully`});
        history.push(USER_CONTENT_TESTS+'?waitCall=true')
      } else if(contentTestsFinish?.status){
        openNotificationError({description: `Content Test having issue for finishing`});
        dispatch({ type: CONTENT_TESTS_FINISH_FAIL, payload: null });
      }
      return () => {
        dispatch({ type: CONTENT_TESTS_FINISH_SUCCESS_CLEAR });
        console.log(`unmount CONTENT_TESTS_FINISH_REQUEST`);
      }
    }, [contentTestsFinish])

    const finishTest = () => {
      !demoData && dispatch(contentTestsFinishAction({id:contentId,finishTest:true}))
    }

    const deleteTest = () => {
        confirm({
          title: 'Are you sure you want to delete this test?',
          icon: <ExclamationCircleOutlined />,
          content: 'This will delete all your variations as well and it cannot be undone.',
          async onOk() {
            !demoData && await dispatch(contentTestsdeleteAction(contentId))
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }
    console.log(`selectedTest`, selectedTest)
    const menu = (
      <Menu>
        {
          selectedTest?.test_status!=='completed' &&
          <Menu.Item onClick={()=> finishTest()} className="text-gray-600" key="1" >
            Finish Test
          </Menu.Item>
        }
        <Menu.Item onClick={()=> deleteTest()} className="text-gray-600" key="2" >
          Delete Test
        </Menu.Item>
      </Menu>
    );

    return (
        <div>
            {
                selectedMailbox 
                && <EditConnectMailboxFormModal
                        visible={visibleForm}
                        setVisibleForm={setVisibleForm}
                        mailboxData={selectedMailbox.sub_accounts}
                        onCancel={() => setVisibleForm(false)}
                  />       
            } 
            <Header className="site-layout-sub-header-background p-0 fixed herder-div-width"> 
                <PageHeader 
                    className="site-page-header"  
                    title="Content Tests"
                    backIcon={<Link to={USER_CONTENT_TESTS}><LeftOutlined className="text-gray-500 text-2xl p-1" /></Link>} 
                    extra={ 
                              loadingFinish 
                              ?  <Spin spinning={true}/>
                              :  <Dropdown className="rounded-lg pr-8 pt-16 text-gray-600" trigger={['click']} overlay={menu}>
                                    <a className="ant-dropdown-link pr-2" href="#/" onClick={e => e.preventDefault()}>
                                      Edit Test
                                    </a>
                                </Dropdown>
                            
                          } 
                    onBack={() => null} title={selectedTest?.test_name}
                />
            </Header>
            <Content className="mx-6 my-6 text-center space-y-4 ant-layout-content-static pb-16">
                {
                    selectedTest 
                    ? <div>
                        { connectionError 
                            && <Alert
                                className='mt-4 ns-rounded-lg text-left'
                                message="Cannot connect to your mailbox."
                                description={connectionError}
                                type="error"
                                showIcon
                                />
                        }
                        <div  className="site-layout-background pt-2 ns-rounded-lg">
                          <div className="site-statistic-demo-card">    
                            <ContentChartScreen 
                                className="flex" 
                                domainName='Overview'
                                contentTest={selectedTest} 
                            />
                          </div>
                        </div>
                        <ContentVariatScreen 
                            className="flex" 
                            domainName='Overview'
                            contentTest={selectedTest} 
                        />
                      </div>
                    : <Space className="w-full" direction="vertical"><ChartLoadingScreen /></Space>
                }
            </Content>
        </div>
    )
}


export default EditContentTestsScreen
import React, { useState } from 'react'
import { Statistic, Card } from 'antd';

const StatisticsJoinedCard = ({cardValue,cardValue1,cardTitle="Test End",cardTitle1="Remaining",isSelectable=false}) => {
  const cardKey = cardTitle.toLowerCase().replace(' ','_');
  return (
    <Card className={`statistics-joined-card ns-bg-light-white ns-rounded-lg  ${cardKey} `}  >
      <div>
        <Statistic
          title={cardTitle}
          value={cardValue ?? "-"}
          bordered={false}
        />
      </div>
      <div></div>
      <div></div>
      <div>
        <Statistic
          title={cardTitle1}
          value={cardValue1 ?? "-"}
          bordered={false}
        />
      </div>
    </Card>
  )
}

export default StatisticsJoinedCard
import React , { useState } from "react";
import {useHistory} from "react-router-dom";
import { Modal, Form, Image, Spin, Input, Button, Divider,notification, message,Select} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ConnectedAppSpecificManualJson from '../Json/ConnectedAppSpecificManual.json'
import { mailboxDetailsUpdateAction } from '../Store/Actions/mailboxActions';
import { useDispatch } from "react-redux";

const EditConnectMailboxFormModal = ({setVisibleForm, visible, mailboxData, onCancel}) => {
    
    const {provider,id:mailboxId} = mailboxData;
    console.log('mailboxData', mailboxData)
    const dispatch = useDispatch();
    const userId = localStorage.getItem("userId")
    const demoData = localStorage.getItem("demoData")
    const history = useHistory();
    const [form] = Form.useForm();
    const [loader, setloader] = useState(false);
    const layout = {
        labelCol: {
        span: 8,
        },
        wrapperCol: {
        span: 23,
        },
    };

    const tailLayout = {
        wrapperCol: {
        offset: 0,
        span: 23,
        },
    };

    const openNotification = placements => {
        notification.error({
          message: `New mailbox connection error: ${placements.msg}`,
          description: 
            placements.description,
          placement:placements.apperence,
        });
    };

    const onFinish = async values => {
        try {
            setloader(true);
            const objMailbox = {
                "userId": userId,
                "linkedAccountId": mailboxId,
                "provider": provider,
                "firstName": values.firstName,
                "lastName": values.lastName,
                "email": values.email,
                "password": values.password || '',
            };
            if (provider === 'custom') {
                objMailbox['customProvider'] = {
                    imap: {
                        "username": values.imap_username,
                        "password": values.imap_password,
                        "host": values.imap_host,
                        "port": values.imap_port,
                        "secure": values.imap_secure,
                    },
                    smtp: {
                        "username": values.smtp_username,
                        "password": values.smtp_password,
                        "host": values.smtp_host,
                        "port": values.smtp_port,
                        "secure": values.smtp_secure,
                    }
                }
            }

            if(!demoData){
                let data = await dispatch(mailboxDetailsUpdateAction(JSON.stringify(objMailbox))).catch((error)=> {
                    
                    console.log('catched error', error)
                    throw error;
                });
                console.log('data mailboxDetailsUpdateAction', data);
                message.info('Mailbox updated successfully.');
                history.push("/user/mailboxes");
                setloader(false);
                setVisibleForm(false);
            }else{
                setloader(false);
            }
        } catch (e) {
            setloader(false);
            // alert(e.message);
            openNotification({
                type: 'error',
                msg: 'Error',
                description: e.message || e,
                apperence: 'topRight',
            });
        }
    }
    const onFinishFailed = async values => {
        console.log('Success:', values);
    };

    const appSpecificManual = (<p className="pl-1 pb-3">
                                Make sure you connect using an app-specific password.&nbsp;&nbsp;
                                <a className="text-blue-400" target="_blank" rel="noopener noreferrer" href={ConnectedAppSpecificManualJson[provider]}>Here’s how</a>
                            </p>);


  return (
    <Modal
    className="connect-mailbox-form-modal ns-rounded-lg"
      visible={visible}
      title=""
      icon={<ExclamationCircleOutlined />}
      okText="Confirm Verification Code"
      onCancel={onCancel}
      width={520}
      onOk={onFinish}
      footer={null}
    >
            <h2 className="pb-2 text-4xl text-center">Edit Your Email Details</h2>
            {provider!=='custom' 
                ? (<div className="flex flex-col px-4">
                    <div className="text-center px-4 py-4 m-2"><Image width={provider==='icloud'?115:75} height={75}  preview={false} src={`/images/mailbox/${provider}.png`}></Image></div>
                    </div>)
                : (<div className="pt-10"></div>)
            }
            <div className="pl-4">
              <Spin spinning={loader}>
                      <Form
                      {...layout}
                      form={form} 
                      name="basic"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      >
                          
                          <Form.Item
                              name="firstName"
                              initialValue={mailboxData.first_name}
                              rules={[
                                  {
                                      required: true,
                                      message: 'Please input your First Name!',
                                  },
                                  ]}
                              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                          >
                              <Input placeholder="First Name" className="rounded-md" />
                          </Form.Item>
                          <Form.Item
                              name="lastName"
                              initialValue={mailboxData.last_name}
                              rules={[
                                  {
                                      required: true,
                                      message: 'Please input your Last Name!',
                                  },
                                  ]}
                              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                          >
                              <Input placeholder="Last Name" className="rounded-md" />
                          </Form.Item>
                          <Form.Item
                              name="email"
                              initialValue={mailboxData.email}
                              rules={[
                              {
                                  required: true,
                                  message: 'Please input your email!',
                                  type: 'email'
                              },
                              ]}
                          >
                              <Input disabled placeholder="Email" className="float-left rounded-md" />
                          </Form.Item>
                    {provider!=='custom' &&
                        <div>
                            <Form.Item
                                name="password"
                                className="mb-0"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                ]}
                            >
                                <Input.Password placeholder="Password" className="rounded-md" />
                            </Form.Item>
                            {appSpecificManual}
                        </div>
                    }
                            {provider==='custom' && mailboxData.custom_provider &&
                                (
                                    <div>
                                    <Divider orientation="left">IMAP</Divider>
                                    <Form.Item
                                        name="imap_username"
                                        className=""
                                        initialValue={mailboxData.custom_provider.imap.username}
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                        ]}
                                    >
                                        <Input placeholder="Username" className="rounded-md" />
                                    </Form.Item>     
                                    <Form.Item
                                        name="imap_password"
                                        className="mb-0"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        ]}
                                    >
                                        <Input.Password placeholder="Password" className="rounded-md" />
                                    </Form.Item>
                                    {appSpecificManual}
                                    <Form.Item
                                        name="imap_host"
                                        initialValue={mailboxData.custom_provider.imap.host}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your IMAP host!',
                                            },
                                            ]}
                                        style={{ display: 'inline-block', width: 'calc(65% - 8px)' }}
                                    >
                                        <Input placeholder="Host (e.g. imap.gmail.com)" className="rounded-md" />
                                    </Form.Item>
                                    <Form.Item
                                        name="imap_port"
                                        initialValue={mailboxData.custom_provider.imap.port}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Port required!',
                                            },
                                            ]}
                                        style={{ display: 'inline-block', width: 'calc(35% - 8px)', margin: '0 8px' }}
                                    >
                                        <Input placeholder="port (e.g. 993)" className="rounded-md" />
                                    </Form.Item>
                                    <Form.Item   initialValue={mailboxData.custom_provider.imap.secure} name="imap_secure">
                                        <Select  placeholder="Security Protocol"  className="w-full rounded-md">
                                            <Select.Option value="ssltls">SSL/TLS</Select.Option>
                                            <Select.Option value="insecure">Insecure</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Divider orientation="left">SMTP</Divider>
                                    <Form.Item
                                        name="smtp_username"
                                        className=""
                                        initialValue={mailboxData.custom_provider.smtp.username}
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                        ]}
                                    >
                                        <Input placeholder="Username" className="rounded-md" />
                                    </Form.Item>     
                                    <Form.Item
                                        name="smtp_password"
                                        className="mb-0"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        ]}
                                    >
                                        <Input.Password placeholder="Password" className="rounded-md" />
                                    </Form.Item>
                                    {appSpecificManual}
                                    <Form.Item
                                        name="smtp_host"
                                        initialValue={mailboxData.custom_provider.smtp.host}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your SMTP host!',
                                            },
                                            ]}
                                        style={{ display: 'inline-block', width: 'calc(65% - 8px)' }}
                                    >
                                        <Input placeholder="Host (e.g. smtp.gmail.com)" className="rounded-md" />
                                    </Form.Item>
                                    <Form.Item
                                        name="smtp_port"
                                        initialValue={mailboxData.custom_provider.smtp.port}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Port required!',
                                            },
                                            ]}
                                        style={{ display: 'inline-block', width: 'calc(35% - 8px)', margin: '0 8px' }}
                                    >
                                        <Input placeholder="port (e.g. 465)" className="rounded-md" />
                                    </Form.Item>
                                    <Form.Item  
                                    initialValue={mailboxData.custom_provider.smtp.secure}
                                    name="smtp_secure">
                                        <Select placeholder="Security Protocol"  className="w-full rounded-md">
                                            <Select.Option value="starttls">STARTTLS</Select.Option>
                                            <Select.Option value="insecure">Insecure</Select.Option>
                                            <Select.Option value="ssltls">SSL/TLS</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div> 
                            )
                          }
                          <Form.Item className="pt-5" {...tailLayout}>
                              <Button type="primary" className="rounded-md w-full" size="large" htmlType="submit">
                                    Save
                              </Button>
                          </Form.Item>
                      </Form>
                  </Spin>              
            </div>
</Modal>
  );
};

export default EditConnectMailboxFormModal;
import { 
  PLAN_DETAILS_REQUEST, PLAN_DETAILS_REQUEST_SUCCESS,PLAN_DETAILS_REQUEST_FAIL,
  PLAN_ALL_DETAILS_REQUEST, PLAN_ALL_DETAILS_REQUEST_SUCCESS,PLAN_ALL_DETAILS_REQUEST_FAIL,
 } from "../Constants/planDetailsConstant";

function  planDetailsReducer(state = {planData: []}, action) {
  switch (action.type) {
    case PLAN_DETAILS_REQUEST:
      return { loading: true, planData: [] };
    case PLAN_DETAILS_REQUEST_SUCCESS:
      return { loading: false, planData: action.payload };
    case PLAN_DETAILS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

function  planAllDetailsReducer(state = {planAllData: []}, action) {
  switch (action.type) {
    case PLAN_ALL_DETAILS_REQUEST:
      return { loading: true, planAllData: [] };
    case PLAN_ALL_DETAILS_REQUEST_SUCCESS:
      return { loading: false, planAllData: action.payload };
    case PLAN_ALL_DETAILS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

export {
  planDetailsReducer,
  planAllDetailsReducer
}
import AxiosApi from '../../config/axios-api';
import {
  OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_SUCCESS,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_FAIL,
  OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_REQUEST,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_SUCCESS,OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_FAIL,
  OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_REQUEST, OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_SUCCESS, OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_FAIL,
} from "../Constants/outgoingMaillboxConstants";

const outgoingMailboxDailyFutureLimitAction = (userMailboxId) => async (dispatch) => {
  try {
    dispatch({ type: OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST, payload: userMailboxId });
    const { data } = await AxiosApi.get('outgoing/daily-future-limits?userMailboxId=' + userMailboxId);
    dispatch({ type: OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_REQUEST_FAIL, payload: error });
  }
};

const outgoingMailboxDailyFutureLimitSaveAction = (userMailbox) => async (dispatch) => {
  try {
    console.log('userMailbox',userMailbox);
    dispatch({ type: OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_REQUEST, payload: userMailbox });
    AxiosApi.defaults.headers.put['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.put('outgoing/daily-future-limits',userMailbox);
    dispatch({ type: OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: OUTGOING_MAILBOX_DAILY_FUTURE_LIMIT_SAVE_FAIL, payload: error });
  }
};

const outgoingMailboxDailyExpectedEmailsAction = (domainMailbox) => async (dispatch) => {
  try {
    dispatch({ type: OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_REQUEST, payload: domainMailbox });
    AxiosApi.defaults.headers.put['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('outgoing/daily-expected-emails',domainMailbox);
    dispatch({ type: OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: OUTGOING_MAILBOX_DAILY_EXPECTED_EMAILS_FAIL, payload: error });
  }
};

export { 
  outgoingMailboxDailyFutureLimitAction,
  outgoingMailboxDailyFutureLimitSaveAction,
  outgoingMailboxDailyExpectedEmailsAction 
};
import React from "react";
import { Modal, Button} from "antd";
import { Link } from "react-router-dom";

const ConfirmSignupMessageModal = ({ visible, setVisibleForm, setMailbox, setVisible, onCancel}) => {
    
  const onFinish = async values => {
      console.log('Success:', values);
  };

  return (
    <Modal
    className="connect-mailbox-modal ns-rounded-lg"
      visible={visible}
      onCancel={onCancel}
      width={440}
      onOk={onFinish}
      footer={null}
    >
            <h2 className="pb-2 text-4xl text-center">Activate Your Account</h2>
            <div className="flex flex-row px-4 pl-0">
              <div className="text-center px-2 py-2 m-2">
                <p className="text-gray-700 py-4 text-base text-left">Congratulations!</p>
                <p className="text-gray-700 py-4 text-base text-left">One more step: please click on the link in your email to activate your account, then log in normally.</p>
               
              </div>
            </div>
            <div className="flex items-start flex-col">
                <h3 className="pl-4 p-2 font-black text-gray-700 text-left">
                  See you inside,<br></br>
                  The Allegrow Team
                </h3>
                <Button type="primary" className="rounded-md ml-8 mt-8 w-5/6" size="large" block>
                  <Link to="/">
                      I’ve done this, take me to Log In
                  </Link>
                </Button>
            </div>
</Modal>
  );
};

export default ConfirmSignupMessageModal;